import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Plus } from "lucide-react";
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { SaleOutput } from "../types";
import { useSaleData } from "../hooks/sales/useSaleData";
import { useCustomers } from "../hooks/customers/useCustomers";
import { useProducts } from "../hooks/products/useProducts";
import logger from "../utils/logger";
import { CreateSaleDialog } from '../components/sales/CreateSaleDialog';
import { SaleList } from '../components/sales/SaleList';

// Import ExtendedSaleOutput type from useSaleData
interface ExtendedSaleOutput extends SaleOutput {
  customer_name: string;
}

export default function Sales() {
  const navigate = useNavigate();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const { sales, isLoading, error, refetch } = useSaleData();
  const { customers, isLoading: customersLoading } = useCustomers();
  const { products, isLoading: productsLoading } = useProducts();

  const [sortedSales, setSortedSales] = useState<ExtendedSaleOutput[]>([]);

  useEffect(() => {
    if (sales) {
      const sorted = [...sales].sort((a, b) => {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      });
      setSortedSales(sorted);
      logger.info("Sales sorted by date", { count: sorted.length });
    }
  }, [sales]);

  const handleCreateSale = () => {
    navigate("/sales/new");
  };

  if (isLoading || customersLoading || productsLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3}>
        <Typography color="error">Error: {error.message}</Typography>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Sales</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsCreateDialogOpen(true)}
        >
          Create Sale
        </Button>
      </Box>

      <Box className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        <Box>
          <Box>
            <Typography variant="h6">Total Sales</Typography>
          </Box>
          <Box>
            <Typography variant="h4">
              {sortedSales.length}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box>
            <Typography variant="h6">Total Revenue</Typography>
          </Box>
          <Box>
            <Typography variant="h4">
              ₦{sortedSales.reduce((sum, sale) => sum + sale.total_amount, 0).toFixed(2)}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box>
            <Typography variant="h6">Average Sale Value</Typography>
          </Box>
          <Box>
            <Typography variant="h4">
              ₦{sortedSales.length > 0
                ? (
                    sortedSales.reduce((sum, sale) => sum + sale.total_amount, 0) /
                    sortedSales.length
                  ).toFixed(2)
                : "0.00"}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box className="mt-8">
        <Box>
          <Typography variant="h6">Recent Sales</Typography>
        </Box>
        <SaleList sales={sortedSales} />
      </Box>

      <CreateSaleDialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        onSuccess={() => {
          setIsCreateDialogOpen(false);
          refetch();
        }}
      />
    </Box>
  );
}
