import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../ui/form";
import { Input } from "../ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { useToast } from "../../utils/use-toast";
import brain from "../../brain";
import { CreateRestockData, InventoryItem, Supplier } from "../../types/inventory";
import { RefreshCwIcon } from "lucide-react";

interface RestockDialogProps {
  item: InventoryItem;
  onSuccess: () => void;
}

export function RestockDialog({ item, onSuccess }: RestockDialogProps) {
  const [open, setOpen] = useState(false);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const { toast } = useToast();
  const form = useForm<CreateRestockData>({
    defaultValues: {
      inventory_id: item.id,
      quantity: 0,
      purchase_cost: item.purchase_cost,
      supplier_id: "",
      notes: "",
    },
  });

  useEffect(() => {
    if (open) {
      loadSuppliers();
    }
  }, [open]);

  const loadSuppliers = async () => {
    try {
      const data = await brain.list_suppliers();
      setSuppliers(data);
    } catch (error: any) {
      console.error("Error loading suppliers:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to load suppliers",
      });
    }
  };

  const onSubmit = async (data: CreateRestockData) => {
    try {
      await brain.restock_item(data);
      toast({
        title: "Success",
        description: "Item restocked successfully",
      });
      setOpen(false);
      onSuccess();
      form.reset({
        inventory_id: item.id,
        quantity: 0,
        purchase_cost: item.purchase_cost,
        supplier_id: "",
        notes: "",
      });
    } catch (error: any) {
      console.error("Error restocking item:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message || "Failed to restock item",
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm">
          <RefreshCwIcon className="h-4 w-4 mr-2" />
          Restock
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Restock {item.name}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="quantity"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Quantity</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      {...field}
                      onChange={(e) => {
                        const value = e.target.value === "" ? 0 : parseFloat(e.target.value);
                        field.onChange(value);
                      }}
                      placeholder="0"
                      min="0"
                      step={item.type === "dimensional" ? "0.01" : "1"}
                      required
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="purchase_cost"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Purchase Cost</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      {...field}
                      onChange={(e) => field.onChange(parseFloat(e.target.value))}
                      placeholder="0.00"
                      min="0.01"
                      step="0.01"
                      required
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="supplier_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Supplier</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select supplier" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {suppliers.map((supplier) => (
                        <SelectItem key={supplier.id} value={supplier.id}>
                          {supplier.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="notes"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Notes (Optional)</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Add notes" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit" className="w-full">Restock Item</Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
