import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { useToast } from "../utils/use-toast";
import brain from "../brain";
import type { GetSalesReportData, GetInventoryReportData, GetProfitLossReportData } from "../types";
import {
  BarChart3Icon,
  BoxIcon,
  DollarSignIcon,
  FileTextIcon,
  PackageIcon,
  TrendingDownIcon,
  TrendingUpIcon,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatNaira, formatNumber, formatPercent } from "../utils/format";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      variant="outline"
      size="sm"
      onClick={() => navigate("/")}
      className="mb-4"
    >
      ← Back to Dashboard
    </Button>
  );
};

export default function Reports() {
  const [salesReport, setSalesReport] = useState<GetSalesReportData | null>(null);
  const [inventoryReport, setInventoryReport] = useState<GetInventoryReportData | null>(null);
  const [profitLossReport, setProfitLossReport] = useState<GetProfitLossReportData | null>(null);
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();

  const loadReports = async () => {
    try {
      setLoading(true);
      // Load sales report
      const salesResponse = await brain.get_sales_report();
      setSalesReport(salesResponse);

      // Load inventory report
      const inventoryResponse = await brain.get_inventory_report();
      setInventoryReport(inventoryResponse);

      // Load profit/loss report
      const plResponse = await brain.get_profit_loss_report();
      setProfitLossReport(plResponse);
    } catch (error) {
      console.error("Error loading reports:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to load reports",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadReports();
  }, []);

  if (loading) {
    return (
      <div className="p-8">
        <BackButton />
        <div className="flex items-center justify-center h-[60vh]">
          <div className="text-lg text-muted-foreground">Loading reports...</div>
        </div>
      </div>
    );
  }

  // Convert monthly data for charts with null checks
  const monthlyData = profitLossReport
    ? Object.entries(profitLossReport.sales_by_month || {}).map(([month, sales]) => {
        const date = new Date(month + '-01');
        const formattedMonth = date.toLocaleString('default', { month: 'short', year: '2-digit' });
        const expenses = (profitLossReport.expenses_by_month || {})[month] || 0;
        const salesAmount = sales || 0;
        return {
          month: formattedMonth,
          sales: salesAmount,
          expenses: expenses,
          profit: salesAmount - expenses,
        };
      })
    : [];

  // Format inventory data for charts with null checks
  const stockData = inventoryReport
    ? Object.entries(inventoryReport.stock_value_by_type || {}).map(([type, value]) => ({
        type: type === 'quantity' ? 'Quantity-based' : 'Dimensional',
        value: value || 0,
      }))
    : [];

  return (
    <div className="p-8">
      <BackButton />
      <div className="flex items-center gap-3 mb-8">
        <BarChart3Icon className="h-8 w-8 text-primary" />
        <h1 className="text-3xl font-bold">Financial Reports</h1>
      </div>

      {/* Quick Stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Total Sales</CardTitle>
            <DollarSignIcon className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {formatNaira(salesReport?.total_sales)}
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Gross Profit</CardTitle>
            <TrendingUpIcon className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {formatNaira(profitLossReport?.gross_profit)}
            </div>
            <p className="text-xs text-muted-foreground">
              {formatPercent(profitLossReport?.gross_margin)} margin
            </p>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Inventory Value
            </CardTitle>
            <BoxIcon className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {formatNaira(inventoryReport?.total_value)}
            </div>
            <p className="text-xs text-muted-foreground">
              {inventoryReport?.low_stock_items?.length || 0} items low stock
            </p>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Pending Payments
            </CardTitle>
            <FileTextIcon className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {formatNaira(salesReport?.total_pending)}
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Monthly Performance */}
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <TrendingUpIcon className="h-5 w-5" />
              Monthly Performance
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={monthlyData}>
                  <defs>
                    <linearGradient id="sales" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="5%"
                        stopColor="var(--chart-1-hex)"
                        stopOpacity={0.8}
                      />
                      <stop
                        offset="95%"
                        stopColor="var(--chart-1-hex)"
                        stopOpacity={0}
                      />
                    </linearGradient>
                    <linearGradient id="expenses" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="5%"
                        stopColor="var(--chart-2-hex)"
                        stopOpacity={0.8}
                      />
                      <stop
                        offset="95%"
                        stopColor="var(--chart-2-hex)"
                        stopOpacity={0}
                      />
                    </linearGradient>
                  </defs>
                  <CartesianGrid
                    strokeDasharray="3 3"
                    className="stroke-border"
                  />
                  <XAxis 
                    dataKey="month" 
                    className="text-muted-foreground"
                    tick={{ fontSize: 12 }}
                    interval={0}
                    angle={-45}
                    textAnchor="end"
                    height={60}
                  />
                  <YAxis 
                    className="text-muted-foreground"
                    tickFormatter={(value) => formatNaira(value)}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "var(--background-hex)",
                      borderColor: "var(--border-hex)",
                      borderRadius: "var(--radius)",
                    }}
                    labelStyle={{ color: "var(--foreground-hex)" }}
                    formatter={(value: number) => [formatNaira(value), ""]}
                  />
                  <Legend />
                  <Area
                    type="monotone"
                    dataKey="sales"
                    name="Sales"
                    stroke="var(--chart-1-hex)"
                    fillOpacity={1}
                    fill="url(#sales)"
                  />
                  <Area
                    type="monotone"
                    dataKey="expenses"
                    name="Expenses"
                    stroke="var(--chart-2-hex)"
                    fillOpacity={1}
                    fill="url(#expenses)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        {/* Inventory Value by Type */}
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <PackageIcon className="h-5 w-5" />
              Inventory Value by Type
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={stockData}>
                  <CartesianGrid
                    strokeDasharray="3 3"
                    className="stroke-border"
                  />
                  <XAxis 
                    dataKey="type" 
                    className="text-muted-foreground"
                    tick={{ fontSize: 12 }}
                    interval={0}
                  />
                  <YAxis 
                    className="text-muted-foreground"
                    tickFormatter={(value) => formatNaira(value)}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "var(--background-hex)",
                      borderColor: "var(--border-hex)",
                      borderRadius: "var(--radius)",
                    }}
                    labelStyle={{ color: "var(--foreground-hex)" }}
                    formatter={(value: number) => [formatNaira(value), ""]}
                  />
                  <Bar dataKey="value" name="Value" fill="var(--chart-1-hex)" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        {/* Low Stock Items */}
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <TrendingDownIcon className="h-5 w-5" />
              Low Stock Items
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {inventoryReport?.low_stock_items?.map((item) => (
                <div
                  key={item.id}
                  className="flex items-center justify-between"
                >
                  <div>
                    <p className="font-medium">{item.name}</p>
                    <p className="text-sm text-muted-foreground">
                      Quantity
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="font-medium">
                      {formatNumber(item.current_quantity)} pcs
                    </p>
                    <p className="text-sm text-muted-foreground">
                      Min: {formatNumber(item.min_quantity)} pcs
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>

        {/* Top Selling Items */}
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <TrendingUpIcon className="h-5 w-5" />
              Top Selling Items
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {salesReport?.top_selling_items?.map((item) => (
                <div
                  key={item.name}
                  className="flex items-center justify-between"
                >
                  <p className="font-medium">{item.name}</p>
                  <p className="font-medium">{formatNaira(item.revenue)}</p>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
