import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface DisplayPreferences {
  compactMode: boolean;
  largeText: boolean;
  lowStockAlerts: boolean;
  paymentReminders: boolean;
  setCompactMode: (enabled: boolean) => void;
  setLargeText: (enabled: boolean) => void;
  setLowStockAlerts: (enabled: boolean) => void;
  setPaymentReminders: (enabled: boolean) => void;
}

export const useDisplayPreferences = create<DisplayPreferences>()(
  persist(
    (set) => ({
      compactMode: false,
      largeText: false,
      lowStockAlerts: true,
      paymentReminders: true,
      setCompactMode: (enabled) => set({ compactMode: enabled }),
      setLargeText: (enabled) => set({ largeText: enabled }),
      setLowStockAlerts: (enabled) => set({ lowStockAlerts: enabled }),
      setPaymentReminders: (enabled) => set({ paymentReminders: enabled }),
    }),
    {
      name: 'display-preferences',
    }
  )
);
