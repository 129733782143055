import * as z from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog"
import { Button } from "../ui/button"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../ui/form"
import { Input } from "../ui/input"
import { useState } from "react"
import { useSecuritySettings } from "../../hooks/use-security-settings"
import { QrCode, Copy, CheckCircle2 } from "lucide-react"

const verificationSchema = z.object({
  code: z.string().length(6, "Verification code must be 6 digits"),
});

type VerificationFormValues = z.infer<typeof verificationSchema>

export function TwoFactorDialog() {
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState<'intro' | 'setup' | 'verify'>('intro')
  const [backupCodes, setBackupCodes] = useState<string[]>([])
  const { settings, toggleTwoFactor } = useSecuritySettings()
  
  const form = useForm<VerificationFormValues>({
    resolver: zodResolver(verificationSchema),
    defaultValues: {
      code: "",
    },
  })

  function generateBackupCodes() {
    // In a real implementation, these would be generated on the server
    const codes = Array.from({ length: 8 }, () => 
      Math.random().toString(36).substring(2, 8).toUpperCase()
    );
    setBackupCodes(codes);
  }

  function onSubmit(data: VerificationFormValues) {
    // Here you would typically verify the code with the server
    if (data.code === "123456") { // Simulated verification
      toggleTwoFactor(true);
      setOpen(false);
      form.reset();
    }
  }

  function handleDisable() {
    toggleTwoFactor(false);
    setOpen(false);
  }

  if (settings.twoFactorEnabled) {
    return (
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button variant="outline" className="w-full">
            Disable 2FA
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Disable Two-Factor Authentication</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <p className="text-sm text-muted-foreground">
              Are you sure you want to disable two-factor authentication? This will make your account less secure.
            </p>
            <div className="flex justify-end space-x-4">
              <Button
                type="button"
                variant="outline"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                type="button"
                variant="destructive"
                onClick={handleDisable}
              >
                Disable 2FA
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" className="w-full">
          Enable 2FA
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Set Up Two-Factor Authentication</DialogTitle>
        </DialogHeader>
        
        {step === 'intro' && (
          <div className="space-y-4">
            <div className="flex items-start space-x-4">
              <QrCode className="h-8 w-8 text-primary" />
              <div>
                <h4 className="text-sm font-medium">Scan QR Code</h4>
                <p className="text-sm text-muted-foreground">
                  Use an authenticator app to scan the QR code we'll provide
                </p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <Copy className="h-8 w-8 text-primary" />
              <div>
                <h4 className="text-sm font-medium">Save Backup Codes</h4>
                <p className="text-sm text-muted-foreground">
                  Store backup codes in a safe place for account recovery
                </p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <CheckCircle2 className="h-8 w-8 text-primary" />
              <div>
                <h4 className="text-sm font-medium">Verify Setup</h4>
                <p className="text-sm text-muted-foreground">
                  Enter a verification code to confirm everything is working
                </p>
              </div>
            </div>
            <Button
              className="w-full"
              onClick={() => {
                setStep('setup');
                generateBackupCodes();
              }}
            >
              Begin Setup
            </Button>
          </div>
        )}

        {step === 'setup' && (
          <div className="space-y-4">
            <div className="rounded-lg border p-4">
              <div className="aspect-square w-full max-w-[200px] mx-auto bg-muted flex items-center justify-center">
                <span className="text-sm text-muted-foreground">QR Code Placeholder</span>
              </div>
            </div>
            
            <div className="space-y-2">
              <h4 className="text-sm font-medium">Backup Codes</h4>
              <p className="text-sm text-muted-foreground">
                Save these backup codes in a secure place. They can be used to recover your account if you lose access to your authenticator app.
              </p>
              <div className="grid grid-cols-2 gap-2">
                {backupCodes.map((code, index) => (
                  <code key={index} className="rounded bg-muted px-2 py-1 text-sm">
                    {code}
                  </code>
                ))}
              </div>
            </div>

            <Button
              className="w-full"
              onClick={() => setStep('verify')}
            >
              Continue
            </Button>
          </div>
        )}

        {step === 'verify' && (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="code"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Verification Code</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Enter 6-digit code"
                        {...field}
                        maxLength={6}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex justify-end space-x-4">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => setStep('setup')}
                >
                  Back
                </Button>
                <Button type="submit">Verify</Button>
              </div>
            </form>
          </Form>
        )}
      </DialogContent>
    </Dialog>
  )
}
