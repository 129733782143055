// @ts-nocheck
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowUpRight } from "lucide-react";

import { Badge } from "../components/ui/badge";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../components/ui/form";
import { useForm } from "react-hook-form";
import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog";
import { Input } from "../components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { useToast } from "../utils/use-toast";
import brain from "../brain";
import { Customer, Invoice, Sale } from "../types";
import { useSaleData } from "../hooks/sales/useSaleData";
import logger from "../utils/logger";

const formatDimensionalDetails = (item: any) => {
  if (item.type !== "DIMENSIONAL") return null;
  
  const width = item.width !== undefined ? parseFloat(item.width) : null;
  const length = item.length !== undefined ? parseFloat(item.length) : null;
  const area = item.area !== undefined ? parseFloat(item.area) : null;
  
  if (width === null || length === null || isNaN(width) || isNaN(length)) {
    if (area !== null && !isNaN(area)) {
      return `${area.toFixed(2)} sq ft`;
    }
    return "Invalid dimensions";
  }
  
  return `${width.toFixed(2)} x ${length.toFixed(2)} ft (${area ? area.toFixed(2) : (width * length).toFixed(2)} sq ft)`;
};

export default function InvoiceDetails() {
  const { id: invoiceId } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { sales, invoices, isLoading, error } = useSaleData();

  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [sale, setSale] = useState<Sale | null>(null);
  const [customer, setCustomer] = useState<Customer | null>(null);
  
  const paymentForm = useForm<{ amount: number }>({
    defaultValues: {
      amount: 0,
    },
  });
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);

  useEffect(() => {
    const loadInvoiceData = async () => {
      try {
        // Find invoice in our local state
        const foundInvoice = invoices.find(inv => inv.id === invoiceId);
        if (!foundInvoice) {
          throw new Error('Invoice not found');
        }
        setInvoice(foundInvoice);

        // Find related sale
        const relatedSale = sales.find(s => s.id === foundInvoice.sale_id);
        if (!relatedSale) {
          throw new Error('Related sale not found');
        }
        setSale(relatedSale);

        // Load customer details
        const customerData = await brain.get_customer({
          customerId: foundInvoice.customer_id,
        });
        setCustomer(customerData);

        logger.info('Invoice data loaded successfully', { 
          invoiceId,
          saleId: relatedSale.id,
          customerId: customerData.id 
        });
      } catch (error) {
        logger.error('Error loading invoice details:', error);
        toast({
          variant: "destructive",
          title: "Error",
          description: error instanceof Error ? error.message : "Failed to load invoice details",
        });
      }
    };

    if (invoiceId && !isLoading) {
      loadInvoiceData();
    }
  }, [invoiceId, invoices, sales, isLoading, toast]);

  const handleSendInvoice = async () => {
    if (!invoice) return;

    try {
      // TODO: Implement email sending functionality
      toast({
        title: "Success",
        description: "Invoice sent successfully",
      });
    } catch (error) {
      logger.error('Error sending invoice:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to send invoice",
      });
    }
  };

  const handleRecordPayment = async (data: { amount: number }) => {
    if (!invoice) return;

    try {
      // TODO: Implement payment recording functionality
      setShowPaymentDialog(false);
      toast({
        title: "Success",
        description: "Payment recorded successfully",
      });
    } catch (error) {
      logger.error('Error recording payment:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to record payment",
      });
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!invoice || !sale || !customer) {
    return <div>Invoice not found</div>;
  }

  return (
    <div className="container mx-auto py-10 space-y-8">
      <div className="flex justify-between items-center">
        <h2 className="text-3xl font-bold tracking-tight">Invoice Details</h2>
        <div className="space-x-4">
          <Dialog open={showPaymentDialog} onOpenChange={setShowPaymentDialog}>
            <DialogTrigger asChild>
              <Button>Record Payment</Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Record Payment</DialogTitle>
              </DialogHeader>
              <Form {...paymentForm}>
                <form onSubmit={paymentForm.handleSubmit(handleRecordPayment)}>
                  <FormField
                    control={paymentForm.control}
                    name="amount"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Amount</FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            step="0.01"
                            placeholder="Enter amount"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="flex justify-end mt-4">
                    <Button type="submit">Submit</Button>
                  </div>
                </form>
              </Form>
            </DialogContent>
          </Dialog>
          <Button onClick={handleSendInvoice}>
            Send Invoice
            <ArrowUpRight className="ml-2 h-4 w-4" />
          </Button>
        </div>
      </div>

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        <Card>
          <CardHeader>
            <CardTitle>Invoice Status</CardTitle>
          </CardHeader>
          <CardContent>
            <Badge>{invoice.status}</Badge>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Customer Details</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-sm">{customer.name}</p>
            <p className="text-sm text-muted-foreground">{customer.email}</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Amount Details</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span className="text-sm">Total Amount:</span>
                <span className="text-sm font-medium">
                  ₦{invoice.total_amount.toFixed(2)}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-sm">Amount Paid:</span>
                <span className="text-sm font-medium">
                  ₦{invoice.amount_paid.toFixed(2)}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-sm">Balance Due:</span>
                <span className="text-sm font-medium">
                  ₦{(invoice.total_amount - invoice.amount_paid).toFixed(2)}
                </span>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Items</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Item</TableHead>
                <TableHead>Dimensions</TableHead>
                <TableHead>Quantity</TableHead>
                <TableHead className="text-right">Amount</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {invoice.items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{formatDimensionalDetails(item)}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell className="text-right">
                    ₦{item.total_price.toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
}
