import { UseFormReturn } from "react-hook-form";
import { Trash2 } from "lucide-react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import { InventoryItem, SaleItem } from "../../../types";
import { FormSaleData } from "../../../hooks/sales/useSaleForm";

interface ItemsSectionProps {
  form: UseFormReturn<FormSaleData>;
  inventory: InventoryItem[];
  selectedItems: SaleItem[];
  onAddItem: (data: FormSaleData, inventoryItem: InventoryItem) => boolean;
  onRemoveItem: (id: string) => void;
  onItemAdded: () => void;
}

export function ItemsSection({
  form,
  inventory,
  selectedItems,
  onAddItem,
  onRemoveItem,
  onItemAdded,
}: ItemsSectionProps) {
  const watchInventoryItem = form.watch("inventory_item_id");
  const selectedInventoryItem = inventory.find(
    (item) => item.id === watchInventoryItem
  );

  const handleAddItem = () => {
    const data = form.getValues();
    const inventoryItem = inventory.find(
      (item) => item.id === data.inventory_item_id
    );
    if (inventoryItem && onAddItem(data, inventoryItem)) {
      onItemAdded();
    }
  };

  return (
    <div className="space-y-4">
      <div className="border rounded-lg p-4 space-y-4">
        <h3 className="font-medium">Add Items</h3>
        <FormField
          control={form.control}
          name="inventory_item_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Item</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select item" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {inventory.map((item) => (
                    <SelectItem key={item.id} value={item.id}>
                      {item.name} ({item.type})
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        {selectedInventoryItem?.type === "quantity" ? (
          <FormField
            control={form.control}
            name="quantity"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Quantity</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    {...field}
                    onChange={(e) => field.onChange(parseInt(e.target.value))}
                    placeholder="0"
                    min="0"
                    required
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        ) : selectedInventoryItem?.type === "dimensional" ? (
          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="width"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Width (ft)</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      {...field}
                      onChange={(e) => field.onChange(parseFloat(e.target.value))}
                      placeholder="0.00"
                      min="0.01"
                      step="0.01"
                      required
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="length"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Length (ft)</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      {...field}
                      onChange={(e) => field.onChange(parseFloat(e.target.value))}
                      placeholder="0.00"
                      min="0.01"
                      step="0.01"
                      required
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        ) : null}

        {selectedInventoryItem && (
          <FormField
            control={form.control}
            name="unit_price"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {selectedInventoryItem?.type === "dimensional"
                    ? "Price per sq ft"
                    : "Price per unit"}
                </FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    {...field}
                    onChange={(e) => field.onChange(parseFloat(e.target.value))}
                    placeholder="0.00"
                    min="0.01"
                    step="0.01"
                    required
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        <Button type="button" onClick={handleAddItem}>
          Add Item
        </Button>
      </div>

      {selectedItems.length > 0 && (
        <div className="border rounded-lg p-4">
          <h3 className="font-medium mb-4">Selected Items</h3>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Item</TableHead>
                <TableHead>Details</TableHead>
                <TableHead>Price</TableHead>
                <TableHead>Total</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {selectedItems.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    {'quantity' in item ? (
                      <span>{item.quantity} pcs</span>
                    ) : (
                      <span>
                        {item.width} × {item.length} ft ({item.area} sq ft)
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    ₦
                    {'quantity' in item
                      ? item.unit_price.toFixed(2)
                      : `${item.unit_price.toFixed(2)}/sq ft`}
                  </TableCell>
                  <TableCell>₦{item.total_price.toFixed(2)}</TableCell>
                  <TableCell>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => onRemoveItem(item.id)}
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
}
