import { Outlet } from "react-router-dom";
import { Navbar } from "./Navbar";
import { Toaster } from "../Toaster";
import { Breadcrumb } from "../Breadcrumb";
import { useTheme } from "../../hooks/use-theme";
import { useEffect } from "react";
import { DisplayPreferencesProvider } from "./DisplayPreferencesProvider";

export function RootLayout() {
  const { theme } = useTheme();

  // Apply theme class to html element
  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove("light", "dark");
    root.classList.add(theme === "system" ? (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light") : theme);
  }, [theme]);

  return (
    <div className="min-h-screen bg-background text-foreground">
      <Navbar />
      <DisplayPreferencesProvider>
        <main className="mx-auto max-w-[1800px] space-y-6 [&.compact-mode]:space-y-3">
          <div className="p-4 md:p-8 [.compact-mode_&]:p-2 md:[.compact-mode_&]:p-4">
            {/* Breadcrumb and Page Header */}
            <div className="flex flex-col space-y-2 xl:flex-row xl:items-center xl:justify-between xl:space-y-0">
              <Breadcrumb />
            </div>
            
            {/* Page Content */}
            <div className="rounded-lg border bg-card p-4 md:p-6 [.compact-mode_&]:p-2 md:[.compact-mode_&]:p-3 shadow-sm">
              <Outlet />
            </div>
          </div>
        </main>
      </DisplayPreferencesProvider>
      <Toaster />
    </div>
  );
}
