import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface SecuritySettings {
  lastPasswordChange: string;
  twoFactorEnabled: boolean;
  passwordStrength: 'weak' | 'medium' | 'strong';
}

interface SecuritySettingsStore {
  settings: SecuritySettings;
  updateLastPasswordChange: (date: string) => void;
  toggleTwoFactor: (enabled: boolean) => void;
  updatePasswordStrength: (strength: 'weak' | 'medium' | 'strong') => void;
}

export const useSecuritySettings = create<SecuritySettingsStore>()(
  persist(
    (set) => ({
      settings: {
        lastPasswordChange: new Date().toISOString(),
        twoFactorEnabled: false,
        passwordStrength: 'medium',
      },
      updateLastPasswordChange: (date) =>
        set((state) => ({
          settings: {
            ...state.settings,
            lastPasswordChange: date,
          },
        })),
      toggleTwoFactor: (enabled) =>
        set((state) => ({
          settings: {
            ...state.settings,
            twoFactorEnabled: enabled,
          },
        })),
      updatePasswordStrength: (strength) =>
        set((state) => ({
          settings: {
            ...state.settings,
            passwordStrength: strength,
          },
        })),
    }),
    {
      name: 'security-settings',
    }
  )
);
