import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface UserProfile {
  name: string;
  email: string;
  role: string;
  phone: string;
  company: string;
  address: string;
}

interface UserProfileStore {
  profile: UserProfile;
  updateProfile: (data: Partial<UserProfile>) => void;
}

export const useUserProfile = create<UserProfileStore>()(
  persist(
    (set) => ({
      profile: {
        name: "Admin User",
        email: "admin@example.com",
        role: "Administrator",
        phone: "+1234567890",
        company: "PrintPressPro",
        address: "123 Business Street",
      },
      updateProfile: (data) =>
        set((state) => ({
          profile: {
            ...state.profile,
            ...data,
          },
        })),
    }),
    {
      name: 'user-profile',
    }
  )
);
