// @ts-nocheck
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCategories } from "@/hooks/use-categories";
import { CategorySelect } from "@/components/categories";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { PackageIcon, PlusIcon, MoreVerticalIcon, FileDownIcon, PrinterIcon } from "lucide-react";
import { useToast } from "@/utils/use-toast";
import brain from '@/brain';
import { InventoryItem, CreateInventoryData, InventoryUpdate } from "@/types";
import { InventorySearch } from "@/components/search/InventorySearch";
import { RestockDialog } from "@/components/inventory/RestockDialog";
import { InventoryHistory } from "@/components/inventory/InventoryHistory";
import { useAuth } from "@/hooks/use-auth";
import { PencilIcon, TrashIcon } from "lucide-react";
import { 
  AlertDialog, 
  AlertDialogAction, 
  AlertDialogCancel, 
  AlertDialogContent, 
  AlertDialogDescription,
  AlertDialogFooter, 
  AlertDialogHeader, 
  AlertDialogTitle 
} from "@/components/ui/alert-dialog";

type FormInventoryItem = {
  name: string;
  type: "quantity" | "dimensional";
  purchase_type: "unit" | "bundle";
  purchase_cost: number;
  current_quantity?: number;
  min_quantity?: number;
  min_area?: number;
  width?: number;
  length?: number;
  units_per_bundle?: number;
  category_id?: string;
};

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      variant="outline"
      size="sm"
      onClick={() => navigate("/")}
      className="mb-4"
    >
      ← Back to Dashboard
    </Button>
  );
};

export default function Inventory() {
  const [inventory, setInventory] = useState<InventoryItem[]>([]);
  const [filteredInventory, setFilteredInventory] = useState<InventoryItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState<InventoryItem | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<string | null>(null);
  const { toast } = useToast();
  const { categories } = useCategories();
  const { user } = useAuth();
  
  const form = useForm<FormInventoryItem>({
    defaultValues: {
      type: "quantity",
      purchase_type: "unit",
      purchase_cost: 0,
      current_quantity: 0,
      min_quantity: 0,
      min_area: 0,
      width: undefined,
      length: undefined,
      units_per_bundle: undefined,
      category_id: undefined,
    }
  });

  const loadInventory = async () => {
    try {
      const data = await brain.list_inventory();
      setInventory(data);
      setFilteredInventory(data);
    } catch (error: any) {
      console.error("Error loading inventory:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message || "Failed to load inventory",
      });
    }
  };

  useEffect(() => {
    loadInventory();
  }, []);

  const onSubmit = async (data: FormInventoryItem) => {
    try {
      const newItem: CreateInventoryData = {
        name: data.name,
        type: data.type,
        purchase_cost: data.purchase_cost,
        current_quantity: data.current_quantity || 0,
        min_quantity: data.type === "quantity" ? data.min_quantity : undefined,
        min_area: data.type === "dimensional" ? data.min_area : undefined,
        width: data.type === "dimensional" ? data.width : undefined,
        length: data.type === "dimensional" ? data.length : undefined,
        units_per_bundle: data.purchase_type === "bundle" ? data.units_per_bundle : undefined,
        category_id: data.category_id,
      };

      await brain.create_inventory_item(newItem);
      toast({
        title: "Success",
        description: "Inventory item created successfully",
      });
      setOpen(false);
      loadInventory();
      form.reset({
        type: "quantity",
        purchase_type: "unit",
        purchase_cost: 0,
        current_quantity: 0,
        min_quantity: 0,
        min_area: 0,
        width: undefined,
        length: undefined,
        units_per_bundle: undefined,
        category_id: undefined,
      });
    } catch (error: any) {
      console.error("Error creating inventory item:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message || "Failed to create inventory item",
      });
    }
  };

  const handleSearchResults = (results: InventoryItem[]) => {
    setFilteredInventory(results);
  };

  const handleSelectItem = (itemId: string) => {
    setSelectedItems(prev => {
      if (prev.includes(itemId)) {
        return prev.filter(id => id !== itemId);
      }
      return [...prev, itemId];
    });
  };

  const handleSelectAll = () => {
    if (selectedItems.length === filteredInventory.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(filteredInventory.map(item => item.id));
    }
  };

  const handleExportCSV = async () => {
    try {
      const items = selectedItems.length > 0
        ? filteredInventory.filter(item => selectedItems.includes(item.id))
        : filteredInventory;

      const csvContent = [
        // CSV Headers
        ['Name', 'Type', 'Purchase Cost', 'Current Quantity', 'Min Quantity', 'Min Area', 'Width', 'Length', 'Units per Bundle', 'Category', 'Created At'].join(','),
        // CSV Data
        ...items.map(item => [
          item.name,
          item.type,
          item.purchase_cost,
          item.current_quantity,
          item.min_quantity || '',
          item.min_area || '',
          item.width || '',
          item.length || '',
          item.units_per_bundle || '',
          item.category?.name || '',
          new Date(item.created_at).toLocaleDateString()
        ].join(','))
      ].join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `inventory_export_${new Date().toISOString().split('T')[0]}.csv`;
      link.click();
      URL.revokeObjectURL(link.href);

      toast({
        title: "Success",
        description: "Inventory exported successfully",
      });
    } catch (error) {
      console.error('Export error:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to export inventory",
      });
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const handleEditItem = async (data: FormInventoryItem) => {
    try {
      if (!editItem) return;
      
      const updateData: InventoryUpdate = {
        name: data.name,
        type: data.type,
        purchase_cost: data.purchase_cost,
        min_quantity: data.type === "quantity" ? data.min_quantity : undefined,
        min_area: data.type === "dimensional" ? data.min_area : undefined,
        width: data.type === "dimensional" ? data.width : undefined,
        length: data.type === "dimensional" ? data.length : undefined,
        units_per_bundle: data.purchase_type === "bundle" ? data.units_per_bundle : undefined,
        category_id: data.category_id,
      };

      await brain.update_inventory_item(editItem.id, updateData);
      toast({
        title: "Success",
        description: "Inventory item updated successfully",
      });
      setIsEditDialogOpen(false);
      loadInventory();
    } catch (error: any) {
      console.error("Error updating inventory item:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message || "Failed to update inventory item",
      });
    }
  };

  const handleDeleteItem = async () => {
    try {
      if (!itemToDelete) return;
      
      await brain.delete_inventory_item(itemToDelete);
      toast({
        title: "Success",
        description: "Inventory item deleted successfully",
      });
      setDeleteConfirmOpen(false);
      setItemToDelete(null);
      loadInventory();
    } catch (error: any) {
      console.error("Error deleting inventory item:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message || "Failed to delete inventory item",
      });
    }
  };

  const watchType = form.watch("type");
  const watchPurchaseType = form.watch("purchase_type");

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="p-8">
      <BackButton />
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center gap-3">
          <PackageIcon className="h-8 w-8 text-primary" />
          <h1 className="text-3xl font-bold">Inventory Management</h1>
        </div>
        <div className="flex items-center gap-2">
          {selectedItems.length > 0 && (
            <div className="text-sm text-muted-foreground mr-2">
              {selectedItems.length} items selected
            </div>
          )}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" size="icon">
                <MoreVerticalIcon className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={handleExportCSV}>
                <FileDownIcon className="h-4 w-4 mr-2" />
                Export CSV
              </DropdownMenuItem>
              <DropdownMenuItem onClick={handlePrint}>
                <PrinterIcon className="h-4 w-4 mr-2" />
                Print View
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
              <Button>
                <PlusIcon className="h-4 w-4 mr-2" /> Add Item
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Add Inventory Item</DialogTitle>
              </DialogHeader>
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Name</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Item name" required />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="type"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Type</FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select type" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectItem value="quantity">Quantity</SelectItem>
                            <SelectItem value="dimensional">Dimensional</SelectItem>
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {watchType === "quantity" && (
                    <FormField
                      control={form.control}
                      name="purchase_type"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Purchase Type</FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select purchase type" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              <SelectItem value="unit">Unit</SelectItem>
                              <SelectItem value="bundle">Bundle</SelectItem>
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}

                  <FormField
                    control={form.control}
                    name="category_id"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Category</FormLabel>
                        <FormControl>
                          <CategorySelect
                            categories={categories}
                            value={field.value}
                            onChange={field.onChange}
                            placeholder="Select category (optional)"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="purchase_cost"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          {watchType === "quantity" && watchPurchaseType === "bundle" 
                            ? "Bundle Cost" 
                            : "Purchase Cost"}
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            {...field}
                            onChange={(e) => field.onChange(parseFloat(e.target.value))}
                            placeholder="0.00"
                            min="0.01"
                            step="0.01"
                            required
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {watchType === "quantity" ? (
                    <>
                      {watchPurchaseType === "bundle" && (
                        <FormField
                          control={form.control}
                          name="units_per_bundle"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Units per Bundle</FormLabel>
                              <FormControl>
                                <Input
                                  type="number"
                                  {...field}
                                  onChange={(e) => field.onChange(parseInt(e.target.value))}
                                  placeholder="0"
                                  min="1"
                                  required
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      )}
                      <FormField
                        control={form.control}
                        name="current_quantity"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Current Quantity</FormLabel>
                            <FormControl>
                              <Input
                                type="number"
                                {...field}
                                onChange={(e) => field.onChange(parseInt(e.target.value))}
                                placeholder="0"
                                min="0"
                                required
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="min_quantity"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Minimum Quantity</FormLabel>
                            <FormControl>
                              <Input
                                type="number"
                                {...field}
                                onChange={(e) => field.onChange(parseInt(e.target.value))}
                                placeholder="0"
                                min="0"
                                required
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormItem>
                        <FormLabel>Cost per Unit</FormLabel>
                        <FormControl>
                          <Input
                            value={
                              (() => {
                                const purchaseCost = form.watch("purchase_cost") || 0;
                                if (watchPurchaseType === "bundle") {
                                  const unitsPerBundle = form.watch("units_per_bundle") || 1;
                                  return (purchaseCost / unitsPerBundle).toFixed(2);
                                }
                                return purchaseCost.toFixed(2);
                              })()
                            }
                            type="number"
                            disabled
                            placeholder="0.00"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    </>
                  ) : (
                    <>
                      <FormField
                        control={form.control}
                        name="min_area"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Minimum Area (sq ft)</FormLabel>
                            <FormControl>
                              <Input
                                type="number"
                                {...field}
                                onChange={(e) => field.onChange(parseFloat(e.target.value))}
                                placeholder="0.00"
                                min="0.01"
                                step="0.01"
                                required
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <div className="grid grid-cols-2 gap-4">
                        <FormField
                          control={form.control}
                          name="width"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Width (ft)</FormLabel>
                              <FormControl>
                                <Input
                                  type="number"
                                  {...field}
                                  onChange={(e) => field.onChange(parseFloat(e.target.value))}
                                  placeholder="0.00"
                                  min="0.01"
                                  step="0.01"
                                  required
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="length"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Length (ft)</FormLabel>
                              <FormControl>
                                <Input
                                  type="number"
                                  {...field}
                                  onChange={(e) => field.onChange(parseFloat(e.target.value))}
                                  placeholder="0.00"
                                  min="0.01"
                                  step="0.01"
                                  required
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                      <FormField
                        control={form.control}
                        name="current_quantity"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Quantity</FormLabel>
                            <FormControl>
                              <Input
                                type="number"
                                {...field}
                                onChange={(e) => field.onChange(parseInt(e.target.value))}
                                placeholder="0"
                                min="0"
                                required
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormItem>
                        <FormLabel>Area (sq ft)</FormLabel>
                        <FormControl>
                          <Input
                            value={
                              ((form.watch("width") || 0) *
                                (form.watch("length") || 0) *
                                (form.watch("current_quantity") || 0)).toFixed(2)
                            }
                            type="number"
                            disabled
                            placeholder="0.00"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                      <FormItem>
                        <FormLabel>Purchase Cost per sq ft</FormLabel>
                        <FormControl>
                          <Input
                            value={
                              (() => {
                                const area = (form.watch("width") || 0) *
                                  (form.watch("length") || 0) *
                                  (form.watch("current_quantity") || 0);
                                const purchaseCost = form.watch("purchase_cost") || 0;
                                const quantity = form.watch("current_quantity") || 0;
                                return area > 0 ? ((purchaseCost * quantity) / area).toFixed(2) : "0.00";
                              })()
                            }
                            type="number"
                            disabled
                            placeholder="0.00"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    </>
                  )}
                  <Button type="submit" className="w-full">Add Item</Button>
                </form>
              </Form>
            </DialogContent>
          </Dialog>
        </div>
      </div>

      <InventorySearch
        initialData={inventory}
        onSearchResults={handleSearchResults}
        className="mb-6"
      />

      <Card>
        <CardHeader>
          <CardTitle>Inventory Items</CardTitle>
        </CardHeader>
        <CardContent>
          {filteredInventory.length === 0 ? (
            <div>No inventory items found.</div>
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[50px]">
                    <Checkbox
                      checked={selectedItems.length === filteredInventory.length}
                      onCheckedChange={handleSelectAll}
                      aria-label="Select all"
                    />
                  </TableHead>
                  <TableHead>Name</TableHead>
                  <TableHead>Type</TableHead>
                  <TableHead>Category</TableHead>
                  <TableHead>Purchase Cost</TableHead>
                  <TableHead>Stock</TableHead>
                  <TableHead>Purchase Date</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredInventory.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedItems.includes(item.id)}
                        onCheckedChange={() => handleSelectItem(item.id)}
                        aria-label={`Select ${item.name}`}
                      />
                    </TableCell>
                    <TableCell className="flex items-center gap-2">
                      {item.name}
                      {item.type === "quantity" && item.min_quantity && item.current_quantity !== undefined && (
                        <Badge variant={item.current_quantity <= item.min_quantity ? "destructive" : "default"}>
                          {item.current_quantity <= item.min_quantity ? "Low Stock" : "In Stock"}
                        </Badge>
                      )}
                      {item.type === "dimensional" && item.min_area && item.area !== undefined && (
                        <Badge variant={item.area <= item.min_area ? "destructive" : "default"}>
                          {item.area <= item.min_area ? "Low Stock" : "In Stock"}
                        </Badge>
                      )}
                    </TableCell>
                    <TableCell className="capitalize">{item.type}</TableCell>
                    <TableCell>
                      {item.category?.name || 'Uncategorized'}
                    </TableCell>
                    <TableCell>
                      ₦{item.purchase_cost.toFixed(2)}
                      {item.units_per_bundle && (
                        <div className="text-sm text-muted-foreground">
                          (Bundle: {item.units_per_bundle} units)
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      {item.type === "quantity" ? (
                        <div className="space-y-1">
                          <div className="flex items-center gap-2">
                            <div>Quantity: {item.current_quantity} pcs</div>
                            {item.min_quantity && (
                              <div className="text-sm text-muted-foreground">
                                (Min: {item.min_quantity} pcs)
                              </div>
                            )}
                          </div>
                          <div>Cost per Unit: ₦{item.cost_per_unit?.toFixed(2)}</div>
                        </div>
                      ) : (
                        <div className="space-y-1">
                          <div>Dimensions: {item.width} × {item.length} ft</div>
                          <div>Quantity: {item.current_quantity}</div>
                          <div>Area: {item.area?.toFixed(2)} sq ft</div>
                          <div>Cost/sq ft: ₦{item.cost_per_sqft?.toFixed(2)}</div>
                        </div>
                      )}
                    </TableCell>
                    <TableCell>{formatDate(item.created_at)}</TableCell>
                    <TableCell>
                      <div className="flex items-center gap-2">
                        <RestockDialog item={item} onSuccess={loadInventory} />
                        <InventoryHistory item={item} />
                        {user?.role === 'admin' && (
                          <div className="flex items-center gap-2">
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => {
                                setEditItem(item);
                                setIsEditDialogOpen(true);
                                form.reset({
                                  ...item,
                                  purchase_type: item.units_per_bundle ? "bundle" : "unit",
                                });
                              }}
                            >
                              <PencilIcon className="h-4 w-4" />
                            </Button>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => {
                                setItemToDelete(item.id);
                                setDeleteConfirmOpen(true);
                              }}
                            >
                              <TrashIcon className="h-4 w-4" />
                            </Button>
                          </div>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>

      <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Inventory Item</DialogTitle>
            <DialogDescription>
              Make changes to the inventory item below.
            </DialogDescription>
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleEditItem)} className="space-y-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Item name" required />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Type</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      value={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select type" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="quantity">Quantity</SelectItem>
                        <SelectItem value="dimensional">Dimensional</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {watchType === "quantity" && (
                <FormField
                  control={form.control}
                  name="purchase_type"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Purchase Type</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select purchase type" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="unit">Unit</SelectItem>
                          <SelectItem value="bundle">Bundle</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              <FormField
                control={form.control}
                name="category_id"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Category</FormLabel>
                    <FormControl>
                      <CategorySelect
                        categories={categories}
                        value={field.value}
                        onChange={field.onChange}
                        placeholder="Select category (optional)"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="purchase_cost"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {watchType === "quantity" && watchPurchaseType === "bundle" 
                        ? "Bundle Cost" 
                        : "Purchase Cost"}
                    </FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        {...field}
                        onChange={(e) => field.onChange(parseFloat(e.target.value))}
                        placeholder="0.00"
                        min="0.01"
                        step="0.01"
                        required
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {watchType === "quantity" ? (
                <>
                  {watchPurchaseType === "bundle" && (
                    <FormField
                      control={form.control}
                      name="units_per_bundle"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Units per Bundle</FormLabel>
                          <FormControl>
                            <Input
                              type="number"
                              {...field}
                              onChange={(e) => field.onChange(parseInt(e.target.value))}
                              placeholder="0"
                              min="1"
                              required
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}
                  <FormField
                    control={form.control}
                    name="current_quantity"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Current Quantity</FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            {...field}
                            onChange={(e) => field.onChange(parseInt(e.target.value))}
                            placeholder="0"
                            min="0"
                            required
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="min_quantity"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Minimum Quantity</FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            {...field}
                            onChange={(e) => field.onChange(parseInt(e.target.value))}
                            placeholder="0"
                            min="0"
                            required
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormItem>
                    <FormLabel>Cost per Unit</FormLabel>
                    <FormControl>
                      <Input
                        value={
                          (() => {
                            const purchaseCost = form.watch("purchase_cost") || 0;
                            if (watchPurchaseType === "bundle") {
                              const unitsPerBundle = form.watch("units_per_bundle") || 1;
                              return (purchaseCost / unitsPerBundle).toFixed(2);
                            }
                            return purchaseCost.toFixed(2);
                          })()
                        }
                        type="number"
                        disabled
                        placeholder="0.00"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                </>
              ) : (
                <>
                  <FormField
                    control={form.control}
                    name="min_area"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Minimum Area (sq ft)</FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            {...field}
                            onChange={(e) => field.onChange(parseFloat(e.target.value))}
                            placeholder="0.00"
                            min="0.01"
                            step="0.01"
                            required
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="grid grid-cols-2 gap-4">
                    <FormField
                      control={form.control}
                      name="width"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Width (ft)</FormLabel>
                          <FormControl>
                            <Input
                              type="number"
                              {...field}
                              onChange={(e) => field.onChange(parseFloat(e.target.value))}
                              placeholder="0.00"
                              min="0.01"
                              step="0.01"
                              required
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="length"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Length (ft)</FormLabel>
                          <FormControl>
                            <Input
                              type="number"
                              {...field}
                              onChange={(e) => field.onChange(parseFloat(e.target.value))}
                              placeholder="0.00"
                              min="0.01"
                              step="0.01"
                              required
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <FormField
                    control={form.control}
                    name="current_quantity"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Quantity</FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            {...field}
                            onChange={(e) => field.onChange(parseInt(e.target.value))}
                            placeholder="0"
                            min="0"
                            required
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormItem>
                    <FormLabel>Area (sq ft)</FormLabel>
                    <FormControl>
                      <Input
                        value={
                          ((form.watch("width") || 0) *
                            (form.watch("length") || 0) *
                            (form.watch("current_quantity") || 0)).toFixed(2)
                        }
                        type="number"
                        disabled
                        placeholder="0.00"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                  <FormItem>
                    <FormLabel>Purchase Cost per sq ft</FormLabel>
                    <FormControl>
                      <Input
                        value={
                          (() => {
                            const area = (form.watch("width") || 0) *
                              (form.watch("length") || 0) *
                              (form.watch("current_quantity") || 0);
                            const purchaseCost = form.watch("purchase_cost") || 0;
                            const quantity = form.watch("current_quantity") || 0;
                            return area > 0 ? ((purchaseCost * quantity) / area).toFixed(2) : "0.00";
                          })()
                        }
                        type="number"
                        disabled
                        placeholder="0.00"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                </>
              )}
              <DialogFooter>
                <Button type="submit">Save Changes</Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>

      <AlertDialog open={deleteConfirmOpen} onOpenChange={setDeleteConfirmOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete the inventory item
              and all associated history.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleDeleteItem}>Delete</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* Print-friendly styles */}
      <style jsx global>{`
        @media print {
          /* Hide non-printable elements */
          button, 
          .print-hide {
            display: none !important;
          }

          /* Ensure table fits on page */
          table {
            font-size: 12px;
            width: 100%;
            border-collapse: collapse;
          }

          /* Add borders for better print visibility */
          th, td {
            border: 1px solid #ddd;
            padding: 8px;
          }

          /* Force background colors */
          * {
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
          }

          /* Page breaks */
          tr {
            page-break-inside: avoid;
          }
        }
      `}</style>
    </div>
  );
}
