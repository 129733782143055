import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useBreakpoint } from '@/hooks/use-responsive';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { 
  Search as SearchIcon,
  SlidersHorizontal as FiltersIcon,
  X as ClearIcon 
} from 'lucide-react';
import { AdvancedSearch } from './AdvancedSearch';
import debounce from 'lodash/debounce';

export interface SearchFilter {
  field: string;
  operator: 'equals' | 'contains' | 'greaterThan' | 'lessThan';
  value: string | number;
}

export type SearchFieldType = 'text' | 'number' | 'date' | 'select' | 'custom';

export interface SearchField {
  id: string;
  label: string;
  type: SearchFieldType;
  options?: Array<{ value: string; label: string }>;
  component?: React.ComponentType<{
    value: any;
    onChange: (value: any) => void;
  }>;
}

interface SearchBarProps {
  onSearch: (query: string, filters: SearchFilter[]) => void;
  placeholder?: string;
  initialFilters?: SearchFilter[];
  showAdvancedSearch?: boolean;
  className?: string;
  fields: SearchField[];
  isLoading?: boolean;
}

export function SearchBar({
  onSearch,
  placeholder = 'Search...',
  initialFilters = [],
  showAdvancedSearch = true,
  className = '',
  fields,
  isLoading = false,
}: SearchBarProps) {
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState<SearchFilter[]>(initialFilters);
  const [showFilters, setShowFilters] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);
  const isMobile = !useBreakpoint('md');

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((searchQuery: string, searchFilters: SearchFilter[]) => {
      onSearch(searchQuery, searchFilters);
    }, 300),
    [onSearch]
  );

  // Handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    debouncedSearch(newQuery, filters);
  };

  // Clear search
  const handleClear = () => {
    setQuery('');
    setFilters([]);
    debouncedSearch('', []);
  };

  // Toggle filters
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  // Handle filters change
  const handleFiltersChange = (newFilters: SearchFilter[]) => {
    setFilters(newFilters);
    debouncedSearch(query, newFilters);
  };

  // Handle click outside to close filters
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setShowFilters(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div ref={searchRef} className={`relative ${className}`}>
      <div className="flex gap-2">
        <div className="relative flex-1">
          <Input
            type="text"
            value={query}
            onChange={handleSearchChange}
            placeholder={placeholder}
            className="pl-10 pr-10"
            disabled={isLoading}
          />
          <SearchIcon 
            className={`absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 ${
              isLoading ? 'animate-spin' : ''
            } text-muted-foreground`}
          />
          {query && (
            <Button
              variant="ghost"
              size="sm"
              className="absolute right-2 top-1/2 -translate-y-1/2 h-6 w-6 p-0"
              onClick={handleClear}
              disabled={isLoading}
            >
              <ClearIcon className="h-4 w-4" />
            </Button>
          )}
        </div>
        {showAdvancedSearch && (
          <Button
            variant="outline"
            size={isMobile ? 'icon' : 'default'}
            onClick={toggleFilters}
            className={showFilters ? 'bg-accent' : ''}
            disabled={isLoading}
          >
            <FiltersIcon className="h-4 w-4" />
            {!isMobile && (
              <>
                <span className="ml-2">Filters</span>
                {filters.length > 0 && (
                  <span className="ml-2 rounded-full bg-primary px-2 py-0.5 text-xs text-primary-foreground">
                    {filters.length}
                  </span>
                )}
              </>
            )}
          </Button>
        )}
      </div>

      {/* Advanced Search Filters */}
      {showFilters && showAdvancedSearch && (
        <div className="absolute z-10 mt-2 w-full rounded-md border bg-popover p-4 shadow-md">
          <AdvancedSearch
            filters={filters}
            onFiltersChange={handleFiltersChange}
            fields={fields}
          />
        </div>
      )}
    </div>
  );
}
