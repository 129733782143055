/**
 * Format a date string to a localized format
 * @param dateString - The date string to format
 * @returns Formatted date string in the format "MMM DD, YYYY"
 */
export const formatDate = (dateString: string): string => {
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

/**
 * Format a date string for financial reports
 * @param dateString - The date string to format
 * @returns Formatted date string in the format "MMMM DD, YYYY"
 */
export const formatFinancialDate = (dateString: string): string => {
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

/**
 * Format a number to Nigerian Naira currency format
 * @param amount - The amount to format
 * @returns Formatted currency string
 */
export const formatNaira = (amount: number | undefined): string => {
  if (amount === undefined) return '₦0.00';
  return new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

/**
 * Format a number with commas as thousands separators
 * @param number - The number to format
 * @param decimals - Number of decimal places (optional)
 * @returns Formatted number string
 */
export const formatNumber = (number: number | undefined, decimals?: number): string => {
  if (number === undefined) return '0';
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals || 0,
    maximumFractionDigits: decimals || 0
  }).format(number);
};

/**
 * Format a number as a percentage
 * @param number - The number to format (e.g., 0.15 for 15%)
 * @param decimals - Number of decimal places (default: 1)
 * @returns Formatted percentage string
 */
export const formatPercent = (number: number | undefined, decimals: number = 1): string => {
  if (number === undefined) return '0%';
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(number);
};
