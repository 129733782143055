import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TruckIcon, PlusIcon, MoreVerticalIcon, FileDownIcon, PrinterIcon, StarIcon } from "lucide-react";

import { Badge } from "../components/ui/badge";
import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Checkbox } from "../components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import { useToast } from "../utils/use-toast";
import brain from '../brain';
import { Supplier } from "../types/inventory-types";
import { SupplierForm } from "../components/suppliers/SupplierForm";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      variant="outline"
      size="sm"
      onClick={() => navigate("/")}
      className="mb-4"
    >
      ← Back to Dashboard
    </Button>
  );
};

export default function Suppliers() {
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [editingSupplier, setEditingSupplier] = useState<Supplier | null>(null);
  const { toast } = useToast();

  const loadSuppliers = async () => {
    try {
      const data = await brain.list_suppliers();
      setSuppliers(data);
    } catch (error: any) {
      console.error("Error loading suppliers:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message || "Failed to load suppliers",
      });
    }
  };

  useEffect(() => {
    loadSuppliers();
  }, []);

  const handleSubmit = async (data: any) => {
    try {
      if (editingSupplier) {
        await brain.update_supplier({ supplier_id: editingSupplier.id }, data);
        toast({
          title: "Success",
          description: "Supplier updated successfully",
        });
      } else {
        await brain.create_supplier(data);
        toast({
          title: "Success",
          description: "Supplier created successfully",
        });
      }
      setOpen(false);
      setEditingSupplier(null);
      loadSuppliers();
    } catch (error: any) {
      console.error("Error saving supplier:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message || "Failed to save supplier",
      });
    }
  };

  const handleDelete = async (supplierId: string) => {
    try {
      await brain.delete_supplier({ supplier_id: supplierId });
      toast({
        title: "Success",
        description: "Supplier deleted successfully",
      });
      loadSuppliers();
    } catch (error: any) {
      console.error("Error deleting supplier:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message || "Failed to delete supplier",
      });
    }
  };

  const handleExportCSV = () => {
    try {
      const items = selectedSuppliers.length > 0
        ? suppliers.filter(item => selectedSuppliers.includes(item.id))
        : suppliers;

      const csvContent = [
        ['Name', 'Email', 'Phone', 'Contact Person', 'Address', 'Preferred', 'Notes', 'Created At'].join(','),
        ...items.map(item => [
          item.name,
          item.email || '',
          item.phone || '',
          item.contact_person || '',
          (item.address || '').replace(/,/g, ';'),
          item.is_preferred ? 'Yes' : 'No',
          (item.notes || '').replace(/,/g, ';'),
          new Date(item.created_at).toLocaleDateString()
        ].join(','))
      ].join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `suppliers_export_${new Date().toISOString().split('T')[0]}.csv`;
      link.click();
      URL.revokeObjectURL(link.href);

      toast({
        title: "Success",
        description: "Suppliers exported successfully",
      });
    } catch (error) {
      console.error('Export error:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to export suppliers",
      });
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const handleSelectSupplier = (supplierId: string) => {
    setSelectedSuppliers(prev => {
      if (prev.includes(supplierId)) {
        return prev.filter(id => id !== supplierId);
      }
      return [...prev, supplierId];
    });
  };

  const handleSelectAll = () => {
    if (selectedSuppliers.length === suppliers.length) {
      setSelectedSuppliers([]);
    } else {
      setSelectedSuppliers(suppliers.map(s => s.id));
    }
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="p-8">
      <BackButton />
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center gap-3">
          <TruckIcon className="h-8 w-8 text-primary" />
          <h1 className="text-3xl font-bold">Supplier Management</h1>
        </div>
        <div className="flex items-center gap-2">
          {selectedSuppliers.length > 0 && (
            <div className="text-sm text-muted-foreground mr-2">
              {selectedSuppliers.length} suppliers selected
            </div>
          )}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" size="icon">
                <MoreVerticalIcon className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={handleExportCSV}>
                <FileDownIcon className="h-4 w-4 mr-2" />
                Export CSV
              </DropdownMenuItem>
              <DropdownMenuItem onClick={handlePrint}>
                <PrinterIcon className="h-4 w-4 mr-2" />
                Print View
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
              <Button onClick={() => setEditingSupplier(null)}>
                <PlusIcon className="h-4 w-4 mr-2" /> Add Supplier
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>
                  {editingSupplier ? 'Edit Supplier' : 'Add Supplier'}
                </DialogTitle>
              </DialogHeader>
              <SupplierForm
                onSubmit={handleSubmit}
                initialData={editingSupplier || undefined}
                submitLabel={editingSupplier ? 'Update' : 'Add'}
              />
            </DialogContent>
          </Dialog>
        </div>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Suppliers</CardTitle>
        </CardHeader>
        <CardContent>
          {suppliers.length === 0 ? (
            <div>No suppliers found.</div>
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[50px]">
                    <Checkbox
                      checked={selectedSuppliers.length === suppliers.length}
                      onCheckedChange={handleSelectAll}
                      aria-label="Select all"
                    />
                  </TableHead>
                  <TableHead>Name</TableHead>
                  <TableHead>Contact</TableHead>
                  <TableHead>Address</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Created</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {suppliers.map((supplier) => (
                  <TableRow key={supplier.id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedSuppliers.includes(supplier.id)}
                        onCheckedChange={() => handleSelectSupplier(supplier.id)}
                        aria-label={`Select ${supplier.name}`}
                      />
                    </TableCell>
                    <TableCell className="font-medium">
                      <div className="flex items-center gap-2">
                        {supplier.name}
                        {supplier.is_preferred && (
                          <StarIcon className="h-4 w-4 text-yellow-500" />
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="space-y-1">
                        {supplier.contact_person && (
                          <div className="text-sm">{supplier.contact_person}</div>
                        )}
                        {supplier.email && (
                          <div className="text-sm">{supplier.email}</div>
                        )}
                        {supplier.phone && (
                          <div className="text-sm">{supplier.phone}</div>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>{supplier.address}</TableCell>
                    <TableCell>
                      <Badge variant={supplier.is_preferred ? "default" : "secondary"}>
                        {supplier.is_preferred ? "Preferred" : "Standard"}
                      </Badge>
                    </TableCell>
                    <TableCell>{formatDate(supplier.created_at)}</TableCell>
                    <TableCell>
                      <div className="flex items-center gap-2">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => {
                            setEditingSupplier(supplier);
                            setOpen(true);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="destructive"
                          size="sm"
                          onClick={() => handleDelete(supplier.id)}
                        >
                          Delete
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>

      {/* Add print styles using a style tag */}
      <style>
        {`
          @media print {
            button, 
            .print-hide {
              display: none !important;
            }
            table {
              font-size: 12px;
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 8px;
            }
            * {
              -webkit-print-color-adjust: exact;
              print-color-adjust: exact;
            }
            tr {
              page-break-inside: avoid;
            }
          }
        `}
      </style>
    </div>
  );
}
