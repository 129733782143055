import { useState, useEffect } from 'react';
import { InventoryItem } from '../../types';
import brain from '../../brain';
import logger from '../../utils/logger';

export function useInventory() {
  const [inventory, setInventory] = useState<InventoryItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchInventory = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const data = await brain.list_inventory();
      setInventory(data);
    } catch (err) {
      logger.error('Error fetching inventory:', err);
      setError(err instanceof Error ? err : new Error('Failed to fetch inventory'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInventory();
  }, []);

  return {
    inventory,
    isLoading,
    error,
    refetch: fetchInventory,
  };
}
