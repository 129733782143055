import { useState, useCallback } from 'react';
import { useToast } from '../utils/use-toast';
import { useAuth } from '../context/AuthContext';
import type {
  Category,
  CategoryCreate,
  CategoryUpdate,
  CategoryListParams,
  CategoryDeleteParams
} from '../types/category';

interface UseCategoriesOptions {
  onError?: (error: Error) => void;
}

export const useCategories = (options: UseCategoriesOptions = {}) => {
  const { toast } = useToast();
  const { token } = useAuth();
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(false); // Start with false
  const [initialLoad, setInitialLoad] = useState(true); // Separate initial load state
  const [error, setError] = useState<Error | null>(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const limit = 100;

  const handleError = useCallback((err: unknown) => {
    const error = err instanceof Error? err : new Error('An error occurred');
    setError(error);
    options.onError?.(error);
    toast({
      title: 'Error',
      description: error.message,
      variant: 'destructive',
    });
  }, [options, toast]);

  const fetchCategories = useCallback(async (params?: CategoryListParams) => {
    if (!initialLoad && !loading) return; // Prevent duplicate fetches
    
    try {
      const queryParams = new URLSearchParams();
      if (params?.parentId) {
        queryParams.append('parent_id', params.parentId);
      }
      if (params?.includeSubcategories !== undefined) {
        queryParams.append('include_subcategories', params.includeSubcategories.toString());
      }
      queryParams.append('limit', String(limit));
      queryParams.append('offset', '0'); // Start from first page

      const response = await fetch(`/api/categories?${queryParams}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
        }
      });
      
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.detail || 'Failed to fetch categories');
      }

      const data = await response.json();
      if (data.length < limit) {
        setHasMore(false);
      }
      setCategories(data); // Replace categories instead of appending
      setPage(0); // Reset page when fetching new categories
      setError(null);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
      setInitialLoad(false);
    }
  }, [handleError, token, limit, loading, initialLoad]);

  const fetchNextPage = useCallback(async () => {
    if (!hasMore || loading) return;

    try {
      setLoading(true);
      const nextPage = page + 1;
      const queryParams = new URLSearchParams();
      queryParams.append('limit', String(limit));
      queryParams.append('offset', String(nextPage * limit));

      const response = await fetch(`/api/categories?${queryParams}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
        }
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.detail || 'Failed to fetch more categories');
      }

      const data = await response.json();
      if (data.length < limit) {
        setHasMore(false);
      }
      setCategories(prev => [...prev, ...data]);
      setPage(nextPage);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  }, [hasMore, loading, page, limit, token, handleError]);

  const createCategory = useCallback(async (data: CategoryCreate) => {
    try {
      const response = await fetch('/api/categories', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          name: data.name,
          description: data.description,
          parent_id: data.parentId
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to create category');
      }

      const newCategory = await response.json();
      setCategories(prevCategories => {
        const updated = [...prevCategories];
        if (newCategory.parent_id) {
          // Find and update parent's subcategories
          const updateParent = (categories: Category[]): Category[] => {
            return categories.map(category => {
              if (category.id === newCategory.parent_id) {
                return {
                  ...category,
                  subcategories: [...(category.subcategories || []), newCategory]
                };
              }
              if (category.subcategories?.length) {
                return {
                  ...category,
                  subcategories: updateParent(category.subcategories)
                };
              }
              return category;
            });
          };
          return updateParent(updated);
        }
        // Add to root level if no parent
        return [...updated, newCategory];
      });
      return true;
    } catch (err) {
      handleError(err);
      return false;
    }
  }, [handleError, token]);

  const updateCategory = useCallback(async (id: string, data: CategoryUpdate) => {
    try {
      const response = await fetch(`/api/categories/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          name: data.name,
          description: data.description,
          parent_id: data.parentId
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to update category');
      }

      const updatedCategory = await response.json();
      setCategories(prevCategories => {
        const updateInTree = (categories: Category[]): Category[] => {
          return categories.map(category => {
            if (category.id === id) {
              return updatedCategory;
            }
            if (category.subcategories?.length) {
              return {
                ...category,
                subcategories: updateInTree(category.subcategories)
              };
            }
            return category;
          });
        };
        return updateInTree(prevCategories);
      });
      return true;
    } catch (err) {
      handleError(err);
      return false;
    }
  }, [handleError, token]);

  const deleteCategory = useCallback(async (id: string, params?: CategoryDeleteParams) => {
    try {
      const queryParams = new URLSearchParams();
      if (params?.reassignTo) {
        queryParams.append('reassign_to', params.reassignTo);
      }

      const response = await fetch(`/api/categories/${id}?${queryParams}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to delete category');
      }

      setCategories(prevCategories => {
        const removeFromTree = (categories: Category[]): Category[] => {
          return categories.filter(category => {
            if (category.id === id) {
              return false;
            }
            if (category.subcategories?.length) {
              category.subcategories = removeFromTree(category.subcategories);
            }
            return true;
          });
        };
        return removeFromTree(prevCategories);
      });
      return true;
    } catch (err) {
      handleError(err);
      return false;
    }
  }, [handleError, token]);

  const moveCategory = useCallback(async (
    sourceId: string,
    destinationParentId: string | null,
    position: number
  ) => {
    try {
      const response = await fetch(`/api/categories/${sourceId}/move`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          parent_id: destinationParentId,
          position,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to move category');
      }

      // Instead of fetching fresh data, update locally
      setCategories(prevCategories => {
        const moveInTree = (categories: Category[]): Category[] => {
          const source = categories.find(c => c.id === sourceId);
          if (!source) return categories;

          // Remove from current position
          const withoutSource = categories.filter(c => c.id !== sourceId);

          // If moving to root level
          if (!destinationParentId) {
            return [
              ...withoutSource.slice(0, position),
              source,
              ...withoutSource.slice(position)
            ];
          }

          // Move within tree
          return withoutSource.map(category => {
            if (category.id === destinationParentId) {
              const subcategories = [...(category.subcategories || [])];
              subcategories.splice(position, 0, source);
              return { ...category, subcategories };
            }
            if (category.subcategories?.length) {
              return {
                ...category,
                subcategories: moveInTree(category.subcategories)
              };
            }
            return category;
          });
        };
        return moveInTree(prevCategories);
      });
      return true;
    } catch (err) {
      handleError(err);
      return false;
    }
  }, [handleError, token]);

  return {
    categories,
    loading,
    error,
    fetchCategories,
    createCategory,
    updateCategory,
    deleteCategory,
    moveCategory,
    fetchNextPage,
    hasMore,
    initialLoad,
  };
};
