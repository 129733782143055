import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HttpClient } from '../brain/http-client';
import { toast } from '../utils/use-toast';

// Configure API URL based on environment
const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:8000' 
    : `https://${window.location.hostname}`;

const httpClient = new HttpClient(API_URL);

interface User {
    username: string;
    email: string;
    role: string;
    is_active: boolean;
}

interface AuthContextType {
    user: User | null;
    token: string | null;
    login: (token: string) => void;
    logout: () => void;
    isAuthenticated: boolean;
    loading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const [user, setUser] = useState<User | null>(null);
    const [token, setToken] = useState<string | null>(() => {
        return localStorage.getItem('auth_token');
    });
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const isAuthenticated = !!token && !!user;

    const logout = () => {
        localStorage.removeItem('auth_token');
        setToken(null);
        setUser(null);
        toast({
            title: "Logged out successfully",
            description: "You have been securely logged out of your account.",
            variant: "default",
        });
        navigate('/login');
    };

    const login = (newToken: string) => {
        localStorage.setItem('auth_token', newToken);
        setToken(newToken);
        toast({
            title: "Logged in successfully",
            description: "Welcome back to PrintPressPro!",
            variant: "default",
        });
    };

    useEffect(() => {
        const fetchUser = async () => {
            if (token) {
                try {
                    const response = await httpClient.request<User>('GET', '/api/auth/me');
                    const userData = await response.json();
                    if (userData) {
                        setUser(userData);
                    } else {
                        logout();
                    }
                } catch (error) {
                    console.error('Failed to fetch user:', error);
                    toast({
                        title: "Session expired",
                        description: "Please log in again to continue.",
                        variant: "destructive",
                    });
                    logout();
                } finally {
                    setLoading(false);
                }
            } else {
              setLoading(false);
            }
        };

        fetchUser();
    }, [token]);

    return (
        <AuthContext.Provider value={{ user, token, login, logout, isAuthenticated, loading }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}
