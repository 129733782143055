import { useSearchParams } from "react-router-dom";
import { ResetPasswordConfirm } from "../components/auth/ResetPasswordConfirm";

export default function ResetConfirm() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  if (!token) {
    return (
      <div className="container max-w-[400px] mx-auto py-10">
        <div className="space-y-4">
          <h3 className="text-lg font-medium text-red-600">Invalid Reset Link</h3>
          <p className="text-sm text-muted-foreground">
            The password reset link appears to be invalid or has expired. Please request a new password reset link.
          </p>
          <a
            href="/request-reset"
            className="inline-block text-sm text-primary hover:underline"
          >
            Request new reset link
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="container max-w-[400px] mx-auto py-10">
      <ResetPasswordConfirm token={token} />
    </div>
  );
}
