import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../components/ui/form";
import { Input } from "../components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import { useToast } from "../utils/use-toast";
import { formatNaira, formatFinancialDate } from "../utils/format";
import brain from "../brain";
import { Expense } from "../types";

// Expense categories
const EXPENSE_CATEGORIES = [
  "utilities",
  "rent",
  "salaries",
  "supplies",
  "maintenance",
  "insurance",
  "marketing",
  "other",
];

// Recurring intervals
const RECURRING_INTERVALS = ["monthly", "quarterly", "yearly"];

type FormExpenseData = {
  category: string;
  amount: string;
  description: string;
  date: string;
  is_recurring: boolean;
  recurring_interval?: string;
};

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      variant="outline"
      size="sm"
      onClick={() => navigate("/")}
      className="mb-4"
    >
      ← Back to Dashboard
    </Button>
  );
};

export default function Expenses() {
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [loading, setLoading] = useState(true);
  const [isAddExpenseOpen, setIsAddExpenseOpen] = useState(false);
  const { toast } = useToast();
  const form = useForm<FormExpenseData>({
    defaultValues: {
      category: "",
      amount: "",
      description: "",
      date: new Date().toISOString().split("T")[0],
      is_recurring: false,
      recurring_interval: "",
    }
  });

  const loadExpenses = async () => {
    try {
      setLoading(true);
      const data = await brain.list_expenses();
      setExpenses(data);
    } catch (error) {
      console.error("Error loading expenses:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to load expenses",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadExpenses();
  }, []);

  const onSubmit = async (data: FormExpenseData) => {
    try {
      await brain.create_expense({
        category: data.category,
        amount: parseFloat(data.amount),
        description: data.description,
        date: data.date,
        is_recurring: data.is_recurring,
        recurring_interval: data.is_recurring ? data.recurring_interval : undefined,
      });

      toast({
        title: "Success",
        description: "Expense added successfully",
      });
      setIsAddExpenseOpen(false);
      form.reset({
        category: "",
        amount: "",
        description: "",
        date: new Date().toISOString().split("T")[0],
        is_recurring: false,
        recurring_interval: "",
      });
      loadExpenses();
    } catch (error: any) {
      console.error("Error creating expense:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message || "Failed to create expense",
      });
    }
  };

  // Calculate totals
  const totalExpenses = expenses.reduce((sum, exp) => sum + exp.amount, 0);
  const recurringExpenses = expenses
    .filter((exp) => exp.is_recurring)
    .reduce((sum, exp) => sum + exp.amount, 0);

  // Group expenses by category
  const expensesByCategory = expenses.reduce(
    (acc, exp) => {
      acc[exp.category] = (acc[exp.category] || 0) + exp.amount;
      return acc;
    },
    {} as Record<string, number>,
  );

  return (
    <div className="container mx-auto py-6 space-y-6">
      <div className="flex justify-between items-center gap-4">
        <BackButton />
        <h1 className="text-3xl font-bold">Expenses</h1>
        <Dialog open={isAddExpenseOpen} onOpenChange={setIsAddExpenseOpen}>
          <DialogTrigger asChild>
            <Button>Add Expense</Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add New Expense</DialogTitle>
            </DialogHeader>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                <FormField
                  control={form.control}
                  name="category"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Category</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select category" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {EXPENSE_CATEGORIES.map((category) => (
                            <SelectItem key={category} value={category}>
                              {category.charAt(0).toUpperCase() + category.slice(1)}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="amount"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Amount</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          step="0.01"
                          {...field}
                          placeholder="0.00"
                          required
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Description</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder="Enter description"
                          required
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="date"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Date</FormLabel>
                      <FormControl>
                        <Input
                          type="date"
                          {...field}
                          required
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="is_recurring"
                  render={({ field }) => (
                    <FormItem className="flex items-center space-x-2">
                      <FormControl>
                        <input
                          type="checkbox"
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                            if (!e.target.checked) {
                              form.setValue('recurring_interval', '');
                            }
                          }}
                        />
                      </FormControl>
                      <FormLabel className="!mt-0">Recurring Expense</FormLabel>
                    </FormItem>
                  )}
                />

                {form.watch('is_recurring') && (
                  <FormField
                    control={form.control}
                    name="recurring_interval"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Recurring Interval</FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select interval" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {RECURRING_INTERVALS.map((interval) => (
                              <SelectItem key={interval} value={interval}>
                                {interval.charAt(0).toUpperCase() + interval.slice(1)}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}

                <Button type="submit" className="w-full">Add Expense</Button>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
      </div>

      <div className="grid gap-4 md:grid-cols-3">
        <Card>
          <CardHeader>
            <CardTitle>Total Expenses</CardTitle>
            <CardDescription>All time expenses</CardDescription>
          </CardHeader>
          <CardContent>
            <p className="text-2xl font-bold">{formatNaira(totalExpenses)}</p>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Recurring Expenses</CardTitle>
            <CardDescription>Monthly recurring costs</CardDescription>
          </CardHeader>
          <CardContent>
            <p className="text-2xl font-bold">{formatNaira(recurringExpenses)}</p>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Categories</CardTitle>
            <CardDescription>Expenses by category</CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            {Object.entries(expensesByCategory).map(([category, amount]) => (
              <div key={category} className="flex justify-between items-center">
                <span className="capitalize">{category}</span>
                <span>{formatNaira(amount)}</span>
              </div>
            ))}
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Expense History</CardTitle>
          <CardDescription>List of all expenses</CardDescription>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Date</TableHead>
                <TableHead>Category</TableHead>
                <TableHead>Description</TableHead>
                <TableHead>Amount</TableHead>
                <TableHead>Recurring</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={5} className="text-center">
                    Loading expenses...
                  </TableCell>
                </TableRow>
              ) : expenses.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} className="text-center">
                    No expenses found
                  </TableCell>
                </TableRow>
              ) : (
                expenses.map((expense) => (
                  <TableRow key={expense.id}>
                    <TableCell>
                      {formatFinancialDate(expense.date)}
                    </TableCell>
                    <TableCell className="capitalize">
                      {expense.category}
                    </TableCell>
                    <TableCell>{expense.description}</TableCell>
                    <TableCell>{formatNaira(expense.amount)}</TableCell>
                    <TableCell>
                      {expense.is_recurring ? expense.recurring_interval : "No"}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
}
