import React, { useState, useMemo } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { ChevronRight, ChevronDown, MoreVertical, Pencil, Trash2, AlertCircle, GripVertical } from 'lucide-react';
import { ScrollArea } from '../ui/scroll-area';
import { Button } from '../ui/button';
import { Card } from '../ui/card';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../ui/dropdown-menu';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../ui/alert-dialog';
import type { Category, CategoryTreeItem } from '../../types/category';

interface CategoryTreeProps {
  categories: Category[];
  onMove?: (sourceId: string, destinationId: string | null, position: number) => Promise<boolean>;
  onEdit?: (category: Category) => void;
  onDelete?: (categoryId: string, reassignTo?: string) => Promise<boolean>;
  onToggle?: (categoryId: string, isExpanded: boolean) => void;
  isLoading?: boolean;
  error?: Error | null;
}

const flattenCategories = (
  categories: Category[],
  depth = 0,
  expanded: Record<string, boolean> = {},
  result: CategoryTreeItem[] = []
): CategoryTreeItem[] => {
  categories.forEach((category) => {
    const isExpanded = expanded[category.id] ?? false;
    const hasChildren = category.subcategories && category.subcategories.length > 0;
    
    result.push({
      ...category,
      depth,
      isExpanded,
      hasChildren
    });

    if (hasChildren && isExpanded) {
      flattenCategories(category.subcategories!, depth + 1, expanded, result);
    }
  });

  return result;
};

export const CategoryTree: React.FC<CategoryTreeProps> = ({
  categories,
  onMove,
  onEdit,
  onDelete,
  onToggle,
  isLoading,
  error
}) => {
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState<string | null>(null);
  const [reassignTo, setReassignTo] = useState<string | null>(null);
  
  const flatCategories = useMemo(
    () => flattenCategories(categories, 0, expanded),
    [categories, expanded]
  );

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination || !onMove) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    
    // If dropping at the same position, do nothing
    if (sourceIndex === destinationIndex) return;

    const sourceItem = flatCategories[sourceIndex];
    const destinationItem = flatCategories[destinationIndex];

    // Determine the new parent ID based on drop position and depth
    let newParentId: string | null = null;
    if (destinationIndex > 0) {
      const prevItem = flatCategories[destinationIndex - 1];
      if (prevItem.depth < sourceItem.depth) {
        newParentId = prevItem.id;
      } else if (prevItem.depth === sourceItem.depth && prevItem.parentId) {
        newParentId = prevItem.parentId;
      }
    }

    await onMove(sourceItem.id, newParentId, destinationIndex);
  };

  const handleToggle = (categoryId: string, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event from bubbling to drag handle
    setExpanded(prev => {
      const newExpanded = { ...prev, [categoryId]: !prev[categoryId] };
      onToggle?.(categoryId, !prev[categoryId]);
      return newExpanded;
    });
  };

  const handleDeleteClick = (categoryId: string) => {
    setCategoryToDelete(categoryId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (categoryToDelete && onDelete) {
      const success = await onDelete(categoryToDelete, reassignTo || undefined);
      if (success) {
        setDeleteDialogOpen(false);
        setCategoryToDelete(null);
        setReassignTo(null);
      }
    }
  };

  if (error) {
    return (
      <Card className="p-4 border-red-200 bg-red-50">
        <div className="flex items-center gap-2 text-red-600">
          <AlertCircle className="h-5 w-5" />
          <p>Error loading categories: {error.message}</p>
        </div>
      </Card>
    );
  }

  if (isLoading) {
    return (
      <Card className="p-4">
        <div className="flex items-center justify-center h-32">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
        </div>
      </Card>
    );
  }

  return (
    <>
      <ScrollArea className="h-[400px] w-full rounded-md border p-4">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="category-tree">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="space-y-2"
              >
                {flatCategories.map((category, index) => (
                  <Draggable
                    key={category.id}
                    draggableId={category.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Card
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={`p-2 ${snapshot.isDragging ? 'opacity-50' : ''}`}
                        style={{
                          marginLeft: `${category.depth * 24}px`,
                          ...provided.draggableProps.style
                        }}
                      >
                        <div className="flex items-center justify-between gap-2">
                          <div className="flex items-center gap-2 flex-1">
                            <div {...provided.dragHandleProps} className="cursor-grab">
                              <GripVertical className="h-4 w-4 text-gray-400" />
                            </div>
                            {category.hasChildren && (
                              <Button
                                variant="ghost"
                                size="sm"
                                className="p-0 h-6 w-6"
                                onClick={(e) => handleToggle(category.id, e)}
                              >
                                {expanded[category.id] ? (
                                  <ChevronDown className="h-4 w-4" />
                                ) : (
                                  <ChevronRight className="h-4 w-4" />
                                )}
                              </Button>
                            )}
                            <span className="flex-1 truncate">{category.name}</span>
                          </div>
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button variant="ghost" size="sm" className="h-6 w-6 p-0">
                                <MoreVertical className="h-4 w-4" />
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                              <DropdownMenuItem onClick={() => onEdit?.(category)}>
                                <Pencil className="mr-2 h-4 w-4" />
                                Edit
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={() => handleDeleteClick(category.id)}
                                className="text-red-600"
                              >
                                <Trash2 className="mr-2 h-4 w-4" />
                                Delete
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </div>
                      </Card>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ScrollArea>

      <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete the category. If it has subcategories or items,
              you can reassign them to another category.
            </AlertDialogDescription>
          </AlertDialogHeader>
          {flatCategories.length > 1 && (
            <div className="mb-4">
              <label className="text-sm font-medium">
                Reassign items to (optional):
              </label>
              <select
                className="mt-1 block w-full rounded-md border border-gray-300 p-2"
                value={reassignTo || ''}
                onChange={(e) => setReassignTo(e.target.value || null)}
              >
                <option value="">None</option>
                {flatCategories
                  .filter((cat) => cat.id !== categoryToDelete)
                  .map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
              </select>
            </div>
          )}
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => {
              setDeleteDialogOpen(false);
              setCategoryToDelete(null);
              setReassignTo(null);
            }}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDeleteConfirm}
              className="bg-red-600 hover:bg-red-700"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
