import React, { useState, useEffect } from 'react';
import { Button } from '../components/ui/button';
import { Card } from '../components/ui/card';
import { Loader2, Plus, Eye, Edit, Check } from 'lucide-react';
import { useToast } from '../utils/use-toast';
import { HttpClient } from '../brain/http-client';
import { InvoiceTemplateForm } from '../components/invoices/InvoiceTemplateForm';
import { InvoiceTemplatePreview } from '../components/invoices/InvoiceTemplatePreview';

const httpClient = new HttpClient();

export default function InvoiceTemplates() {
  const [templates, setTemplates] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState<any>(null);
  const [previewTemplate, setPreviewTemplate] = useState<any>(null);
  const { toast } = useToast();

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await httpClient.request('GET', '/api/invoice-templates');
      const data = await response.json();
      setTemplates(data.templates);
    } catch (error) {
      console.error('Error fetching templates:', error);
      toast({
        title: 'Error',
        description: 'Failed to load invoice templates',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (data: any) => {
    try {
      if (editingTemplate) {
        await httpClient.request('PUT', `/api/invoice-templates/${editingTemplate.id}`, {}, data);
        toast({
          title: 'Success',
          description: 'Template updated successfully',
        });
      } else {
        await httpClient.request('POST', '/api/invoice-templates', {}, data);
        toast({
          title: 'Success',
          description: 'Template created successfully',
        });
      }
      
      setShowForm(false);
      setEditingTemplate(null);
      fetchTemplates();
    } catch (error) {
      console.error('Error saving template:', error);
      toast({
        title: 'Error',
        description: 'Failed to save template',
        variant: 'destructive',
      });
    }
  };

  const handleSetDefault = async (templateId: string) => {
    try {
      await httpClient.request('POST', `/api/invoice-templates/${templateId}/set-default`);
      toast({
        title: 'Success',
        description: 'Default template updated',
      });
      fetchTemplates();
    } catch (error) {
      console.error('Error setting default template:', error);
      toast({
        title: 'Error',
        description: 'Failed to set default template',
        variant: 'destructive',
      });
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-2xl font-bold tracking-tight">Invoice Templates</h2>
          <p className="text-muted-foreground">
            Manage and customize your invoice templates
          </p>
        </div>
        <Button onClick={() => setShowForm(true)}>
          <Plus className="h-4 w-4 mr-2" />
          New Template
        </Button>
      </div>

      {showForm && (
        <Card className="p-6">
          <InvoiceTemplateForm
            initialData={editingTemplate}
            onSubmit={handleSubmit}
            isEditing={!!editingTemplate}
          />
        </Card>
      )}

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        {templates.map((template) => (
          <Card key={template.id} className="p-4">
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="font-medium">{template.name}</h3>
                <p className="text-sm text-muted-foreground">
                  {template.description}
                </p>
              </div>
              {template.is_default && (
                <span className="text-xs bg-green-100 text-green-800 px-2 py-1 rounded">
                  Default
                </span>
              )}
            </div>
            
            <div className="flex gap-2">
              <Button
                variant="outline"
                size="sm"
                onClick={() => setPreviewTemplate(template)}
              >
                <Eye className="h-4 w-4 mr-2" />
                Preview
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => {
                  setEditingTemplate(template);
                  setShowForm(true);
                }}
              >
                <Edit className="h-4 w-4 mr-2" />
                Edit
              </Button>
              {!template.is_default && (
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => handleSetDefault(template.id)}
                >
                  <Check className="h-4 w-4 mr-2" />
                  Set Default
                </Button>
              )}
            </div>
          </Card>
        ))}
      </div>

      {previewTemplate && (
        <InvoiceTemplatePreview
          template={previewTemplate}
          isOpen={!!previewTemplate}
          onClose={() => setPreviewTemplate(null)}
        />
      )}
    </div>
  );
}
