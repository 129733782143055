import React from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import type { Category } from '../../types/category';

interface CategorySelectProps {
  categories: Category[];
  value?: string | null;
  onChange: (value: string | null) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  error?: boolean;
}

const flattenCategoriesWithDepth = (
  categories: Category[],
  depth = 0,
  result: { id: string; name: string; depth: number }[] = []
): { id: string; name: string; depth: number }[] => {
  categories.forEach((category) => {
    result.push({
      id: category.id,
      name: category.name,
      depth
    });

    if (category.subcategories?.length) {
      flattenCategoriesWithDepth(category.subcategories, depth + 1, result);
    }
  });

  return result;
};

export const CategorySelect: React.FC<CategorySelectProps> = ({
  categories,
  value,
  onChange,
  placeholder = "Select a category",
  disabled = false,
  className = "",
  error = false
}) => {
  const flatCategories = flattenCategoriesWithDepth(categories);

  const getCategoryName = (categoryId: string | null): string => {
    if (!categoryId) return '';
    const category = flatCategories.find(c => c.id === categoryId);
    return category ? category.name : '';
  };

  return (
    <Select
      value={value === null ? undefined : value}
      onValueChange={onChange}
      disabled={disabled}
    >
      <SelectTrigger 
        className={`${className} ${error ? 'border-red-500' : ''}`}
      >
        <SelectValue placeholder={placeholder}>
          {value ? getCategoryName(value) : placeholder}
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={null as any}>None</SelectItem>
        {flatCategories.map(({ id, name, depth }) => (
          <SelectItem
            key={id}
            value={id}
            className="flex items-center"
          >
            <span
              style={{
                marginLeft: `${depth * 16}px`,
                paddingLeft: depth > 0 ? '8px' : '0',
                borderLeft: depth > 0 ? '1px solid #e2e8f0' : 'none'
              }}
            >
              {name}
            </span>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
