import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import {
  PackageIcon,
  ShoppingCartIcon,
  UsersIcon,
  FileTextIcon,
  AlertCircleIcon,
} from 'lucide-react';
import { Button } from '../components/ui/button';
import { useToast } from '../utils/use-toast';
import brain from '../brain';

export default function Dashboard() {
  const navigate = useNavigate();
  const { toast } = useToast();

  return (
    <div className="p-8">
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-3xl font-bold">Dashboard</h1>
      </div>

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        {/* Inventory Card */}
        <Card>
          <CardHeader>
            <CardTitle>Inventory Management</CardTitle>
            <CardDescription>Manage your inventory items</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <PackageIcon className="h-8 w-8 text-primary mr-2" />
                <div>
                  <div className="text-2xl font-bold">Inventory</div>
                  <div className="text-sm text-muted-foreground">View and manage items</div>
                </div>
              </div>
              <Button
                variant="outline"
                size="sm"
                onClick={() => navigate('/inventory')}
              >
                Manage
              </Button>
            </div>
          </CardContent>
        </Card>

        {/* Sales Card */}
        <Card>
          <CardHeader>
            <CardTitle>Sales</CardTitle>
            <CardDescription>Track and manage sales</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <ShoppingCartIcon className="h-8 w-8 text-primary mr-2" />
                <div>
                  <div className="text-2xl font-bold">Sales</div>
                  <div className="text-sm text-muted-foreground">View sales history</div>
                </div>
              </div>
              <Button
                variant="outline"
                size="sm"
                onClick={() => navigate('/sales')}
              >
                View Sales
              </Button>
            </div>
          </CardContent>
        </Card>

        {/* Customers Card */}
        <Card>
          <CardHeader>
            <CardTitle>Customers</CardTitle>
            <CardDescription>Manage customer information</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <UsersIcon className="h-8 w-8 text-primary mr-2" />
                <div>
                  <div className="text-2xl font-bold">Customers</div>
                  <div className="text-sm text-muted-foreground">View customer details</div>
                </div>
              </div>
              <Button
                variant="outline"
                size="sm"
                onClick={() => navigate('/customers')}
              >
                View All
              </Button>
            </div>
          </CardContent>
        </Card>

        {/* Invoices Card */}
        <Card>
          <CardHeader>
            <CardTitle>Invoices</CardTitle>
            <CardDescription>Manage invoices and payments</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <FileTextIcon className="h-8 w-8 text-primary mr-2" />
                <div>
                  <div className="text-2xl font-bold">Invoices</div>
                  <div className="text-sm text-muted-foreground">Track payments</div>
                </div>
              </div>
              <Button
                variant="outline"
                size="sm"
                onClick={() => navigate('/invoices')}
              >
                View All
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
