import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { Button } from "../components/ui/button";
import { useToast } from "../utils/use-toast";
import { ArrowLeft, Building2, Mail, Phone, MapPin } from "lucide-react";
import brain from "../brain";
import { CustomerHistory, CustomerHistoryItem } from "../types";
import { formatCurrency } from "../lib/utils";
import { formatFinancialDate } from "../utils/format";

export default function CustomerDetails() {
  const { customerId } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [loading, setLoading] = useState(true);
  const [customerHistory, setCustomerHistory] = useState<CustomerHistory | null>(null);

  useEffect(() => {
    loadCustomerHistory();
  }, [customerId]);

  const loadCustomerHistory = async () => {
    if (!customerId) return;
    
    try {
      setLoading(true);
      const data = await brain.get_customer_history({ customerId });
      setCustomerHistory(data);
    } catch (error) {
      console.error("Error loading customer history:", error);
      toast({
        title: "Error",
        description: "Failed to load customer history",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!customerHistory) {
    return <div>Customer not found</div>;
  }

  const { customer, history, summary } = customerHistory;

  const handleViewDetails = (item: CustomerHistoryItem) => {
    if (item.type === 'sale') {
      navigate(`/SaleDetails?id=${item.id}`);
    } else {
      navigate(`/InvoiceDetails?id=${item.id}`);
    }
  };

  return (
    <div className="container mx-auto py-6 space-y-6">
      <div className="flex items-center gap-4">
        <Button variant="ghost" onClick={() => navigate("/customers")}>
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Customers
        </Button>
        <h1 className="text-3xl font-bold">Customer Details</h1>
      </div>

      <div className="grid md:grid-cols-2 gap-6">
        {/* Customer Information */}
        <Card>
          <CardHeader>
            <CardTitle>Customer Information</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <h2 className="text-2xl font-semibold">{customer.name}</h2>
            {customer.company && (
              <div className="flex items-center gap-2 text-muted-foreground">
                <Building2 className="h-4 w-4" />
                {customer.company}
              </div>
            )}
            {customer.email && (
              <div className="flex items-center gap-2 text-muted-foreground">
                <Mail className="h-4 w-4" />
                {customer.email}
              </div>
            )}
            {customer.phone && (
              <div className="flex items-center gap-2 text-muted-foreground">
                <Phone className="h-4 w-4" />
                {customer.phone}
              </div>
            )}
            {customer.address && (
              <div className="flex items-center gap-2 text-muted-foreground">
                <MapPin className="h-4 w-4" />
                {customer.address}
              </div>
            )}
          </CardContent>
        </Card>

        {/* Summary Metrics */}
        <Card>
          <CardHeader>
            <CardTitle>Financial Summary</CardTitle>
          </CardHeader>
          <CardContent className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-muted-foreground">Total Sales</p>
              <p className="text-2xl font-bold">{formatCurrency(summary.total_sales)}</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">Total Invoiced</p>
              <p className="text-2xl font-bold">{formatCurrency(summary.total_invoices)}</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">Total Paid</p>
              <p className="text-2xl font-bold">{formatCurrency(summary.total_paid)}</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">Outstanding Balance</p>
              <p className="text-2xl font-bold">{formatCurrency(summary.total_invoices - summary.total_paid)}</p>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Purchase History */}
      <Card>
        <CardHeader>
          <CardTitle>Purchase History</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Date</TableHead>
                <TableHead>Type</TableHead>
                <TableHead>Amount</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Details</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {history.map((item: CustomerHistoryItem) => (
                <TableRow key={`${item.type}-${item.id}`}>
                  <TableCell>{formatFinancialDate(item.date)}</TableCell>
                  <TableCell className="capitalize">{item.type}</TableCell>
                  <TableCell>{formatCurrency(item.amount)}</TableCell>
                  <TableCell>
                    <div className="capitalize">{item.status.toLowerCase()}</div>
                    {item.type === "invoice" && item.amount_paid && item.amount_paid > 0 && (
                      <div className="text-sm text-muted-foreground">
                        Paid: {formatCurrency(item.amount_paid)}
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="ghost"
                      onClick={() => handleViewDetails(item)}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
}
