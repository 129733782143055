import { useDisplayPreferences } from "../../hooks/use-display-preferences";

export function DisplayPreferencesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { compactMode, largeText } = useDisplayPreferences();

  return (
    <div
      className={`
        ${compactMode ? "compact-mode" : ""}
        ${largeText ? "large-text" : ""}
      `}
      style={{
        "--compact-padding": compactMode ? "0.5rem" : "1rem",
        "--compact-margin": compactMode ? "0.5rem" : "1rem",
        "--text-base": largeText ? "1.125rem" : "1rem",
        "--text-sm": largeText ? "1rem" : "0.875rem",
      } as React.CSSProperties}
    >
      {children}
    </div>
  );
}
