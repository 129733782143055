import { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { useToast } from "../../utils/use-toast";
import brain from "../../brain";
import {
  InventoryItem,
  RestockHistoryItem,
  PriceChangeHistoryItem,
  RestockAnalytics,
  Supplier,
} from "../../types/inventory";
import {
  HistoryIcon,
  TrendingUpIcon,
  Loader2Icon,
  ArrowUpDownIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { formatDate } from "../../utils/format";

interface InventoryHistoryProps {
  item: InventoryItem;
}

type SortField = "date" | "quantity" | "purchase_cost" | "supplier" | "notes" | 
                 "old_price" | "new_price" | "change" | "reason";
type SortOrder = "asc" | "desc";

interface SortConfig {
  field: SortField;
  order: SortOrder;
}

export function InventoryHistory({ item }: InventoryHistoryProps) {
  const [open, setOpen] = useState(false);
  const [restockHistory, setRestockHistory] = useState<RestockHistoryItem[]>([]);
  const [priceHistory, setPriceHistory] = useState<PriceChangeHistoryItem[]>([]);
  const [analytics, setAnalytics] = useState<RestockAnalytics | null>(null);
  const [suppliers, setSuppliers] = useState<Record<string, Supplier>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [restockSort, setRestockSort] = useState<SortConfig>({ field: "date", order: "desc" });
  const [priceSort, setPriceSort] = useState<SortConfig>({ field: "date", order: "desc" });
  const [restockPage, setRestockPage] = useState(1);
  const [pricePage, setPricePage] = useState(1);
  const itemsPerPage = 10;
  const { toast } = useToast();

  const loadHistory = async () => {
    setIsLoading(true);
    try {
      const [restocks, prices, analyticsData, suppliersList] = await Promise.all([
        brain.get_restock_history({ inventory_id: item.id }),
        brain.get_price_history({ inventory_id: item.id }),
        brain.get_restock_analytics({ inventory_id: item.id }),
        brain.list_suppliers(),
      ]);
      setRestockHistory(restocks);
      setPriceHistory(prices);
      setAnalytics(analyticsData);
      const supplierMap = suppliersList.reduce((acc, supplier) => {
        acc[supplier.id] = supplier;
        return acc;
      }, {} as Record<string, Supplier>);
      setSuppliers(supplierMap);
    } catch (error: any) {
      console.error("Error loading history:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message || "Failed to load item history. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      loadHistory();
    }
  }, [open, item.id]);

  const getSortedRestockHistory = () => {
    return [...restockHistory].sort((a, b) => {
      switch (restockSort.field) {
        case "date":
          return restockSort.order === "asc"
            ? new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
            : new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        case "quantity":
          return restockSort.order === "asc"
            ? a.quantity - b.quantity
            : b.quantity - a.quantity;
        case "purchase_cost":
          return restockSort.order === "asc"
            ? a.purchase_cost - b.purchase_cost
            : b.purchase_cost - a.purchase_cost;
        case "supplier":
          const supplierA = a.supplier_id && suppliers[a.supplier_id] ? suppliers[a.supplier_id].name : "";
          const supplierB = b.supplier_id && suppliers[b.supplier_id] ? suppliers[b.supplier_id].name : "";
          return restockSort.order === "asc"
            ? supplierA.localeCompare(supplierB)
            : supplierB.localeCompare(supplierA);
        case "notes":
          const notesA = a.notes || "";
          const notesB = b.notes || "";
          return restockSort.order === "asc"
            ? notesA.localeCompare(notesB)
            : notesB.localeCompare(notesA);
        default:
          return 0;
      }
    });
  };

  const getSortedPriceHistory = () => {
    return [...priceHistory].sort((a, b) => {
      switch (priceSort.field) {
        case "date":
          return priceSort.order === "asc"
            ? new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
            : new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        case "old_price":
          return priceSort.order === "asc"
            ? a.old_price - b.old_price
            : b.old_price - a.old_price;
        case "new_price":
          return priceSort.order === "asc"
            ? a.new_price - b.new_price
            : b.new_price - a.new_price;
        case "change":
          const changeA = ((a.new_price - a.old_price) / a.old_price) * 100;
          const changeB = ((b.new_price - b.old_price) / b.old_price) * 100;
          return priceSort.order === "asc" ? changeA - changeB : changeB - changeA;
        case "reason":
          const reasonA = a.reason || "";
          const reasonB = b.reason || "";
          return priceSort.order === "asc"
            ? reasonA.localeCompare(reasonB)
            : reasonB.localeCompare(reasonA);
        default:
          return 0;
      }
    });
  };

  const getPaginatedRestockHistory = () => {
    const sorted = getSortedRestockHistory();
    const start = (restockPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return sorted.slice(start, end);
  };

  const getPaginatedPriceHistory = () => {
    const sorted = getSortedPriceHistory();
    const start = (pricePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return sorted.slice(start, end);
  };

  const totalRestockPages = Math.ceil(restockHistory.length / itemsPerPage);
  const totalPricePages = Math.ceil(priceHistory.length / itemsPerPage);

  const handleRestockSort = (field: SortField) => {
    setRestockSort((prev) => ({
      field,
      order: prev.field === field && prev.order === "asc" ? "desc" : "asc",
    }));
  };

  const handlePriceSort = (field: SortField) => {
    setPriceSort((prev) => ({
      field,
      order: prev.field === field && prev.order === "asc" ? "desc" : "asc",
    }));
  };

  const SortIcon = ({ field, sortConfig }: { field: SortField; sortConfig: SortConfig }) => {
    if (field !== sortConfig.field) {
      return <ArrowUpDownIcon className="h-4 w-4 ml-1" />;
    }
    return sortConfig.order === "asc" ? (
      <ChevronUpIcon className="h-4 w-4 ml-1" />
    ) : (
      <ChevronDownIcon className="h-4 w-4 ml-1" />
    );
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm">
          <HistoryIcon className="h-4 w-4 mr-2" />
          History
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle>History for {item.name}</DialogTitle>
        </DialogHeader>

        {isLoading ? (
          <div className="flex items-center justify-center py-8">
            <Loader2Icon className="h-8 w-8 animate-spin" />
          </div>
        ) : (
          <>
            {analytics && (
              <div className="grid grid-cols-2 gap-4 mb-6">
                <Card>
                  <CardHeader>
                    <CardTitle className="text-lg">Restock Analytics</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <dl className="space-y-2">
                      <div>
                        <dt className="text-sm text-muted-foreground">Total Restocks</dt>
                        <dd className="text-2xl font-bold">{analytics.total_restocks}</dd>
                      </div>
                      <div>
                        <dt className="text-sm text-muted-foreground">Total Cost</dt>
                        <dd className="text-2xl font-bold">₦{analytics.total_cost.toFixed(2)}</dd>
                      </div>
                      <div>
                        <dt className="text-sm text-muted-foreground">Average Cost</dt>
                        <dd className="text-2xl font-bold">₦{analytics.average_cost.toFixed(2)}</dd>
                      </div>
                      <div>
                        <dt className="text-sm text-muted-foreground">Restock Frequency</dt>
                        <dd className="text-2xl font-bold">
                          {analytics.restock_frequency.toFixed(1)} days
                        </dd>
                      </div>
                    </dl>
                  </CardContent>
                </Card>

                <Card>
                  <CardHeader>
                    <CardTitle className="text-lg">Price Trends</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="h-[200px] flex items-center justify-center">
                      <TrendingUpIcon className="h-12 w-12 text-muted-foreground" />
                    </div>
                  </CardContent>
                </Card>
              </div>
            )}

            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-medium mb-3">Restock History</h3>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead onClick={() => handleRestockSort("date")} className="cursor-pointer">
                        Date
                        <SortIcon field="date" sortConfig={restockSort} />
                      </TableHead>
                      <TableHead
                        onClick={() => handleRestockSort("quantity")}
                        className="cursor-pointer"
                      >
                        Quantity
                        <SortIcon field="quantity" sortConfig={restockSort} />
                      </TableHead>
                      <TableHead
                        onClick={() => handleRestockSort("purchase_cost")}
                        className="cursor-pointer"
                      >
                        Purchase Cost
                        <SortIcon field="purchase_cost" sortConfig={restockSort} />
                      </TableHead>
                      <TableHead
                        onClick={() => handleRestockSort("supplier")}
                        className="cursor-pointer"
                      >
                        Supplier
                        <SortIcon field="supplier" sortConfig={restockSort} />
                      </TableHead>
                      <TableHead
                        onClick={() => handleRestockSort("notes")}
                        className="cursor-pointer"
                      >
                        Notes
                        <SortIcon field="notes" sortConfig={restockSort} />
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {getPaginatedRestockHistory().map((restock) => (
                      <TableRow key={restock.id}>
                        <TableCell>{formatDate(restock.created_at)}</TableCell>
                        <TableCell>{restock.quantity}</TableCell>
                        <TableCell>₦{restock.purchase_cost.toFixed(2)}</TableCell>
                        <TableCell>
                          {restock.supplier_id && suppliers[restock.supplier_id] ? suppliers[restock.supplier_id].name : "-"}
                        </TableCell>
                        <TableCell>{restock.notes || "-"}</TableCell>
                      </TableRow>
                    ))}
                    {!isLoading && restockHistory.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={5} className="text-center">
                          No restock history found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {restockHistory.length > 0 && (
                  <div className="flex justify-between items-center mt-4">
                    <div className="text-sm text-muted-foreground">
                      Showing {((restockPage - 1) * itemsPerPage) + 1} to{" "}
                      {Math.min(restockPage * itemsPerPage, restockHistory.length)} of{" "}
                      {restockHistory.length} entries
                    </div>
                    <div className="flex gap-2">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => setRestockPage(p => Math.max(1, p - 1))}
                        disabled={restockPage === 1}
                      >
                        Previous
                      </Button>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => setRestockPage(p => Math.min(totalRestockPages, p + 1))}
                        disabled={restockPage === totalRestockPages}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <h3 className="text-lg font-medium mb-3">Price History</h3>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead onClick={() => handlePriceSort("date")} className="cursor-pointer">
                        Date
                        <SortIcon field="date" sortConfig={priceSort} />
                      </TableHead>
                      <TableHead
                        onClick={() => handlePriceSort("old_price")}
                        className="cursor-pointer"
                      >
                        Old Price
                        <SortIcon field="old_price" sortConfig={priceSort} />
                      </TableHead>
                      <TableHead
                        onClick={() => handlePriceSort("new_price")}
                        className="cursor-pointer"
                      >
                        New Price
                        <SortIcon field="new_price" sortConfig={priceSort} />
                      </TableHead>
                      <TableHead
                        onClick={() => handlePriceSort("change")}
                        className="cursor-pointer"
                      >
                        Change
                        <SortIcon field="change" sortConfig={priceSort} />
                      </TableHead>
                      <TableHead
                        onClick={() => handlePriceSort("reason")}
                        className="cursor-pointer"
                      >
                        Reason
                        <SortIcon field="reason" sortConfig={priceSort} />
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {getPaginatedPriceHistory().map((price) => (
                      <TableRow key={price.id}>
                        <TableCell>{formatDate(price.created_at)}</TableCell>
                        <TableCell>₦{price.old_price.toFixed(2)}</TableCell>
                        <TableCell>₦{price.new_price.toFixed(2)}</TableCell>
                        <TableCell
                          className={
                            price.new_price > price.old_price ? "text-green-600" : "text-red-600"
                          }
                        >
                          {((price.new_price - price.old_price) / price.old_price * 100).toFixed(1)}%
                        </TableCell>
                        <TableCell>{price.reason || "-"}</TableCell>
                      </TableRow>
                    ))}
                    {!isLoading && priceHistory.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={5} className="text-center">
                          No price history found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {priceHistory.length > 0 && (
                  <div className="flex justify-between items-center mt-4">
                    <div className="text-sm text-muted-foreground">
                      Showing {((pricePage - 1) * itemsPerPage) + 1} to{" "}
                      {Math.min(pricePage * itemsPerPage, priceHistory.length)} of{" "}
                      {priceHistory.length} entries
                    </div>
                    <div className="flex gap-2">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => setPricePage(p => Math.max(1, p - 1))}
                        disabled={pricePage === 1}
                      >
                        Previous
                      </Button>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => setPricePage(p => Math.min(totalPricePages, p + 1))}
                        disabled={pricePage === totalPricePages}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
