import { Button, Switch } from "../components/ui";
import { useTheme } from "../hooks/use-theme";
import { useDisplayPreferences } from "../hooks/use-display-preferences";
import { MoonIcon, SunIcon, MonitorIcon } from "lucide-react";
import { CompanySettings } from "../components/settings/CompanySettings";

export default function Settings() {
  const { theme, setTheme } = useTheme();
  const {
    compactMode,
    largeText,
    lowStockAlerts,
    paymentReminders,
    setCompactMode,
    setLargeText,
    setLowStockAlerts,
    setPaymentReminders,
  } = useDisplayPreferences();

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-2xl font-bold tracking-tight">Settings</h2>
        <p className="text-muted-foreground">
          Manage your application preferences and settings.
        </p>
      </div>

      <div className="space-y-4">
        {/* Company Settings Section */}
        <div className="border rounded-lg p-4">
          <h3 className="text-lg font-medium mb-4">Company Information</h3>
          <CompanySettings />
        </div>

        <div className="border rounded-lg p-4">
          <h3 className="text-lg font-medium mb-4">Appearance</h3>
          <div className="space-y-4">
            <div>
              <label className="text-sm font-medium mb-2 block">Theme</label>
              <div className="flex flex-wrap gap-4">
                <Button
                  variant={theme === "light" ? "default" : "outline"}
                  className="flex items-center gap-2"
                  onClick={() => setTheme("light")}
                >
                  <SunIcon className="h-4 w-4" />
                  Light
                </Button>
                <Button
                  variant={theme === "dark" ? "default" : "outline"}
                  className="flex items-center gap-2"
                  onClick={() => setTheme("dark")}
                >
                  <MoonIcon className="h-4 w-4" />
                  Dark
                </Button>
                <Button
                  variant={theme === "system" ? "default" : "outline"}
                  className="flex items-center gap-2"
                  onClick={() => setTheme("system")}
                >
                  <MonitorIcon className="h-4 w-4" />
                  System
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="border rounded-lg p-4">
          <h3 className="text-lg font-medium mb-4">Display Settings</h3>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <label className="text-sm font-medium block">Compact Mode</label>
                <span className="text-sm text-muted-foreground">
                  Reduce padding and margin in tables and cards
                </span>
              </div>
              <Switch
                checked={compactMode}
                onCheckedChange={setCompactMode}
                aria-label="Toggle compact mode"
              />
            </div>
            <div className="flex items-center justify-between">
              <div>
                <label className="text-sm font-medium block">Large Text</label>
                <span className="text-sm text-muted-foreground">
                  Increase text size throughout the application
                </span>
              </div>
              <Switch
                checked={largeText}
                onCheckedChange={setLargeText}
                aria-label="Toggle large text"
              />
            </div>
          </div>
        </div>

        <div className="border rounded-lg p-4">
          <h3 className="text-lg font-medium mb-4">Notifications</h3>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <label className="text-sm font-medium block">Low Stock Alerts</label>
                <span className="text-sm text-muted-foreground">
                  Receive notifications when inventory items are running low
                </span>
              </div>
              <Switch
                checked={lowStockAlerts}
                onCheckedChange={setLowStockAlerts}
                aria-label="Toggle low stock alerts"
              />
            </div>
            <div className="flex items-center justify-between">
              <div>
                <label className="text-sm font-medium block">Payment Reminders</label>
                <span className="text-sm text-muted-foreground">
                  Get notified about upcoming and overdue payments
                </span>
              </div>
              <Switch
                checked={paymentReminders}
                onCheckedChange={setPaymentReminders}
                aria-label="Toggle payment reminders"
              />
            </div>
          </div>
        </div>

        <div className="border rounded-lg p-4">
          <h3 className="text-lg font-medium mb-4">System Information</h3>
          <div className="space-y-2 text-sm">
            <div className="flex justify-between">
              <span className="text-muted-foreground">Version</span>
              <span>1.0.0</span>
            </div>
            <div className="flex justify-between">
              <span className="text-muted-foreground">Last Updated</span>
              <span>{new Date().toLocaleDateString()}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
