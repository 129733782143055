import { useState, useEffect } from 'react';
import { Customer } from '../../types';
import logger from '../../utils/logger';
import brain from '../../brain';

export function useCustomers() {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const data = await brain.list_customers();
      setCustomers(data || []);
    } catch (error) {
      logger.error('Error fetching customers:', error);
      setError(error instanceof Error ? error : new Error('Failed to fetch customers'));
      setCustomers([]); // Reset customers on error
    } finally {
      setIsLoading(false);
    }
  };

  return { customers, isLoading, error, refetch: fetchCustomers };
}
