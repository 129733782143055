// Define logger interface
interface Logger {
  debug: (message: string, ...args: any[]) => void;
  info: (message: string, ...args: any[]) => void;
  warn: (message: string, ...args: any[]) => void;
  error: (message: string, ...args: any[]) => void;
  logAPIRequest: (method: string, endpoint: string, data?: any) => void;
  logAPIResponse: (method: string, endpoint: string, status: number, data?: any) => void;
  logAPIError: (method: string, endpoint: string, error: any, context?: any) => void;
  logOperationStart: (operation: string, context?: any) => void;
  logOperationSuccess: (operation: string, result?: any) => void;
  logOperationError: (operation: string, error: any, context?: any) => void;
  logUserAction: (action: string, userId: string, details?: any) => void;
  logStateChange: (component: string, prevState: any, newState: any) => void;
  logPerformance: (operation: string, duration: number, context?: any) => void;
}

// Create a browser-compatible logger
const logger: Logger = {
  debug: (message: string, ...args: any[]) => {
    console.debug(message, ...args);
  },

  info: (message: string, ...args: any[]) => {
    console.info(message, ...args);
  },

  warn: (message: string, ...args: any[]) => {
    console.warn(message, ...args);
  },

  error: (message: string, ...args: any[]) => {
    console.error(message, ...args);
  },

  logAPIRequest: (method: string, endpoint: string, data?: any) => {
    console.info(`API Request: ${method} ${endpoint}`, {
      type: 'api_request',
      method,
      endpoint,
      data
    });
  },

  logAPIResponse: (method: string, endpoint: string, status: number, data?: any) => {
    console.info(`API Response: ${method} ${endpoint} - ${status}`, {
      type: 'api_response',
      method,
      endpoint,
      status,
      data
    });
  },

  logAPIError: (method: string, endpoint: string, error: any, context?: any) => {
    console.error(`API Error: ${method} ${endpoint}`, {
      type: 'api_error',
      method,
      endpoint,
      error: error instanceof Error ? { 
        message: error.message, 
        stack: error.stack 
      } : error,
      context
    });
  },

  logOperationStart: (operation: string, context?: any) => {
    console.info(`Starting operation: ${operation}`, {
      type: 'operation_start',
      operation,
      context
    });
  },

  logOperationSuccess: (operation: string, result?: any) => {
    console.info(`Operation successful: ${operation}`, {
      type: 'operation_success',
      operation,
      result
    });
  },

  logOperationError: (operation: string, error: any, context?: any) => {
    console.error(`Operation failed: ${operation}`, {
      type: 'operation_error',
      operation,
      error: error instanceof Error ? { 
        message: error.message, 
        stack: error.stack 
      } : error,
      context
    });
  },

  logUserAction: (action: string, userId: string, details?: any) => {
    console.info(`User action: ${action}`, {
      type: 'user_action',
      action,
      userId,
      details
    });
  },

  logStateChange: (component: string, prevState: any, newState: any) => {
    console.debug(`State change in ${component}`, {
      type: 'state_change',
      component,
      prevState,
      newState
    });
  },

  logPerformance: (operation: string, duration: number, context?: any) => {
    console.info(`Performance: ${operation}`, {
      type: 'performance',
      operation,
      duration,
      context
    });
  }
};

export default logger;
