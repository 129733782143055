import { useState, useEffect } from 'react';
import { InventoryItem } from '../../types';
import logger from '../../utils/logger';
import brain from '../../brain';

export function useProducts() {
  const [products, setProducts] = useState<InventoryItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const data = await brain.list_inventory();
      setProducts(data || []);
    } catch (error) {
      logger.error('Error fetching products:', error);
      setError(error instanceof Error ? error : new Error('Failed to fetch products'));
      setProducts([]); // Reset products on error
    } finally {
      setIsLoading(false);
    }
  };

  return { products, isLoading, error, refetch: fetchProducts };
}
