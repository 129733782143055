import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { Customer } from "../../../types";
import { FormField, FormItem, FormLabel } from "../../ui/form";
import { UseFormReturn } from "react-hook-form";
import { FormSaleData } from "../../../hooks/sales/useSaleForm";

interface CustomerSelectProps {
  customers: Customer[];
  form: UseFormReturn<FormSaleData>;
}

export function CustomerSelect({ customers, form }: CustomerSelectProps) {
  return (
    <FormField
      control={form.control}
      name="customer_id"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Customer</FormLabel>
          <Select
            value={field.value}
            onValueChange={field.onChange}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select a customer" />
            </SelectTrigger>
            <SelectContent>
              {customers.map((customer) => (
                <SelectItem key={customer.id} value={customer.id}>
                  {customer.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </FormItem>
      )}
    />
  );
}
