export class HttpClient {
    private baseUrl: string;

    constructor(baseUrl: string = "") {
        this.baseUrl = baseUrl;
    }

    private getAuthToken(): string | null {
        return localStorage.getItem('auth_token');
    }

    private getHeaders(customHeaders: Record<string, string> = {}): Record<string, string> {
        const headers: Record<string, string> = {
            "Content-Type": "application/json",
            ...customHeaders,
        };

        const token = this.getAuthToken();
        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        return headers;
    }

    async request<T>(
        method: string,
        url: string,
        params: Record<string, any> = {},
        body: any = null,
        headers: Record<string, string> = {}
    ): Promise<Response & { json(): Promise<T> }> {
        const queryString = new URLSearchParams(params).toString();
        const fullUrl = `${this.baseUrl}${url}${queryString ? `?${queryString}` : ""}`;

        const options: RequestInit = {
            method,
            headers: this.getHeaders(headers),
            credentials: "include",
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        try {
            const response = await fetch(fullUrl, options);

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    localStorage.removeItem('auth_token');
                    window.location.href = '/login';
                }
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            return response as Response & { json(): Promise<T> };
        } catch (error) {
            console.error("API request failed:", error);
            throw error;
        }
    }
}
