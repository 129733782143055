import { useForm } from "react-hook-form";
import { PaymentMethod, SaleItem, PAYMENT_METHODS } from "../../types";
import logger from "../../utils/logger";

export interface FormSaleData {
  customer_id: string;
  inventory_item_id: string;
  quantity: number;
  width: number;
  length: number;
  unit_price: number;
  payment_method: PaymentMethod;
  notes: string;
  apply_tax: boolean;
  tax_rate: number;
  apply_discount: boolean;
  discount_type: "percentage" | "fixed";
  discount_value: number;
}

export function useSaleForm() {
  logger.logOperationStart('useSaleForm', 'Initializing sale form hook');
  
  const form = useForm<FormSaleData>({
    defaultValues: {
      customer_id: "",
      inventory_item_id: "",
      quantity: 0,
      width: 0,
      length: 0,
      unit_price: 0,
      payment_method: PAYMENT_METHODS.CASH,
      notes: "",
      apply_tax: false,
      tax_rate: 7.5, // Default tax rate
      apply_discount: false,
      discount_type: "percentage",
      discount_value: 0
    }
  });

  const resetItemFields = () => {
    logger.logOperationStart('resetItemFields', 'Resetting sale form item fields');
    form.setValue("inventory_item_id", "");
    form.setValue("quantity", 0);
    form.setValue("width", 0);
    form.setValue("length", 0);
    form.setValue("unit_price", 0);
    logger.logOperationSuccess('resetItemFields', 'Sale form item fields reset successfully');
  };

  const calculateTotalAmount = (
    items: SaleItem[], 
    applyTax: boolean, 
    taxRate: number, 
    applyDiscount: boolean, 
    discountType: "percentage" | "fixed", 
    discountValue: number
  ): number => {
    let subtotal = items.reduce((sum, item) => sum + item.total_price, 0);
    
    // Apply discount first
    if (applyDiscount && discountValue > 0) {
      if (discountType === "percentage") {
        subtotal = subtotal * (1 - discountValue / 100);
      } else {
        subtotal = Math.max(0, subtotal - discountValue);
      }
    }
    
    // Apply tax after discount
    if (applyTax && taxRate > 0) {
      subtotal = subtotal * (1 + taxRate / 100);
    }
    
    return subtotal;
  };

  const prepareSaleData = (
    selectedItems: SaleItem[],
  ) => {
    logger.logOperationStart('prepareSaleData', { selectedItems });
    const data = form.getValues();
    
    const totalAmount = calculateTotalAmount(
      selectedItems,
      data.apply_tax,
      data.tax_rate || 0,
      data.apply_discount,
      data.discount_type,
      data.discount_value || 0
    );

    return {
      customer_id: data.customer_id,
      items: selectedItems.map(item => ({
        inventory_item_id: item.inventory_item_id,
        name: item.name,
        type: item.type,
        quantity: 'quantity' in item ? item.quantity : undefined,
        width: 'width' in item ? item.width : undefined,
        length: 'length' in item ? item.length : undefined,
        area: 'area' in item ? item.area : undefined,
        unit_price: item.unit_price,
        total_price: item.total_price
      })),
      total_amount: totalAmount,
      payment_method: data.payment_method,
      notes: data.notes,
      tax_applied: data.apply_tax,
      tax_rate: data.apply_tax ? data.tax_rate : null,
      discount_applied: data.apply_discount,
      discount_type: data.apply_discount ? data.discount_type : null,
      discount_value: data.apply_discount ? data.discount_value : null,
    };
  };

  const validateForm = () => {
    const data = form.getValues();
    
    if (!data.customer_id) {
      return "Please select a customer";
    }
    
    if (!data.payment_method) {
      return "Please select a payment method";
    }

    if (data.apply_tax && (!data.tax_rate || data.tax_rate <= 0)) {
      return "Please enter a valid tax rate";
    }

    if (data.apply_discount) {
      if (!data.discount_type) {
        return "Please select a discount type";
      }
      if (!data.discount_value || data.discount_value <= 0) {
        return "Please enter a valid discount value";
      }
      if (data.discount_type === "percentage" && data.discount_value > 100) {
        return "Discount percentage cannot exceed 100%";
      }
    }

    return null;
  };

  return {
    form,
    resetItemFields,
    prepareSaleData,
    validateForm,
    calculateTotalAmount,
  };
}
