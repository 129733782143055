import React, { useEffect, useState } from 'react';
import { Plus, Loader2 } from 'lucide-react';
import { Button } from '../components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../components/ui/dialog';
import { useToast } from '../utils/use-toast';
import { CategoryTree, CategoryForm } from '../components/categories';
import { useCategories } from '../hooks/use-categories';
import type { Category, CategoryFormData } from '../types/category';
import styles from '../styles/categories.module.css';

const CategoriesPage = () => {
  const { toast } = useToast();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);

  const {
    categories,
    loading,
    error,
    fetchCategories,
    createCategory,
    updateCategory,
    deleteCategory,
    moveCategory,
    fetchNextPage,
    hasMore,
    initialLoad,
  } = useCategories({
    onError: (error) => {
      toast({
        title: 'Error',
        description: error.message,
        variant: 'destructive',
        duration: 5000,
      });
    },
  });

  useEffect(() => {
    let mounted = true;

    const load = async () => {
      if (!mounted) return;
      await fetchCategories();
    };

    if (initialLoad) {
      load();
    }

    return () => {
      mounted = false;
    };
  }, [fetchCategories, initialLoad]);

  const handleFormSubmit = async (data: CategoryFormData) => {
    try {
      const success = selectedCategory
        ? await updateCategory(selectedCategory.id, data)
        : await createCategory(data);

      if (success) {
        toast({
          title: selectedCategory ? 'Category Updated' : 'Category Created',
          description: `Successfully ${selectedCategory ? 'updated' : 'created'} category "${data.name}"`,
        });
        setIsFormOpen(false);
        setSelectedCategory(null);
      }
    } catch (err) {
      console.error('Form submission error:', err);
      toast({
        title: 'Error',
        description: 'Failed to save category. Please try again.',
        variant: 'destructive',
      });
    }
  };

  const handleEdit = (category: Category) => {
    setSelectedCategory(category);
    setIsFormOpen(true);
  };

  const handleDelete = async (categoryId: string, reassignTo?: string) => {
    try {
      const success = await deleteCategory(categoryId, { reassignTo });
      if (success) {
        toast({
          title: 'Category Deleted',
          description: 'Successfully deleted the category',
        });
      }
      return success;
    } catch (err) {
      console.error('Delete error:', err);
      toast({
        title: 'Error',
        description: 'Failed to delete category. Please try again.',
        variant: 'destructive',
      });
      return false;
    }
  };

  const handleMove = async (sourceId: string, destinationParentId: string | null, position: number) => {
    try {
      const success = await moveCategory(sourceId, destinationParentId, position);
      if (success) {
        toast({
          title: 'Category Moved',
          description: 'Successfully reordered categories',
        });
      }
      return success;
    } catch (err) {
      console.error('Move error:', err);
      toast({
        title: 'Error',
        description: 'Failed to move category. Please try again.',
        variant: 'destructive',
      });
      return false;
    }
  };

  const handleRetry = () => {
    fetchCategories();
  };

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-[400px] p-4">
        <div className="text-red-500 mb-4 text-center">
          <p className="font-semibold mb-2">Failed to load categories</p>
          <p className="text-sm text-red-400">{error.message}</p>
        </div>
        <Button onClick={handleRetry} variant="outline">
          <Loader2 className={`h-4 w-4 mr-2 ${loading ? 'animate-spin' : ''}`} />
          Retry
        </Button>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 space-y-4">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Categories</h1>
        <Button onClick={() => setIsFormOpen(true)} disabled={initialLoad}>
          <Plus className="h-4 w-4 mr-2" />
          Add Category
        </Button>
      </div>

      <div className="min-h-[400px] relative bg-background">
        {initialLoad ? (
          <div className={styles.loadingOverlay}>
            <Loader2 className="h-8 w-8 animate-spin" />
          </div>
        ) : (
          <>
            <div className={styles.treeContainer}>
              <CategoryTree
                categories={categories}
                onMove={handleMove}
                onEdit={handleEdit}
                onDelete={handleDelete}
                isLoading={loading}
                error={error}
              />
            </div>
            {hasMore && (
              <div className="flex justify-center mt-4">
                <Button 
                  onClick={fetchNextPage}
                  disabled={loading}
                  variant="outline"
                  className="min-w-[120px]"
                >
                  {loading ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin mr-2" />
                      Loading...
                    </>
                  ) : (
                    'Load More'
                  )}
                </Button>
              </div>
            )}
          </>
        )}
      </div>

      <Dialog 
        open={isFormOpen} 
        onOpenChange={(open) => {
          if (!initialLoad) {
            setIsFormOpen(open);
            if (!open) {
              setSelectedCategory(null);
            }
          }
        }}
      >
        <DialogContent 
          className={styles.dialogContent}
          onOpenAutoFocus={(e) => e.preventDefault()}
        >
          <DialogHeader>
            <DialogTitle>
              {selectedCategory ? 'Edit Category' : 'Create Category'}
            </DialogTitle>
          </DialogHeader>
          <div className={styles.dialogBody}>
            <CategoryForm
              category={selectedCategory || undefined}
              categories={categories}
              onSubmit={handleFormSubmit}
              onCancel={() => {
                setIsFormOpen(false);
                setSelectedCategory(null);
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CategoriesPage;
