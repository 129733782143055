import React from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Plus as PlusIcon, X as RemoveIcon } from 'lucide-react';
import type { SearchFilter, SearchField } from './SearchBar';

interface AdvancedSearchProps {
  filters: SearchFilter[];
  onFiltersChange: (filters: SearchFilter[]) => void;
  fields: SearchField[];
  className?: string;
}

export function AdvancedSearch({
  filters,
  onFiltersChange,
  fields,
  className = '',
}: AdvancedSearchProps) {
  // Add new filter
  const addFilter = () => {
    const newFilter: SearchFilter = {
      field: fields[0].id,
      operator: 'equals',
      value: '',
    };
    onFiltersChange([...filters, newFilter]);
  };

  // Remove filter
  const removeFilter = (index: number) => {
    const newFilters = filters.filter((_, i) => i !== index);
    onFiltersChange(newFilters);
  };

  // Update filter
  const updateFilter = (index: number, updates: Partial<SearchFilter>) => {
    const newFilters = filters.map((filter, i) => {
      if (i === index) {
        return { ...filter, ...updates };
      }
      return filter;
    });
    onFiltersChange(newFilters);
  };

  // Get available operators based on field type
  const getOperators = (fieldType: string) => {
    switch (fieldType) {
      case 'number':
      case 'date':
        return [
          { value: 'equals', label: 'Equals' },
          { value: 'greaterThan', label: 'Greater than' },
          { value: 'lessThan', label: 'Less than' },
        ];
      case 'select':
      case 'custom':
        return [{ value: 'equals', label: 'Equals' }];
      default:
        return [
          { value: 'equals', label: 'Equals' },
          { value: 'contains', label: 'Contains' },
        ];
    }
  };

  // Render value input based on field type
  const renderValueInput = (field: SearchField, filter: SearchFilter, index: number) => {
    if (field.type === 'custom' && field.component) {
      const CustomComponent = field.component;
      return (
        <CustomComponent
          value={filter.value}
          onChange={(value) => updateFilter(index, { value })}
        />
      );
    }

    if (field.type === 'select') {
      return (
        <Select
          value={filter.value.toString()}
          onValueChange={(value) => updateFilter(index, { value })}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select value" />
          </SelectTrigger>
          <SelectContent>
            {field.options?.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      );
    }

    return (
      <Input
        type={field.type}
        value={filter.value}
        onChange={(e) => updateFilter(index, { value: e.target.value })}
        placeholder="Enter value"
      />
    );
  };

  return (
    <div className={`space-y-4 ${className}`}>
      {filters.map((filter, index) => {
        const field = fields.find((f) => f.id === filter.field);
        const operators = field ? getOperators(field.type) : [];

        return (
          <div
            key={index}
            className="flex flex-col gap-2 md:flex-row md:items-center md:gap-4"
          >
            {/* Field Select */}
            <Select
              value={filter.field}
              onValueChange={(value) => updateFilter(index, { field: value })}
            >
              <SelectTrigger className="w-full md:w-[200px]">
                <SelectValue placeholder="Select field" />
              </SelectTrigger>
              <SelectContent>
                {fields.map((field) => (
                  <SelectItem key={field.id} value={field.id}>
                    {field.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            {/* Operator Select */}
            <Select
              value={filter.operator}
              onValueChange={(value) => 
                updateFilter(index, { 
                  operator: value as SearchFilter['operator']
                })
              }
            >
              <SelectTrigger className="w-full md:w-[150px]">
                <SelectValue placeholder="Select operator" />
              </SelectTrigger>
              <SelectContent>
                {operators.map((op) => (
                  <SelectItem key={op.value} value={op.value}>
                    {op.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            {/* Value Input */}
            <div className="flex-1">
              {field && renderValueInput(field, filter, index)}
            </div>

            {/* Remove Filter Button */}
            <Button
              variant="ghost"
              size="icon"
              onClick={() => removeFilter(index)}
              className="h-10 w-10"
            >
              <RemoveIcon className="h-4 w-4" />
            </Button>
          </div>
        );
      })}

      {/* Add Filter Button */}
      <Button
        variant="outline"
        size="sm"
        onClick={addFilter}
        className="w-full md:w-auto"
      >
        <PlusIcon className="mr-2 h-4 w-4" />
        Add Filter
      </Button>
    </div>
  );
}
