import { createBrowserRouter } from 'react-router-dom';
import { RootLayout } from '../components/layout/RootLayout';
import Dashboard from '../pages/Dashboard';
import Inventory from '../pages/Inventory';
import Sales from '../pages/Sales';
import SaleDetails from '../pages/SaleDetails';
import Invoices from '../pages/Invoices';
import InvoiceDetails from '../pages/InvoiceDetails';
import InvoiceTemplates from '../pages/InvoiceTemplates';
import Customers from '../pages/Customers';
import CustomerDetails from '../pages/CustomerDetails';
import Categories from '../pages/Categories';
import Settings from '../pages/Settings';
import Profile from '../pages/Profile';
import Login from '../pages/Login';
import RequestReset from '../pages/RequestReset';
import ResetConfirm from '../pages/ResetConfirm';
import Users from '../pages/Users';
import Expenses from '../pages/Expenses';
import Suppliers from '../pages/Suppliers';
import Reports from '../pages/Reports';
import { ProtectedRoute } from '../components/auth/ProtectedRoute';
import { AuthProvider } from '../context/AuthContext';

// Root component that provides auth context
const AuthRoot = ({ children }: { children: React.ReactNode }) => {
  return <AuthProvider>{children}</AuthProvider>;
};

export const router = createBrowserRouter([
  {
    element: <AuthRoot><RootLayout /></AuthRoot>,
    children: [
      {
        path: '/',
        element: <ProtectedRoute><Dashboard /></ProtectedRoute>,
      },
      {
        path: '/inventory',
        element: <ProtectedRoute><Inventory /></ProtectedRoute>,
      },
      {
        path: '/sales',
        element: <ProtectedRoute><Sales /></ProtectedRoute>,
      },
      {
        path: '/sales/:id',
        element: <ProtectedRoute><SaleDetails /></ProtectedRoute>,
      },
      {
        path: '/invoices',
        element: <ProtectedRoute><Invoices /></ProtectedRoute>,
      },
      {
        path: '/invoices/:id',
        element: <ProtectedRoute><InvoiceDetails /></ProtectedRoute>,
      },
      {
        path: '/invoice-templates',
        element: <ProtectedRoute><InvoiceTemplates /></ProtectedRoute>,
      },
      {
        path: '/customers',
        element: <ProtectedRoute><Customers /></ProtectedRoute>,
      },
      {
        path: '/customers/:id',
        element: <ProtectedRoute><CustomerDetails /></ProtectedRoute>,
      },
      {
        path: '/categories',
        element: <ProtectedRoute><Categories /></ProtectedRoute>,
      },
      {
        path: '/settings',
        element: <ProtectedRoute><Settings /></ProtectedRoute>,
      },
      {
        path: '/profile',
        element: <ProtectedRoute><Profile /></ProtectedRoute>,
      },
      {
        path: '/users',
        element: <ProtectedRoute><Users /></ProtectedRoute>,
      },
      {
        path: '/expenses',
        element: <ProtectedRoute><Expenses /></ProtectedRoute>,
      },
      {
        path: '/suppliers',
        element: <ProtectedRoute><Suppliers /></ProtectedRoute>,
      },
      {
        path: '/reports',
        element: <ProtectedRoute><Reports /></ProtectedRoute>,
      },
    ],
  },
  {
    path: '/login',
    element: <AuthRoot><Login /></AuthRoot>,
  },
  {
    path: '/request-reset',
    element: <AuthRoot><RequestReset /></AuthRoot>,
  },
  {
    path: '/reset-confirm',
    element: <AuthRoot><ResetConfirm /></AuthRoot>,
  },
]);
