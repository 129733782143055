import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { Textarea } from '../ui/textarea';
import { Switch } from '../ui/switch';
import { Card } from '../ui/card';
import { useToast } from '../../utils/use-toast';

const templateSchema = z.object({
  name: z.string().min(1, 'Template name is required'),
  description: z.string().optional(),
  template_data: z.object({
    header: z.object({
      logo: z.boolean(),
      company_info: z.boolean(),
      title: z.string(),
    }),
    content: z.object({
      customer_info: z.boolean(),
      invoice_details: z.boolean(),
      items_table: z.object({
        show_item_code: z.boolean(),
        show_description: z.boolean(),
        show_unit_price: z.boolean(),
        show_quantity: z.boolean(),
        show_total: z.boolean(),
      }),
      subtotal_section: z.boolean(),
      tax_section: z.boolean(),
      discount_section: z.boolean(),
      total_section: z.boolean(),
    }),
    footer: z.object({
      notes: z.boolean(),
      terms: z.boolean(),
      signature: z.boolean(),
      payment_info: z.boolean(),
    }),
    styling: z.object({
      primary_color: z.string(),
      secondary_color: z.string(),
      font_family: z.string(),
      font_size: z.string(),
    }),
  }),
  is_default: z.boolean().default(false),
});

type TemplateFormData = z.infer<typeof templateSchema>;

interface InvoiceTemplateFormProps {
  initialData?: TemplateFormData;
  onSubmit: (data: TemplateFormData) => Promise<void>;
  isEditing?: boolean;
}

export function InvoiceTemplateForm({
  initialData,
  onSubmit,
  isEditing = false,
}: InvoiceTemplateFormProps) {
  const { toast } = useToast();
  const form = useForm<TemplateFormData>({
    resolver: zodResolver(templateSchema),
    defaultValues: initialData || {
      name: '',
      description: '',
      template_data: {
        header: {
          logo: true,
          company_info: true,
          title: 'INVOICE',
        },
        content: {
          customer_info: true,
          invoice_details: true,
          items_table: {
            show_item_code: true,
            show_description: true,
            show_unit_price: true,
            show_quantity: true,
            show_total: true,
          },
          subtotal_section: true,
          tax_section: true,
          discount_section: true,
          total_section: true,
        },
        footer: {
          notes: true,
          terms: true,
          signature: true,
          payment_info: true,
        },
        styling: {
          primary_color: '#000000',
          secondary_color: '#666666',
          font_family: 'Arial, sans-serif',
          font_size: '12px',
        },
      },
      is_default: false,
    },
  });

  const handleSubmit = async (data: TemplateFormData) => {
    try {
      await onSubmit(data);
      toast({
        title: `Template ${isEditing ? 'updated' : 'created'} successfully`,
        variant: 'default',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: `Failed to ${isEditing ? 'update' : 'create'} template`,
        variant: 'destructive',
      });
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
        <Card className="p-6">
          <div className="space-y-4">
            {/* Basic Information */}
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Template Name</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Enter template name" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      placeholder="Enter template description"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Header Settings */}
            <div className="space-y-2">
              <h3 className="text-lg font-medium">Header Settings</h3>
              <FormField
                control={form.control}
                name="template_data.header.logo"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between">
                    <FormLabel>Show Logo</FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              {/* Add other header fields similarly */}
            </div>

            {/* Content Settings */}
            <div className="space-y-2">
              <h3 className="text-lg font-medium">Content Settings</h3>
              <FormField
                control={form.control}
                name="template_data.content.customer_info"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between">
                    <FormLabel>Show Customer Information</FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              {/* Add other content fields similarly */}
            </div>

            {/* Styling Settings */}
            <div className="space-y-2">
              <h3 className="text-lg font-medium">Styling</h3>
              <FormField
                control={form.control}
                name="template_data.styling.primary_color"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Primary Color</FormLabel>
                    <FormControl>
                      <Input type="color" {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
              {/* Add other styling fields similarly */}
            </div>

            {/* Default Template Setting */}
            <FormField
              control={form.control}
              name="is_default"
              render={({ field }) => (
                <FormItem className="flex items-center justify-between">
                  <FormLabel>Set as Default Template</FormLabel>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>

          <div className="mt-6 flex justify-end space-x-4">
            <Button type="submit" className="w-32">
              {isEditing ? 'Update' : 'Create'}
            </Button>
          </div>
        </Card>
      </form>
    </Form>
  );
}
