import { useEffect, useState } from "react";
import { SaleOutput, Invoice } from "../../types";
import brain from "../../brain";
import logger from "../../utils/logger";

// Extended interface for sales with customer names
interface ExtendedSaleOutput extends SaleOutput {
  customer_name: string;
}

export function useSaleData() {
  const [sales, setSales] = useState<ExtendedSaleOutput[]>([]);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setError(null);

      // Fetch sales data
      const salesData = await brain.list_sales();

      // Fetch customers for each sale
      const customers = await Promise.all(
        salesData.map(sale => brain.get_customer({ customerId: sale.customer_id }))
      );

      // Combine sales data with customer names
      const salesWithCustomers: ExtendedSaleOutput[] = salesData.map((sale, index) => ({
        ...sale,
        customer_name: customers[index]?.name || 'Unknown Customer'
      }));

      setSales(salesWithCustomers);

      // Generate invoices from approved sales
      const generatedInvoices: Invoice[] = salesWithCustomers
        .filter((sale) => sale.status === "approved")
        .map((sale) => ({
          id: `INV-${sale.id}`,
          invoice_number: `INV-${sale.id}`,
          sale_id: sale.id,
          customer_id: sale.customer_id,
          customer_name: sale.customer_name,
          items: sale.items,
          subtotal: sale.total_amount,
          total_amount: sale.total_amount,
          amount_paid: 0, // Default to 0 for new invoices
          status: "PENDING_PAYMENT",
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        }));

      setInvoices(generatedInvoices);
    } catch (err) {
      logger.error("Error fetching sales data:", err);
      setError(err instanceof Error ? err : new Error("Failed to fetch sales data"));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    sales,
    invoices,
    isLoading,
    error,
    refetch: fetchData,
  };
}
