// @ts-nocheck
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowUpRight, FileTextIcon, PrinterIcon } from "lucide-react";

import { Badge } from "../components/ui/badge";
import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../components/ui/alert-dialog";
import { useToast } from "../utils/use-toast";
import brain from "../brain";
import { Customer, InventoryItem, SaleOutput } from "../types";

export default function SaleDetails() {
  const { id: saleId } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();

  const [sale, setSale] = useState<SaleOutput | null>(null);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([]);
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [relatedInvoice, setRelatedInvoice] = useState<any>(null);

  useEffect(() => {
    const loadInvoice = async (saleId: string) => {
      try {
        const invoices = await brain.list_invoices();
        const invoice = invoices.find((inv: any) => inv.sale_id === saleId);
        setRelatedInvoice(invoice);
      } catch (error) {
        console.error("Error loading invoice:", error);
      }
    };

    const loadSale = async () => {
      try {
        const data = await brain.get_sale({ saleId: saleId! });
        setSale(data);

        // Load customer details
        const customerData = await brain.get_customer({
          customerId: data.customer_id,
        });
        setCustomer(customerData);
      } catch (error) {
        console.error("Error loading sale:", error);
        toast({
          variant: "destructive",
          title: "Error",
          description: "Failed to load sale details",
        });
      }
    };

    const loadInventory = async () => {
      try {
        const data = await brain.list_inventory();
        setInventoryItems(data);
      } catch (error) {
        console.error("Error loading inventory:", error);
      }
    };

    if (saleId) {
      loadSale();
      loadInvoice(saleId);
      loadInventory();
    }
  }, [saleId, toast]);

  const handleDelete = async () => {
    if (!sale) return;

    try {
      // First check if the sale can be deleted
      if (sale.status !== "pending") {
        throw new Error("Only pending sales can be deleted");
      }

      await brain.delete_sale({ saleId: sale.id });
      
      toast({
        title: "Success",
        description: "Sale deleted successfully",
      });
      navigate("/sales");
    } catch (error: any) {
      console.error("Error deleting sale:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message || "Failed to delete sale",
      });
    }
  };

  const handleApproveSale = async () => {
    if (!sale) return;

    try {
      await brain.approve_sale({ saleId: sale.id });
      toast({
        title: "Success",
        description: "Sale approved successfully",
      });
      // Reload sale details
      const data = await brain.get_sale({ saleId: sale.id });
      setSale(data);
      // Reload invoice
      const invoices = await brain.list_invoices();
      const invoice = invoices.find((inv: any) => inv.sale_id === sale.id);
      setRelatedInvoice(invoice);
    } catch (error) {
      console.error("Error approving sale:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to approve sale",
      });
    }
  };

  const handleCancelSale = async () => {
    if (!sale) return;

    try {
      await brain.cancel_sale({ saleId: sale.id });
      toast({
        title: "Success",
        description: "Sale cancelled successfully",
      });
      // Reload sale details
      const data = await brain.get_sale({ saleId: sale.id });
      setSale(data);
    } catch (error) {
      console.error("Error cancelling sale:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to cancel sale",
      });
    }
  };

  if (!sale || !customer) {
    return (
      <div className="p-8">
        <Button
          variant="outline"
          size="sm"
          onClick={() => navigate("/sales")}
          className="mb-4"
        >
          ← Back to Sales
        </Button>
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <div className="p-8">
      <Button
        variant="outline"
        size="sm"
        onClick={() => navigate("/sales")}
        className="mb-4"
      >
        ← Back to Sales
      </Button>

      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center gap-3">
          <FileTextIcon className="h-8 w-8 text-primary" />
          <h1 className="text-3xl font-bold">Sale Details</h1>
        </div>
        <div className="flex gap-2">
          <Button variant="outline" onClick={() => window.print()}>
            <PrinterIcon className="h-4 w-4 mr-2" /> Print
          </Button>
        </div>
      </div>

      <div className="grid gap-4">
        <Card>
          <CardHeader>
            <CardTitle>Sale Details</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid gap-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h3 className="font-medium mb-2">Status</h3>
                  <Badge
                    variant={
                      sale.status === "approved"
                        ? "default"
                        : sale.status === "cancelled"
                          ? "destructive"
                          : "secondary"
                    }
                  >
                    {sale.status}
                  </Badge>
                </div>
                <div>
                  <h3 className="font-medium mb-2">Date</h3>
                  <p>{new Date(sale.created_at).toLocaleDateString()}</p>
                </div>
              </div>

              <div>
                <h3 className="font-medium mb-2">Customer Information</h3>
                <p>{customer.name}</p>
                {customer.company && <p>{customer.company}</p>}
                {customer.email && <p>{customer.email}</p>}
                {customer.phone && <p>{customer.phone}</p>}
                {customer.address && <p>{customer.address}</p>}
              </div>

              <div>
                <h3 className="font-medium mb-2">Items</h3>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Item</TableHead>
                      <TableHead>Details</TableHead>
                      <TableHead>Price</TableHead>
                      <TableHead>Total</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {sale.items.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>
                          {item.type === "QUANTITY" ? (
                            <span>{item.quantity} pcs</span>
                          ) : (
                            <span>
                              {item.width} × {item.length} ft ({item.area} sq ft)
                            </span>
                          )}
                        </TableCell>
                        <TableCell>
                          ₦
                          {item.type === "QUANTITY"
                            ? item.unit_price
                            : `${item.unit_price}/sq ft`}
                        </TableCell>
                        <TableCell>₦{item.total_price}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={3} className="text-right font-bold">
                        Total:
                      </TableCell>
                      <TableCell className="font-bold">
                        ₦{sale.total_amount}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>

              <div>
                <h3 className="font-medium mb-2">Notes</h3>
                {sale.notes ? (
                  <p>{sale.notes}</p>
                ) : (
                  <p className="text-muted-foreground">No notes</p>
                )}
              </div>

              {sale.status === "approved" && relatedInvoice && (
                <div className="flex items-center gap-2">
                  <h3 className="font-medium">Invoice:</h3>
                  <Button
                    variant="link"
                    className="px-0"
                    onClick={() => navigate(`/invoices/${relatedInvoice.id}`)}
                  >
                    View Invoice #{relatedInvoice.invoice_number}{" "}
                    <ArrowUpRight className="ml-1 h-4 w-4" />
                  </Button>
                </div>
              )}

              {sale.status === "pending" && (
                <div className="flex gap-2">
                  <Button
                    variant="destructive"
                    onClick={() => setShowDeleteDialog(true)}
                  >
                    Delete Sale
                  </Button>
                  <Button onClick={() => setShowApproveDialog(true)}>
                    Approve Sale
                  </Button>
                  <Button
                    variant="destructive"
                    onClick={() => setShowCancelDialog(true)}
                  >
                    Cancel Sale
                  </Button>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>

      <AlertDialog open={showApproveDialog} onOpenChange={setShowApproveDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Approve Sale</AlertDialogTitle>
            <AlertDialogDescription>
              This will approve the sale, create an invoice, and update inventory levels.
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleApproveSale}>
              Approve
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog open={showCancelDialog} onOpenChange={setShowCancelDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Cancel Sale</AlertDialogTitle>
            <AlertDialogDescription>
              This will cancel the sale. This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>No, keep it</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleCancelSale}
              className="bg-destructive text-destructive-foreground"
            >
              Yes, cancel it
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Sale</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete the sale. This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>No, keep it</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              className="bg-destructive text-destructive-foreground"
            >
              Yes, delete it
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
