import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { useCompanySettings } from '../../hooks/use-company-settings';
import { Card } from '../ui/card';
import { Loader2 } from 'lucide-react';

interface InvoiceTemplatePreviewProps {
  template: any;
  isOpen: boolean;
  onClose: () => void;
}

export function InvoiceTemplatePreview({
  template,
  isOpen,
  onClose
}: InvoiceTemplatePreviewProps) {
  const { settings, loading } = useCompanySettings();

  if (!template) return null;

  const renderSection = (section: any) => {
    switch (section.type) {
      case 'grid':
        return (
          <div
            className="grid"
            style={{
              gridTemplateColumns: `repeat(${section.columns}, 1fr)`,
              gap: '1rem'
            }}
          >
            {section.items.map((item: any, index: number) => (
              <div key={index}>{renderSection(item)}</div>
            ))}
          </div>
        );
      
      case 'billing_info':
        return (
          <div className="space-y-2">
            <h3 className="font-medium">{section.title}</h3>
            <div className="text-sm space-y-1">
              <p>Sample Customer</p>
              <p>Sample Company</p>
              <p>123 Business Street</p>
              <p>customer@example.com</p>
              <p>+1 234 567 8900</p>
            </div>
          </div>
        );
      
      case 'invoice_info':
        return (
          <div className="space-y-2">
            {section.fields.map((field: any, index: number) => (
              <div key={index} className="flex justify-between text-sm">
                <span className="text-gray-600">{field.label}:</span>
                <span className="font-medium">
                  {field.value.replace('{{invoice_number}}', 'INV-202312-0001')
                    .replace('{{date}}', new Date().toLocaleDateString())
                    .replace('{{due_date}}', new Date(Date.now() + 30*24*60*60*1000).toLocaleDateString())
                    .replace('{{payment_terms}}', 'Net 30')}
                </span>
              </div>
            ))}
          </div>
        );
      
      case 'items_table':
        return (
          <div className="mt-6">
            <table className="w-full">
              <thead>
                <tr>
                  {section.columns.map((col: any, index: number) => (
                    <th
                      key={index}
                      className="text-left py-2 px-4 bg-gray-50 text-sm font-medium"
                      style={{ width: col.width }}
                    >
                      {col.header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr className="border-t">
                  <td className="py-2 px-4">Sample Item</td>
                  <td className="py-2 px-4">2</td>
                  <td className="py-2 px-4">$100.00</td>
                  <td className="py-2 px-4">$200.00</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      
      case 'summary':
        return (
          <div className={`mt-6 space-y-2 ${section.align === 'right' ? 'text-right' : ''}`}>
            {section.items.map((item: any, index: number) => (
              <div key={index} className="flex justify-end gap-4">
                <span className="text-gray-600">{item.label}:</span>
                <span className={item.style === 'bold' ? 'font-bold' : ''}>
                  {item.value.replace('{{subtotal}}', '$200.00')
                    .replace('{{total}}', '$200.00')}
                </span>
              </div>
            ))}
          </div>
        );
      
      case 'notes':
      case 'payment_info':
        return (
          <div className="mt-4">
            <h4 className="font-medium mb-2">{section.title}</h4>
            <p className="text-sm text-gray-600">
              {section.content.replace('{{notes}}', 'Thank you for your business!')
                .replace('{{payment_info}}', 'Please make payment to: Bank Account 1234-5678-9012')}
            </p>
          </div>
        );
      
      case 'signature':
        if (!section.show) return null;
        return (
          <div className="mt-8">
            <h4 className="font-medium mb-4">{section.title}</h4>
            <div className="border-t w-48 pt-2">
              <p className="text-sm text-gray-600">Date & Signature</p>
            </div>
          </div>
        );
      
      default:
        return null;
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle>Template Preview: {template.name}</DialogTitle>
        </DialogHeader>

        {loading ? (
          <div className="flex items-center justify-center p-8">
            <Loader2 className="h-8 w-8 animate-spin" />
          </div>
        ) : (
          <Card className="p-8">
            {/* Header */}
            <div className="flex justify-between items-start mb-8">
              {template.template_data.layout.header.show_logo && settings?.logo_url && (
                <div className="w-32">
                  <img
                    src={settings.logo_url}
                    alt="Company Logo"
                    className="max-w-full h-auto"
                  />
                </div>
              )}
              <div className="text-right">
                <h1 className="text-2xl font-bold mb-2">
                  {template.template_data.layout.header.title}
                </h1>
                <div className="text-sm">
                  <p className="font-medium">{settings?.company_name || 'Company Name'}</p>
                  {settings?.address && <p>{settings.address}</p>}
                  {settings?.phone && <p>{settings.phone}</p>}
                  {settings?.email && <p>{settings.email}</p>}
                </div>
              </div>
            </div>

            {/* Content */}
            <div className="space-y-6">
              {template.template_data.layout.content.sections.map((section: any, index: number) => (
                <div key={index}>{renderSection(section)}</div>
              ))}
            </div>

            {/* Footer */}
            <div className="mt-8 pt-4 border-t">
              {template.template_data.layout.footer.sections.map((section: any, index: number) => (
                <div key={index}>{renderSection(section)}</div>
              ))}
            </div>
          </Card>
        )}
      </DialogContent>
    </Dialog>
  );
}
