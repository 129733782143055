import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
} from '@mui/material';
import { SaleOutput } from '../../../types';

// Extended interface for sales with customer names
interface ExtendedSaleOutput extends SaleOutput {
  customer_name: string;
}

interface Props {
  sales: ExtendedSaleOutput[];
}

export function SaleList({ sales }: Props) {
  const navigate = useNavigate();

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'approved':
        return 'success';
      case 'cancelled':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell>Items</TableCell>
            <TableCell>Total Amount</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sales.map((sale) => (
            <TableRow
              key={sale.id}
              hover
              onClick={() => navigate(`/sales/${sale.id}`)}
              style={{ cursor: 'pointer' }}
            >
              <TableCell>{sale.customer_name}</TableCell>
              <TableCell>{sale.items.length} items</TableCell>
              <TableCell>₦{sale.total_amount.toFixed(2)}</TableCell>
              <TableCell>
                <Chip
                  label={sale.status}
                  color={getStatusColor(sale.status)}
                  size="small"
                />
              </TableCell>
              <TableCell>
                {new Date(sale.created_at).toLocaleDateString()}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
