import { useState, useEffect } from 'react';
import { useToast } from '../utils/use-toast';
import { HttpClient } from '../brain/http-client';

const httpClient = new HttpClient();

interface CompanySettings {
  id: string;
  company_name: string;
  address: string | null;
  phone: string | null;
  email: string | null;
  website: string | null;
  tax_id: string | null;
  bank_details: Array<{
    bank_name: string;
    account_name: string;
    account_number: string;
    sort_code?: string;
    swift_code?: string;
    iban?: string;
  }> | null;
  invoice_prefix: string;
  invoice_footer: string | null;
  logo_url: string | null;
}

export function useCompanySettings() {
  const [settings, setSettings] = useState<CompanySettings | null>(null);
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();

  const fetchSettings = async () => {
    try {
      const response = await httpClient.request<CompanySettings>('GET', '/api/company');
      const data = await response.json();
      setSettings(data);
    } catch (error) {
      console.error('Error fetching company settings:', error);
      toast({
        title: 'Error',
        description: 'Failed to load company settings',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  const updateSettings = async (data: Partial<CompanySettings>) => {
    try {
      const response = await httpClient.request<CompanySettings>('PUT', '/api/company', {}, data);
      const updatedData = await response.json();
      setSettings(updatedData);
      toast({
        title: 'Success',
        description: 'Company settings updated successfully',
      });
    } catch (error) {
      console.error('Error updating company settings:', error);
      toast({
        title: 'Error',
        description: 'Failed to update company settings',
        variant: 'destructive',
      });
      throw error;
    }
  };

  const uploadLogo = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch('/api/company/logo', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to upload logo');
      }

      const data = await response.json();
      setSettings(prev => prev ? { ...prev, logo_url: data.logo } : null);
      toast({
        title: 'Success',
        description: 'Company logo uploaded successfully',
      });
    } catch (error) {
      console.error('Error uploading logo:', error);
      toast({
        title: 'Error',
        description: 'Failed to upload company logo',
        variant: 'destructive',
      });
      throw error;
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  return {
    settings,
    loading,
    updateSettings,
    uploadLogo,
    refreshSettings: fetchSettings,
  };
}
