import React, { useState } from 'react';
import { SearchBar } from './SearchBar';
import { useSearch } from '@/hooks/use-search';
import { useCategories } from '@/hooks/use-categories';
import { Switch } from '@/components/ui/switch';
import { CategorySelect } from '@/components/categories';
import type { InventoryItem } from '@/types/inventory';
import { Card, CardContent } from '@/components/ui/card';

interface InventorySearchProps {
  initialData: InventoryItem[];
  onSearchResults: (results: InventoryItem[]) => void;
  className?: string;
}

export function InventorySearch({
  initialData,
  onSearchResults,
  className = '',
}: InventorySearchProps) {
  const [includeSubcategories, setIncludeSubcategories] = useState(true);
  const { categories } = useCategories();

  const {
    handleSearch,
    results,
    isLoading,
    searchFields,
  } = useSearch<InventoryItem>({
    entity: 'inventory',
    data: initialData,
    customFilters: {
      category: (item: InventoryItem, categoryId: string) => {
        if (!categoryId) return true;
        if (!includeSubcategories) return item.category_id === categoryId;
        
        // Helper function to get all subcategory IDs
        const getSubcategoryIds = (categoryId: string): string[] => {
          const category = categories.find(c => c.id === categoryId);
          if (!category) return [];
          const ids = [category.id];
          if (category.subcategories) {
            category.subcategories.forEach(sub => {
              ids.push(...getSubcategoryIds(sub.id));
            });
          }
          return ids;
        };

        const validCategoryIds = getSubcategoryIds(categoryId);
        return item.category_id ? validCategoryIds.includes(item.category_id) : false;
      }
    }
  });

  // Update parent component with search results
  React.useEffect(() => {
    onSearchResults(results.data);
  }, [results.data, onSearchResults]);

  return (
    <Card className={className}>
      <CardContent className="pt-6">
        <SearchBar
          onSearch={handleSearch}
          placeholder="Search inventory by name, type, quantity..."
          fields={[
            { id: 'name', label: 'Name', type: 'text' },
            { 
              id: 'type', 
              label: 'Type', 
              type: 'select',
              options: [
                { value: 'quantity', label: 'Quantity' },
                { value: 'dimensional', label: 'Dimensional' },
              ]
            },
            { id: 'current_quantity', label: 'Current Quantity', type: 'number' },
            { id: 'purchase_cost', label: 'Purchase Cost', type: 'number' },
            { 
              id: 'stock_status', 
              label: 'Stock Status', 
              type: 'select',
              options: [
                { value: 'in_stock', label: 'In Stock' },
                { value: 'low_stock', label: 'Low Stock' },
              ]
            },
            {
              id: 'category',
              label: 'Category',
              type: 'custom',
              component: ({ value, onChange }) => (
                <div className="space-y-2">
                  <CategorySelect
                    categories={categories}
                    value={value}
                    onChange={onChange}
                    placeholder="Filter by category"
                  />
                  <div className="flex items-center gap-2">
                    <Switch
                      checked={includeSubcategories}
                      onCheckedChange={setIncludeSubcategories}
                    />
                    <span className="text-sm">Include subcategories</span>
                  </div>
                </div>
              )
            }
          ]}
          isLoading={isLoading}
        />
        {results.filtered !== results.total && (
          <div className="mt-2 text-sm text-muted-foreground">
            Showing {results.filtered} of {results.total} items
          </div>
        )}
      </CardContent>
    </Card>
  );
}
