export interface Customer {
  id: string;
  name: string;
  email?: string;
  phone?: string;
  address?: string;
  company?: string;
  created_at?: string;
}

export interface CreateCustomerData {
  name: string;
  email?: string;
  phone?: string;
  address?: string;
  company?: string;
}

export interface UpdateCustomerData {
  name?: string;
  email?: string;
  phone?: string;
  address?: string;
  company?: string;
}

// Constants
export const PAYMENT_METHODS = {
  CASH: 'CASH' as const,
  TRANSFER: 'TRANSFER' as const,
  CREDIT: 'CREDIT' as const,
} as const;

export const ITEM_TYPES = {
  QUANTITY: 'quantity' as const,
  DIMENSIONAL: 'dimensional' as const,
} as const;

export type PaymentMethod = typeof PAYMENT_METHODS[keyof typeof PAYMENT_METHODS];
export type ItemType = typeof ITEM_TYPES[keyof typeof ITEM_TYPES];

export interface BaseSaleItem {
  id: string;
  inventory_item_id: string;
  name: string;
  type: ItemType;
  unit_price: number;
  total_price: number;
}

export interface QuantitySaleItem extends BaseSaleItem {
  type: typeof ITEM_TYPES.QUANTITY;
  quantity: number;
}

export interface DimensionalSaleItem extends BaseSaleItem {
  type: typeof ITEM_TYPES.DIMENSIONAL;
  width: number;
  length: number;
  area: number;
}

export type SaleItem = QuantitySaleItem | DimensionalSaleItem;

export interface SaleItemCreate extends Omit<BaseSaleItem, 'id' | 'unit_price'> {
  quantity?: number;
  area?: number;
}

export interface SaleInput {
  customer_id: string;
  items: SaleItemCreate[];
  total_amount: number;
  payment_method: PaymentMethod;
  notes?: string;
}

export interface SaleOutput {
  id: string;
  customer_id: string;
  items: SaleItem[];
  total_amount: number;
  status: 'pending' | 'approved' | 'cancelled';
  notes?: string;
  payment_method: PaymentMethod;
  created_at: string;
}

export interface Invoice {
  id: string;
  sale_id: string;
  customer_id: string;
  invoice_number: string;
  items: any[];
  subtotal: number;
  tax?: number;
  total_amount: number;
  amount_paid: number;
  status: 'DRAFT' | 'PAID' | 'PARTIALLY_PAID' | 'PENDING_PAYMENT';
  due_date?: string;
  notes?: string;
  created_at: string;
}

export interface CustomerHistoryItem {
  type: 'sale' | 'invoice';
  date: string;
  amount: number;
  status: string;
  id: string;
  payment_method?: PaymentMethod;
  amount_paid?: number;
  due_date?: string;
}

export interface CustomerHistory {
  customer: Customer;
  history: CustomerHistoryItem[];
  summary: {
    total_sales: number;
    total_invoices: number;
    total_paid: number;
  };
}

export interface Expense {
  id: string;
  category: string;
  amount: number;
  description: string;
  date: string;
  is_recurring: boolean;
  recurring_interval?: string;
  created_at: string;
}

export interface GetSalesReportData {
  total_sales: number;
  total_pending: number;
  sales_by_month: Record<string, number>;
  top_selling_items: {
    name: string;
    total_sales: number;
    quantity_sold: number;
    revenue: number;
    id: string;
  }[];
}

export interface GetInventoryReportData {
  total_items: number;
  total_value: number;
  stock_value_by_type: Record<string, number>;
  low_stock_items: {
    name: string;
    current_quantity: number;
    min_quantity: number;
    id: string;
  }[];
}

export interface GetProfitLossReportData {
  revenue: number;
  expenses: number;
  profit: number;
  gross_profit: number;
  gross_margin: number;
  sales_by_month: Record<string, number>;
  expenses_by_month: Record<string, number>;
  profit_by_month: {
    month: string;
    revenue: number;
    expenses: number;
    profit: number;
  }[];
}

export interface InventoryItem {
  id: string;
  name: string;
  type: ItemType;
  purchase_cost: number;
  current_quantity?: number;
  min_quantity?: number;
  min_area?: number;
  width?: number;
  length?: number;
  area?: number;
  cost_per_unit?: number;
  cost_per_sqft?: number;
  created_at: string;
}

export interface CreateInventoryData {
  name: string;
  type: ItemType;
  purchase_cost: number;
  current_quantity?: number;
  min_quantity?: number;
  min_area?: number;
  width?: number;
  length?: number;
}

// Type guards
export function isQuantityInventoryItem(item: InventoryItem): item is QuantityInventoryItem {
  return item.type === ITEM_TYPES.QUANTITY;
}

export function isDimensionalInventoryItem(item: InventoryItem): item is DimensionalInventoryItem {
  return item.type === ITEM_TYPES.DIMENSIONAL;
}

export interface QuantityInventoryItem extends InventoryItem {
  type: typeof ITEM_TYPES.QUANTITY;
  current_quantity: number;
  min_quantity: number;
}

export interface DimensionalInventoryItem extends InventoryItem {
  type: typeof ITEM_TYPES.DIMENSIONAL;
  width: number;
  length: number;
  area: number;
  min_area: number;
}
