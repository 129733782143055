import {
  Customer,
  CreateCustomerData,
  UpdateCustomerData,
  SaleInput,
  SaleOutput,
  Invoice,
  CustomerHistory,
  Expense,
  GetSalesReportData,
  GetInventoryReportData,
  GetProfitLossReportData,
  InventoryItem,
  CreateInventoryData,
} from "../types";

import {
  RestockHistoryItem,
  CreateRestockData,
  PriceChangeHistoryItem,
  UpdatePriceData,
  Supplier,
  CreateSupplierData,
  UpdateSupplierData,
  RestockAnalytics,
} from "../types/inventory-types";

import {
  Category,
  CategoryCreate,
  CategoryUpdate,
  CategoryListParams,
  CategoryDeleteParams,
  CategoryMoveParams,
} from "../types/category";

import { apiRequest } from '../utils/api';
import logger from '../utils/logger';

class Brain {
  private baseUrl: string;

  constructor() {
    // Configure API URL based on environment
    this.baseUrl = window.location.hostname === 'localhost' 
      ? 'http://localhost:8000' 
      : `https://${window.location.hostname}`;
  }

  private getAuthToken(): string | null {
    return localStorage.getItem('auth_token');
  }

  // Company Settings
  async get_company_settings() {
    logger.logOperationStart('get_company_settings');
    try {
      const result = await this.request("GET", "/api/company");
      logger.logOperationSuccess('get_company_settings', result);
      return result;
    } catch (error) {
      logger.logOperationError('get_company_settings', error);
      throw error;
    }
  }

  async update_company_settings(data: any) {
    logger.logOperationStart('update_company_settings', { data });
    try {
      const result = await this.request("PUT", "/api/company", data);
      logger.logOperationSuccess('update_company_settings', result);
      return result;
    } catch (error) {
      logger.logOperationError('update_company_settings', error, { data });
      throw error;
    }
  }

  async upload_company_logo(file: File) {
    logger.logOperationStart('upload_company_logo', { file });
    try {
      const formData = new FormData();
      formData.append('file', file);

      const headers: Record<string, string> = {
        "Authorization": `Bearer ${this.getAuthToken()}`,
      };

      const response = await fetch(this.baseUrl + '/api/company/logo', {
        method: 'POST',
        headers,
        body: formData,
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to upload logo');
      }

      const result = await response.json();
      logger.logOperationSuccess('upload_company_logo', result);
      return result;
    } catch (error) {
      logger.logOperationError('upload_company_logo', error, { file });
      throw error;
    }
  }

  // Invoice Templates
  async list_invoice_templates() {
    logger.logOperationStart('list_invoice_templates');
    try {
      const result = await this.request("GET", "/api/invoice-templates");
      logger.logOperationSuccess('list_invoice_templates', result);
      return result;
    } catch (error) {
      logger.logOperationError('list_invoice_templates', error);
      throw error;
    }
  }

  async create_invoice_template(data: any) {
    logger.logOperationStart('create_invoice_template', { data });
    try {
      const result = await this.request("POST", "/api/invoice-templates", data);
      logger.logOperationSuccess('create_invoice_template', result);
      return result;
    } catch (error) {
      logger.logOperationError('create_invoice_template', error, { data });
      throw error;
    }
  }

  async update_invoice_template(templateId: string, data: any) {
    logger.logOperationStart('update_invoice_template', { templateId, data });
    try {
      const result = await this.request("PUT", `/api/invoice-templates/${templateId}`, data);
      logger.logOperationSuccess('update_invoice_template', result);
      return result;
    } catch (error) {
      logger.logOperationError('update_invoice_template', error, { templateId, data });
      throw error;
    }
  }

  async set_default_template(templateId: string) {
    logger.logOperationStart('set_default_template', { templateId });
    try {
      const result = await this.request("POST", `/api/invoice-templates/${templateId}/set-default`);
      logger.logOperationSuccess('set_default_template', result);
      return result;
    } catch (error) {
      logger.logOperationError('set_default_template', error, { templateId });
      throw error;
    }
  }

  // Categories
  async list_categories(params?: CategoryListParams): Promise<Category[]> {
    logger.logOperationStart('list_categories', { params });
    try {
      const queryParams = new URLSearchParams();
      if (params?.parentId) {
        queryParams.append('parent_id', params.parentId);
      }
      if (params?.includeSubcategories !== undefined) {
        queryParams.append('include_subcategories', params.includeSubcategories.toString());
      }
      const result = await this.request("GET", `/api/categories?${queryParams}`);
      logger.logOperationSuccess('list_categories', result);
      return result;
    } catch (error) {
      logger.logOperationError('list_categories', error, { params });
      throw error;
    }
  }

  async create_category(data: CategoryCreate): Promise<Category> {
    logger.logOperationStart('create_category', { data });
    try {
      const result = await this.request("POST", "/api/categories", {
        name: data.name,
        description: data.description,
        parent_id: data.parentId,
      });
      logger.logOperationSuccess('create_category', result);
      return result;
    } catch (error) {
      logger.logOperationError('create_category', error, { data });
      throw error;
    }
  }

  async update_category(id: string, data: CategoryUpdate): Promise<Category> {
    logger.logOperationStart('update_category', { id, data });
    try {
      const result = await this.request("PUT", `/api/categories/${id}`, {
        name: data.name,
        description: data.description,
        parent_id: data.parentId,
      });
      logger.logOperationSuccess('update_category', result);
      return result;
    } catch (error) {
      logger.logOperationError('update_category', error, { id, data });
      throw error;
    }
  }

  async delete_category(id: string, params?: CategoryDeleteParams): Promise<void> {
    logger.logOperationStart('delete_category', { id, params });
    try {
      const queryParams = new URLSearchParams();
      if (params?.reassignTo) {
        queryParams.append('reassign_to', params.reassignTo);
      }
      await this.request("DELETE", `/api/categories/${id}?${queryParams}`);
      logger.logOperationSuccess('delete_category');
    } catch (error) {
      logger.logOperationError('delete_category', error, { id, params });
      throw error;
    }
  }

  async move_category(id: string, data: CategoryMoveParams): Promise<void> {
    logger.logOperationStart('move_category', { id, data });
    try {
      await this.request("POST", `/api/categories/${id}/move`, {
        parent_id: data.parentId,
        position: data.position,
      });
      logger.logOperationSuccess('move_category');
    } catch (error) {
      logger.logOperationError('move_category', error, { id, data });
      throw error;
    }
  }

  // Auth endpoints
  async login(credentials: { username: string; password: string }) {
    logger.logOperationStart('login', { credentials });
    try {
      const result = await this.request("POST", "/api/auth/login", credentials);
      logger.logOperationSuccess('login', result);
      return result;
    } catch (error) {
      logger.logOperationError('login', error, { credentials });
      throw error;
    }
  }

  async getCurrentUser() {
    logger.logOperationStart('getCurrentUser');
    try {
      const result = await this.request("GET", "/api/auth/me");
      logger.logOperationSuccess('getCurrentUser', result);
      return result;
    } catch (error) {
      logger.logOperationError('getCurrentUser', error);
      throw error;
    }
  }

  // Profile endpoints
  async getProfile() {
    logger.logOperationStart('getProfile');
    try {
      const result = await this.request("GET", "/api/profile");
      logger.logOperationSuccess('getProfile', result);
      return result;
    } catch (error) {
      logger.logOperationError('getProfile', error);
      throw error;
    }
  }

  async update_profile(data: {
    email: string;
    current_password?: string;
    new_password?: string;
  }) {
    logger.logOperationStart('update_profile', { data });
    try {
      const result = await this.request("PUT", "/api/profile", data);
      logger.logOperationSuccess('update_profile', result);
      return result;
    } catch (error) {
      logger.logOperationError('update_profile', error, { data });
      throw error;
    }
  }

  // User Management
  async list_users() {
    logger.logOperationStart('list_users');
    try {
      const result = await this.request("GET", "/api/users");
      logger.logOperationSuccess('list_users', result);
      return result;
    } catch (error) {
      logger.logOperationError('list_users', error);
      throw error;
    }
  }

  async create_user(userData: {
    username: string;
    email: string;
    password: string;
    role: string;
  }) {
    logger.logOperationStart('create_user', { userData });
    try {
      const result = await this.request("POST", "/api/users", userData);
      logger.logOperationSuccess('create_user', result);
      return result;
    } catch (error) {
      logger.logOperationError('create_user', error, { userData });
      throw error;
    }
  }

  async update_user_status(userId: string, isActive: boolean) {
    logger.logOperationStart('update_user_status', { userId, isActive });
    try {
      const result = await this.request("PATCH", `/api/users/${userId}/status`, { is_active: isActive });
      logger.logOperationSuccess('update_user_status', result);
      return result;
    } catch (error) {
      logger.logOperationError('update_user_status', error, { userId, isActive });
      throw error;
    }
  }

  // Customers
  async list_customers(): Promise<Customer[]> {
    logger.logOperationStart('list_customers');
    try {
      const result = await this.request("GET", "/api/customers");
      logger.logOperationSuccess('list_customers', result);
      return result;
    } catch (error) {
      logger.logOperationError('list_customers', error);
      throw error;
    }
  }

  async create_customer(customer: CreateCustomerData): Promise<Customer> {
    logger.logOperationStart('create_customer', { customer });
    try {
      const result = await this.request("POST", "/api/customers", customer);
      logger.logOperationSuccess('create_customer', result);
      return result;
    } catch (error) {
      logger.logOperationError('create_customer', error, { customer });
      throw error;
    }
  }

  async update_customer(params: { customerId: string }, customer: UpdateCustomerData): Promise<Customer> {
    logger.logOperationStart('update_customer', { params, customer });
    try {
      const result = await this.request("PUT", `/api/customers/${params.customerId}`, customer);
      logger.logOperationSuccess('update_customer', result);
      return result;
    } catch (error) {
      logger.logOperationError('update_customer', error, { params, customer });
      throw error;
    }
  }

  async delete_customer(params: { customerId: string }): Promise<null> {
    logger.logOperationStart('delete_customer', { params });
    try {
      await this.request("DELETE", `/api/customers/${params.customerId}`);
      logger.logOperationSuccess('delete_customer');
      return null;
    } catch (error) {
      logger.logOperationError('delete_customer', error, { params });
      throw error;
    }
  }

  async get_customer(params: { customerId: string }): Promise<Customer> {
    logger.logOperationStart('get_customer', { params });
    try {
      const result = await this.request("GET", `/api/customers/${params.customerId}`);
      logger.logOperationSuccess('get_customer', result);
      return result;
    } catch (error) {
      logger.logOperationError('get_customer', error, { params });
      throw error;
    }
  }

  async get_customer_history(params: { customerId: string }): Promise<CustomerHistory> {
    logger.logOperationStart('get_customer_history', { params });
    try {
      const result = await this.request("GET", `/api/customers/${params.customerId}/history`);
      logger.logOperationSuccess('get_customer_history', result);
      return result;
    } catch (error) {
      logger.logOperationError('get_customer_history', error, { params });
      throw error;
    }
  }

  // Sales
  async create_sale(sale: SaleInput): Promise<SaleOutput> {
    logger.logOperationStart('create_sale', { sale });
    try {
      const result = await this.request("POST", "/api/sales", sale);
      logger.logOperationSuccess('create_sale', result);
      return result;
    } catch (error) {
      logger.logOperationError('create_sale', error, { sale });
      throw error;
    }
  }

  async list_sales(): Promise<SaleOutput[]> {
    logger.logOperationStart('list_sales');
    try {
      const result = await this.request("GET", "/api/sales");
      logger.logOperationSuccess('list_sales', result);
      return result;
    } catch (error) {
      logger.logOperationError('list_sales', error);
      throw error;
    }
  }

  async get_sale(params: { saleId: string }): Promise<SaleOutput> {
    logger.logOperationStart('get_sale', { params });
    try {
      const result = await this.request("GET", `/api/sales/${params.saleId}`);
      logger.logOperationSuccess('get_sale', result);
      return result;
    } catch (error) {
      logger.logOperationError('get_sale', error, { params });
      throw error;
    }
  }

  async update_sale(saleId: string, data: any): Promise<SaleOutput> {
    logger.logOperationStart('update_sale', { saleId, data });
    try {
      const result = await this.request("PUT", `/api/sales/${saleId}`, data);
      logger.logOperationSuccess('update_sale', result);
      return result;
    } catch (error) {
      logger.logOperationError('update_sale', error, { saleId, data });
      throw error;
    }
  }

  async delete_sale(saleId: string): Promise<void> {
    logger.logOperationStart('delete_sale', { saleId });
    try {
      await this.request("DELETE", `/api/sales/${saleId}`);
      logger.logOperationSuccess('delete_sale');
    } catch (error) {
      logger.logOperationError('delete_sale', error, { saleId });
      throw error;
    }
  }

  async approve_sale(params: { saleId: string }): Promise<SaleOutput> {
    logger.logOperationStart('approve_sale', { params });
    try {
      const result = await this.request("PUT", `/api/sales/${params.saleId}/approve`);
      logger.logOperationSuccess('approve_sale', result);
      return result;
    } catch (error) {
      logger.logOperationError('approve_sale', error, { params });
      throw error;
    }
  }

  async cancel_sale(params: { saleId: string }): Promise<SaleOutput> {
    logger.logOperationStart('cancel_sale', { params });
    try {
      const result = await this.request("POST", `/api/sales/${params.saleId}/cancel`);
      logger.logOperationSuccess('cancel_sale', result);
      return result;
    } catch (error) {
      logger.logOperationError('cancel_sale', error, { params });
      throw error;
    }
  }

  // Invoices
  async list_invoices(): Promise<Invoice[]> {
    logger.logOperationStart('list_invoices');
    try {
      const result = await this.request("GET", "/api/invoices");
      logger.logOperationSuccess('list_invoices', result);
      return result;
    } catch (error) {
      logger.logOperationError('list_invoices', error);
      throw error;
    }
  }

  async get_invoice(params: { invoiceId: string }): Promise<Invoice> {
    logger.logOperationStart('get_invoice', { params });
    try {
      const result = await this.request("GET", `/api/invoices/${params.invoiceId}`);
      logger.logOperationSuccess('get_invoice', result);
      return result;
    } catch (error) {
      logger.logOperationError('get_invoice', error, { params });
      throw error;
    }
  }

  async record_payment(params: { invoiceId: string }, data: { amount: number }): Promise<Invoice> {
    logger.logOperationStart('record_payment', { params, data });
    try {
      const result = await this.request("POST", `/api/invoices/${params.invoiceId}/payments`, data);
      logger.logOperationSuccess('record_payment', result);
      return result;
    } catch (error) {
      logger.logOperationError('record_payment', error, { params, data });
      throw error;
    }
  }

  async send_invoice(params: { invoiceId: string }): Promise<void> {
    logger.logOperationStart('send_invoice', { params });
    try {
      await this.request("POST", `/api/invoices/${params.invoiceId}/send`);
      logger.logOperationSuccess('send_invoice');
    } catch (error) {
      logger.logOperationError('send_invoice', error, { params });
      throw error;
    }
  }

  // Invoice Reminders
  async setup_invoice_reminders(invoiceId: string, frequency: string) {
    logger.logOperationStart('setup_invoice_reminders', { invoiceId, frequency });
    try {
      const result = await this.request("POST", `/api/invoices/${invoiceId}/setup-reminders`, {
        reminder_config: {
          frequency,
          max_reminders: 5,
          reminder_message: 'Your invoice payment is due. Please process the payment at your earliest convenience.'
        }
      });
      logger.logOperationSuccess('setup_invoice_reminders', result);
      return result;
    } catch (error) {
      logger.logOperationError('setup_invoice_reminders', error, { invoiceId, frequency });
      throw error;
    }
  }

  async get_reminder_status(invoiceId: string) {
    logger.logOperationStart('get_reminder_status', { invoiceId });
    try {
      const result = await this.request("GET", `/api/invoices/${invoiceId}/reminder-status`);
      logger.logOperationSuccess('get_reminder_status', result);
      return result;
    } catch (error) {
      logger.logOperationError('get_reminder_status', error, { invoiceId });
      throw error;
    }
  }

  async update_reminder_frequency(invoiceId: string, frequency: string) {
    logger.logOperationStart('update_reminder_frequency', { invoiceId, frequency });
    try {
      const result = await this.request("PUT", `/api/invoices/${invoiceId}`, {
        reminder_frequency: frequency
      });
      logger.logOperationSuccess('update_reminder_frequency', result);
      return result;
    } catch (error) {
      logger.logOperationError('update_reminder_frequency', error, { invoiceId, frequency });
      throw error;
    }
  }

  async stop_reminders(invoiceId: string) {
    logger.logOperationStart('stop_reminders', { invoiceId });
    try {
      const result = await this.request("PUT", `/api/invoices/${invoiceId}`, {
        reminder_frequency: null
      });
      logger.logOperationSuccess('stop_reminders', result);
      return result;
    } catch (error) {
      logger.logOperationError('stop_reminders', error, { invoiceId });
      throw error;
    }
  }

  // Inventory
  async list_inventory(): Promise<InventoryItem[]> {
    logger.logOperationStart('list_inventory');
    try {
      const result = await this.request("GET", "/api/inventory");
      logger.logOperationSuccess('list_inventory', result);
      return result;
    } catch (error) {
      logger.logOperationError('list_inventory', error);
      throw error;
    }
  }

  async create_inventory_item(item: CreateInventoryData): Promise<InventoryItem> {
    logger.logOperationStart('create_inventory_item', { item });
    try {
      const result = await this.request("POST", "/api/inventory", item);
      logger.logOperationSuccess('create_inventory_item', result);
      return result;
    } catch (error) {
      logger.logOperationError('create_inventory_item', error, { item });
      throw error;
    }
  }

  // New Inventory Management Methods
  async restock_item(data: CreateRestockData): Promise<RestockHistoryItem> {
    logger.logOperationStart('restock_item', { data });
    try {
      const result = await this.request("POST", "/api/inventory/restock", data);
      logger.logOperationSuccess('restock_item', result);
      return result;
    } catch (error) {
      logger.logOperationError('restock_item', error, { data });
      throw error;
    }
  }

  async get_restock_history(params: { inventory_id: string }): Promise<RestockHistoryItem[]> {
    logger.logOperationStart('get_restock_history', { params });
    try {
      const result = await this.request("GET", `/api/inventory/${params.inventory_id}/restock-history`);
      logger.logOperationSuccess('get_restock_history', result);
      return result;
    } catch (error) {
      logger.logOperationError('get_restock_history', error, { params });
      throw error;
    }
  }

  async update_price(data: UpdatePriceData): Promise<PriceChangeHistoryItem> {
    logger.logOperationStart('update_price', { data });
    try {
      const result = await this.request("POST", "/api/inventory/price-change", data);
      logger.logOperationSuccess('update_price', result);
      return result;
    } catch (error) {
      logger.logOperationError('update_price', error, { data });
      throw error;
    }
  }

  async get_price_history(params: { inventory_id: string }): Promise<PriceChangeHistoryItem[]> {
    logger.logOperationStart('get_price_history', { params });
    try {
      const result = await this.request("GET", `/api/inventory/${params.inventory_id}/price-history`);
      logger.logOperationSuccess('get_price_history', result);
      return result;
    } catch (error) {
      logger.logOperationError('get_price_history', error, { params });
      throw error;
    }
  }

  async list_suppliers(): Promise<Supplier[]> {
    logger.logOperationStart('list_suppliers');
    try {
      const result = await this.request("GET", "/api/suppliers");
      logger.logOperationSuccess('list_suppliers', result);
      return result;
    } catch (error) {
      logger.logOperationError('list_suppliers', error);
      throw error;
    }
  }

  async create_supplier(supplier: CreateSupplierData): Promise<Supplier> {
    logger.logOperationStart('create_supplier', { supplier });
    try {
      const result = await this.request("POST", "/api/suppliers", supplier);
      logger.logOperationSuccess('create_supplier', result);
      return result;
    } catch (error) {
      logger.logOperationError('create_supplier', error, { supplier });
      throw error;
    }
  }

  async update_supplier(params: { supplier_id: string }, data: UpdateSupplierData): Promise<Supplier> {
    logger.logOperationStart('update_supplier', { params, data });
    try {
      const result = await this.request("PUT", `/api/suppliers/${params.supplier_id}`, data);
      logger.logOperationSuccess('update_supplier', result);
      return result;
    } catch (error) {
      logger.logOperationError('update_supplier', error, { params, data });
      throw error;
    }
  }

  async delete_supplier(params: { supplier_id: string }): Promise<null> {
    logger.logOperationStart('delete_supplier', { params });
    try {
      await this.request("DELETE", `/api/suppliers/${params.supplier_id}`);
      logger.logOperationSuccess('delete_supplier');
      return null;
    } catch (error) {
      logger.logOperationError('delete_supplier', error, { params });
      throw error;
    }
  }

  async get_supplier(params: { supplier_id: string }): Promise<Supplier> {
    logger.logOperationStart('get_supplier', { params });
    try {
      const result = await this.request("GET", `/api/suppliers/${params.supplier_id}`);
      logger.logOperationSuccess('get_supplier', result);
      return result;
    } catch (error) {
      logger.logOperationError('get_supplier', error, { params });
      throw error;
    }
  }

  async get_restock_analytics(params: { inventory_id: string }): Promise<RestockAnalytics> {
    logger.logOperationStart('get_restock_analytics', { params });
    try {
      const result = await this.request("GET", `/api/inventory/${params.inventory_id}/restock-analytics`);
      logger.logOperationSuccess('get_restock_analytics', result);
      return result;
    } catch (error) {
      logger.logOperationError('get_restock_analytics', error, { params });
      throw error;
    }
  }

  // Admin inventory management
  async update_inventory_item(id: string, data: any): Promise<any> {
    logger.logOperationStart('update_inventory_item', { id, data });
    try {
      const result = await this.request('PUT', `/api/inventory/${id}`, data);
      logger.logOperationSuccess('update_inventory_item', result);
      return result;
    } catch (error) {
      logger.logOperationError('update_inventory_item', error, { id, data });
      throw error;
    }
  }

  async delete_inventory_item(id: string): Promise<any> {
    logger.logOperationStart('delete_inventory_item', { id });
    try {
      const result = await this.request('DELETE', `/api/inventory/${id}`);
      logger.logOperationSuccess('delete_inventory_item', result);
      return result;
    } catch (error) {
      logger.logOperationError('delete_inventory_item', error, { id });
      throw error;
    }
  }

  // Expenses
  async list_expenses(): Promise<Expense[]> {
    logger.logOperationStart('list_expenses');
    try {
      const result = await this.request("GET", "/api/expenses");
      logger.logOperationSuccess('list_expenses', result);
      return result;
    } catch (error) {
      logger.logOperationError('list_expenses', error);
      throw error;
    }
  }

  async create_expense(expense: Omit<Expense, 'id' | 'created_at'>): Promise<Expense> {
    logger.logOperationStart('create_expense', { expense });
    try {
      const result = await this.request("POST", "/api/expenses", expense);
      logger.logOperationSuccess('create_expense', result);
      return result;
    } catch (error) {
      logger.logOperationError('create_expense', error, { expense });
      throw error;
    }
  }

  // Reports
  async get_sales_report(): Promise<GetSalesReportData> {
    logger.logOperationStart('get_sales_report');
    try {
      const result = await this.request("GET", "/api/reports/sales");
      logger.logOperationSuccess('get_sales_report', result);
      return result;
    } catch (error) {
      logger.logOperationError('get_sales_report', error);
      throw error;
    }
  }

  async get_inventory_report(): Promise<GetInventoryReportData> {
    logger.logOperationStart('get_inventory_report');
    try {
      const result = await this.request("GET", "/api/reports/inventory");
      logger.logOperationSuccess('get_inventory_report', result);
      return result;
    } catch (error) {
      logger.logOperationError('get_inventory_report', error);
      throw error;
    }
  }

  async get_profit_loss_report(): Promise<GetProfitLossReportData> {
    logger.logOperationStart('get_profit_loss_report');
    try {
      const result = await this.request("GET", "/api/reports/profit-loss");
      logger.logOperationSuccess('get_profit_loss_report', result);
      return result;
    } catch (error) {
      logger.logOperationError('get_profit_loss_report', error);
      throw error;
    }
  }

  // Private helper methods
  private async request(method: string, endpoint: string, data?: any) {
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
    };

    const token = localStorage.getItem('auth_token');
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    } else if (!endpoint.includes('/auth/login')) {
      // Only redirect to login if we're not already trying to login
      window.location.href = '/login';
      throw new Error('Authentication required');
    }

    logger.logAPIRequest(method, endpoint, data);
    try {
      const response = await fetch(this.baseUrl + endpoint, {
        method,
        headers,
        body: data ? JSON.stringify(data) : undefined,
        credentials: 'include',
      });

      const responseData = await response.json().catch(() => null);
      logger.logAPIResponse(method, endpoint, response.status, responseData);

      if (!response.ok) {
        if (response.status === 401 && !endpoint.includes('/auth/login')) {
          localStorage.removeItem('auth_token');
          window.location.href = '/login';
          throw new Error('Authentication required');
        }
        
        const errorMessage = responseData?.detail || responseData?.message || 'API request failed';
        logger.logAPIError(method, endpoint, errorMessage, { data });
        throw new Error(errorMessage);
      }

      return responseData;
    } catch (error) {
      logger.logAPIError(method, endpoint, error, { data });
      if (error instanceof Error && error.message === 'Failed to fetch') {
        throw new Error('Network error. Please check your connection.');
      }
      throw error;
    }
  }
}

export default new Brain();
