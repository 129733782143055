import * as React from "react";
import { cn } from "../../utils/cn";
import { Loader2 } from "lucide-react";

// Spinner component with different sizes
export function Spinner({ className, size = "default" }: { className?: string; size?: "sm" | "default" | "lg" }) {
  const sizeClasses = {
    sm: "h-4 w-4",
    default: "h-6 w-6",
    lg: "h-8 w-8"
  };

  return (
    <Loader2 
      className={cn(
        "animate-spin",
        sizeClasses[size],
        className
      )} 
    />
  );
}

// Loading overlay for sections/cards
export function LoadingOverlay({ 
  children, 
  loading, 
  blur = false 
}: { 
  children: React.ReactNode; 
  loading: boolean; 
  blur?: boolean;
}) {
  if (!loading) return <>{children}</>;

  return (
    <div className="relative">
      <div className={cn(
        "transition-opacity",
        loading ? "opacity-50" : "opacity-100",
        blur && loading ? "blur-sm" : ""
      )}>
        {children}
      </div>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Spinner size="lg" className="text-primary" />
        </div>
      )}
    </div>
  );
}

// Full page loading
export function LoadingPage() {
  return (
    <div className="flex min-h-[400px] items-center justify-center">
      <div className="flex flex-col items-center gap-2">
        <Spinner size="lg" className="text-primary" />
        <p className="text-sm text-muted-foreground animate-pulse">
          Loading...
        </p>
      </div>
    </div>
  );
}

// Loading skeleton for content
export function LoadingSkeleton({ 
  className, 
  ...props 
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        "animate-pulse rounded-md bg-muted",
        className
      )}
      {...props}
    />
  );
}

// Loading dots animation
export function LoadingDots() {
  return (
    <span className="inline-flex items-center gap-1">
      <span className="animate-bounce [animation-delay:-0.3s]">.</span>
      <span className="animate-bounce [animation-delay:-0.2s]">.</span>
      <span className="animate-bounce [animation-delay:-0.1s]">.</span>
    </span>
  );
}

// Loading button (extends existing button component)
export function LoadingButton({ 
  children, 
  loading = false,
  loadingText = "Loading",
  ...props 
}: React.ButtonHTMLAttributes<HTMLButtonElement> & { 
  loading?: boolean;
  loadingText?: string;
}) {
  return (
    <button
      disabled={loading}
      className={cn(
        "inline-flex items-center justify-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
        props.className
      )}
      {...props}
    >
      {loading ? (
        <>
          <Spinner size="sm" className="mr-2" />
          {loadingText}
        </>
      ) : (
        children
      )}
    </button>
  );
}
