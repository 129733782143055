import { useState, useCallback, useMemo, useEffect } from 'react';
import type { SearchFilter, SearchField } from '@/components/search/SearchBar';
import type { 
  InventoryItem, 
  SaleOutput, 
  Customer, 
  Invoice, 
  Expense 
} from '@/types';

export type SearchableEntity = 'inventory' | 'sales' | 'customers' | 'invoices' | 'expenses';

interface SearchConfig<T> {
  entity: SearchableEntity;
  initialQuery?: string;
  initialFilters?: SearchFilter[];
  data?: T[];
  onDataNeeded?: () => Promise<T[]>;
  customFilters?: {
    [key: string]: (item: T, value: any) => boolean;
  };
}

interface SearchResult<T> {
  data: T[];
  total: number;
  filtered: number;
}

export function useSearch<T>({
  entity,
  initialQuery = '',
  initialFilters = [],
  data: initialData = [],
  onDataNeeded,
  customFilters = {},
}: SearchConfig<T>) {
  const [query, setQuery] = useState(initialQuery);
  const [filters, setFilters] = useState<SearchFilter[]>(initialFilters);
  const [data, setData] = useState<T[]>(initialData);
  const [results, setResults] = useState<SearchResult<T>>({
    data: initialData,
    total: initialData.length,
    filtered: initialData.length,
  });
  const [isLoading, setIsLoading] = useState(false);

  // Update internal data when initialData changes
  useEffect(() => {
    setData(initialData);
    // Reset search results with new data
    setResults({
      data: initialData,
      total: initialData.length,
      filtered: initialData.length,
    });
  }, [initialData]);

  // Define searchable fields for each entity
  const entityFields = useMemo(() => {
    const fields: Record<SearchableEntity, SearchField[]> = {
      inventory: [
        { id: 'name', label: 'Name', type: 'text' },
        { 
          id: 'type', 
          label: 'Type', 
          type: 'select',
          options: [
            { value: 'quantity', label: 'Quantity' },
            { value: 'dimensional', label: 'Dimensional' },
          ]
        },
        { id: 'current_quantity', label: 'Current Quantity', type: 'number' },
        { id: 'purchase_cost', label: 'Purchase Cost', type: 'number' },
      ],
      sales: [
        { id: 'id', label: 'Sale ID', type: 'text' },
        { 
          id: 'status', 
          label: 'Status', 
          type: 'select',
          options: [
            { value: 'pending', label: 'Pending' },
            { value: 'approved', label: 'Approved' },
            { value: 'cancelled', label: 'Cancelled' },
          ]
        },
        { id: 'total_amount', label: 'Total Amount', type: 'number' },
      ],
      customers: [
        { id: 'name', label: 'Name', type: 'text' },
        { id: 'email', label: 'Email', type: 'text' },
        { id: 'phone', label: 'Phone', type: 'text' },
        { id: 'company', label: 'Company', type: 'text' },
      ],
      invoices: [
        { id: 'invoice_number', label: 'Invoice Number', type: 'text' },
        { 
          id: 'status', 
          label: 'Status', 
          type: 'select',
          options: [
            { value: 'DRAFT', label: 'Draft' },
            { value: 'PAID', label: 'Paid' },
            { value: 'PARTIALLY_PAID', label: 'Partially Paid' },
            { value: 'PENDING_PAYMENT', label: 'Pending Payment' },
          ]
        },
        { id: 'total_amount', label: 'Total Amount', type: 'number' },
      ],
      expenses: [
        { id: 'category', label: 'Category', type: 'text' },
        { id: 'description', label: 'Description', type: 'text' },
        { id: 'amount', label: 'Amount', type: 'number' },
        { id: 'date', label: 'Date', type: 'date' },
      ],
    };

    return fields[entity];
  }, [entity]);

  // Apply filters to data
  const applyFilters = useCallback((items: T[], searchQuery: string, searchFilters: SearchFilter[]) => {
    let filtered = [...items];

    // Apply text search if query exists
    if (searchQuery) {
      const lowercaseQuery = searchQuery.toLowerCase();
      filtered = filtered.filter((item) => {
        // Search through all string and number fields
        return Object.entries(item as Record<string, any>).some(([key, value]) => {
          if (typeof value === 'string' || typeof value === 'number') {
            return value.toString().toLowerCase().includes(lowercaseQuery);
          }
          return false;
        });
      });
    }

    // Apply filters
    if (searchFilters.length > 0) {
      filtered = filtered.filter((item) => {
        return searchFilters.every((filter) => {
          // Check if there's a custom filter for this field
          if (customFilters[filter.field]) {
            return customFilters[filter.field](item, filter.value);
          }

          const value = (item as Record<string, any>)[filter.field];
          if (value === undefined || value === null) return false;

          switch (filter.operator) {
            case 'contains':
              return value.toString().toLowerCase().includes(filter.value.toString().toLowerCase());
            case 'equals':
              return value.toString().toLowerCase() === filter.value.toString().toLowerCase();
            case 'greaterThan':
              return parseFloat(value) > parseFloat(filter.value.toString());
            case 'lessThan':
              return parseFloat(value) < parseFloat(filter.value.toString());
            default:
              return false;
          }
        });
      });
    }

    return filtered;
  }, [customFilters]);

  // Perform search
  const performSearch = useCallback(async (searchQuery: string, searchFilters: SearchFilter[]) => {
    setIsLoading(true);
    try {
      let searchData = data;
      
      // If we have a data fetching function and no data, fetch it
      if (onDataNeeded && data.length === 0) {
        const fetchedData = await onDataNeeded();
        searchData = fetchedData;
        setData(fetchedData);
      }

      // Apply filters to data
      const filtered = applyFilters(searchData, searchQuery, searchFilters);

      setResults({
        data: filtered,
        total: searchData.length,
        filtered: filtered.length,
      });
    } catch (error) {
      console.error('Search error:', error);
      // Keep the current results on error
    } finally {
      setIsLoading(false);
    }
  }, [data, onDataNeeded, applyFilters]);

  // Handle search
  const handleSearch = useCallback((searchQuery: string, searchFilters: SearchFilter[]) => {
    setQuery(searchQuery);
    setFilters(searchFilters);
    performSearch(searchQuery, searchFilters);
  }, [performSearch]);

  return {
    query,
    filters,
    results,
    isLoading,
    handleSearch,
    searchFields: entityFields,
  };
}
