import { useEffect, useCallback } from 'react';

type KeyHandler = (event: KeyboardEvent) => void;

interface KeyMap {
  [key: string]: KeyHandler | KeyCombo;
}

interface KeyCombo {
  key: string;
  ctrl?: boolean;
  alt?: boolean;
  shift?: boolean;
  handler: KeyHandler;
}

// Convert key combination to string format (e.g., "ctrl+k")
function getKeyComboString(combo: KeyCombo): string {
  const parts: string[] = [];
  if (combo.ctrl) parts.push('ctrl');
  if (combo.alt) parts.push('alt');
  if (combo.shift) parts.push('shift');
  parts.push(combo.key.toLowerCase());
  return parts.join('+');
}

// Check if event matches key combination
function matchesKeyCombo(event: KeyboardEvent, combo: KeyCombo): boolean {
  return (
    event.key.toLowerCase() === combo.key.toLowerCase() &&
    !!event.ctrlKey === !!combo.ctrl &&
    !!event.altKey === !!combo.alt &&
    !!event.shiftKey === !!combo.shift
  );
}

export function useKeyboardNavigation(keyMap: { [key: string]: KeyHandler | KeyCombo }) {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      // Don't handle keys if user is typing in an input
      if (
        event.target instanceof HTMLInputElement ||
        event.target instanceof HTMLTextAreaElement ||
        (event.target instanceof HTMLElement && event.target.isContentEditable)
      ) {
        return;
      }

      Object.entries(keyMap).forEach(([keyCombo, handler]) => {
        if (typeof handler === 'function') {
          // Simple key handler
          if (event.key.toLowerCase() === keyCombo.toLowerCase()) {
            event.preventDefault();
            handler(event);
          }
        } else {
          // Key combination handler
          if (matchesKeyCombo(event, handler)) {
            event.preventDefault();
            handler.handler(event);
          }
        }
      });
    },
    [keyMap]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);
}

// Common keyboard shortcuts
export const KEYBOARD_SHORTCUTS = {
  OPEN_MENU: { key: 'm', alt: true, handler: () => {} },
  CLOSE_MENU: { key: 'Escape', handler: () => {} },
  NAVIGATE_HOME: { key: 'h', alt: true, handler: () => {} },
  OPEN_SETTINGS: { key: ',', ctrl: true, handler: () => {} },
  SEARCH: { key: 'k', ctrl: true, handler: () => {} },
  SAVE: { key: 's', ctrl: true, handler: () => {} },
  HELP: { key: '/', ctrl: true, handler: () => {} },
  TOGGLE_THEME: { key: 't', alt: true, handler: () => {} },
  LOGOUT: { key: 'l', alt: true, handler: () => {} },
} as const;

// Hook for focus management
export function useFocusTrap(ref: React.RefObject<HTMLElement>) {
  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const focusableElements = element.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    
    const firstFocusable = focusableElements[0] as HTMLElement;
    const lastFocusable = focusableElements[focusableElements.length - 1] as HTMLElement;

    function handleTabKey(e: KeyboardEvent) {
      if (e.key !== 'Tab') return;

      if (e.shiftKey) {
        if (document.activeElement === firstFocusable) {
          e.preventDefault();
          lastFocusable.focus();
        }
      } else {
        if (document.activeElement === lastFocusable) {
          e.preventDefault();
          firstFocusable.focus();
        }
      }
    }

    element.addEventListener('keydown', handleTabKey);
    return () => element.removeEventListener('keydown', handleTabKey);
  }, [ref]);
}

// Hook for managing focus history
export function useFocusHistory() {
  useEffect(() => {
    const focusHistory: HTMLElement[] = [];

    const handleFocusIn = (event: FocusEvent) => {
      const target = event.target as HTMLElement;
      if (target.tagName !== 'BODY') {
        focusHistory.push(target);
        if (focusHistory.length > 10) focusHistory.shift();
      }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      // Alt + Left Arrow to go back in focus history
      if (event.altKey && event.key === 'ArrowLeft' && focusHistory.length > 1) {
        event.preventDefault();
        focusHistory.pop(); // Remove current focus
        const previousElement = focusHistory.pop();
        if (previousElement) previousElement.focus();
      }
    };

    document.addEventListener('focusin', handleFocusIn);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('focusin', handleFocusIn);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
}
