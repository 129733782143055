import { Button } from "../components/ui";
import { UserIcon, Mail, Phone, Building, MapPin, Shield, AlertTriangle } from "lucide-react";
import { useUserProfile } from "../hooks/use-user-profile";
import { useSecuritySettings } from "../hooks/use-security-settings";
import { EditProfileDialog } from "../components/profile/EditProfileDialog";
import { ChangePasswordDialog } from "../components/profile/ChangePasswordDialog";
import { TwoFactorDialog } from "../components/profile/TwoFactorDialog";

export default function Profile() {
  const { profile } = useUserProfile();
  const { settings } = useSecuritySettings();

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }).format(date);
  };

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-2xl font-bold tracking-tight">Profile</h2>
        <p className="text-muted-foreground">
          View and manage your profile information.
        </p>
      </div>

      <div className="grid gap-6 md:grid-cols-2">
        {/* Profile Information */}
        <div className="border rounded-lg p-4 space-y-4">
          <h3 className="text-lg font-medium">Personal Information</h3>
          
          <div className="space-y-4">
            <div className="flex items-center gap-4">
              <div className="h-20 w-20 rounded-full bg-muted flex items-center justify-center">
                <UserIcon className="h-10 w-10 text-muted-foreground" />
              </div>
              <div>
                <h4 className="text-xl font-semibold">{profile.name}</h4>
                <p className="text-sm text-muted-foreground">{profile.role}</p>
              </div>
            </div>

            <div className="space-y-2">
              <div className="flex items-center gap-2 text-sm">
                <Mail className="h-4 w-4 text-muted-foreground" />
                <span>{profile.email}</span>
              </div>
              <div className="flex items-center gap-2 text-sm">
                <Phone className="h-4 w-4 text-muted-foreground" />
                <span>{profile.phone}</span>
              </div>
              <div className="flex items-center gap-2 text-sm">
                <Building className="h-4 w-4 text-muted-foreground" />
                <span>{profile.company}</span>
              </div>
              <div className="flex items-center gap-2 text-sm">
                <MapPin className="h-4 w-4 text-muted-foreground" />
                <span>{profile.address}</span>
              </div>
            </div>
          </div>

          <EditProfileDialog />
        </div>

        {/* Security Settings */}
        <div className="border rounded-lg p-4 space-y-4">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium">Security</h3>
            <Shield className={`h-5 w-5 ${settings.passwordStrength === 'strong' ? 'text-green-500' : settings.passwordStrength === 'medium' ? 'text-yellow-500' : 'text-red-500'}`} />
          </div>
          
          <div className="space-y-4">
            <div>
              <label className="text-sm font-medium block">Password</label>
              <div className="flex items-center justify-between">
                <p className="text-sm text-muted-foreground">
                  Last changed {formatDate(settings.lastPasswordChange)}
                </p>
                <span className={`text-xs font-medium px-2 py-1 rounded-full ${
                  settings.passwordStrength === 'strong' 
                    ? 'bg-green-100 text-green-700'
                    : settings.passwordStrength === 'medium'
                    ? 'bg-yellow-100 text-yellow-700'
                    : 'bg-red-100 text-red-700'
                }`}>
                  {settings.passwordStrength.charAt(0).toUpperCase() + settings.passwordStrength.slice(1)}
                </span>
              </div>
            </div>

            <ChangePasswordDialog />

            <div className="pt-4">
              <div className="flex items-center justify-between">
                <div>
                  <label className="text-sm font-medium block">Two-Factor Authentication</label>
                  <p className="text-sm text-muted-foreground">
                    {settings.twoFactorEnabled 
                      ? "2FA is currently enabled" 
                      : "Add an extra layer of security to your account"}
                  </p>
                </div>
                {settings.twoFactorEnabled && (
                  <Shield className="h-5 w-5 text-green-500" />
                )}
              </div>
            </div>

            <TwoFactorDialog />

            {!settings.twoFactorEnabled && (
              <div className="flex items-center gap-2 p-2 rounded bg-yellow-50 text-yellow-700">
                <AlertTriangle className="h-4 w-4" />
                <span className="text-xs">We recommend enabling 2FA for enhanced security</span>
              </div>
            )}
          </div>
        </div>

        {/* Activity Log */}
        <div className="border rounded-lg p-4 space-y-4 md:col-span-2">
          <h3 className="text-lg font-medium">Recent Activity</h3>
          
          <div className="space-y-4">
            <div className="text-sm text-muted-foreground italic">
              Activity log coming soon...
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
