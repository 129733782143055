import { MoonIcon, PrinterIcon, SunIcon, UserIcon, MonitorIcon, KeyboardIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { useTheme } from "../../hooks/use-theme";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useKeyboardNavigation, KEYBOARD_SHORTCUTS, useFocusTrap } from "../../hooks/use-keyboard-navigation";
import { toast } from "../../utils/use-toast";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "../ui";

const navigationItems = [
  { name: "Dashboard", path: "/", shortcut: "alt+h" },
  { name: "Inventory", path: "/inventory", shortcut: "alt+i" },
  { name: "Categories", path: "/categories", shortcut: "alt+g" },
  { name: "Suppliers", path: "/suppliers", shortcut: "alt+u" },
  { name: "Customers", path: "/customers", shortcut: "alt+c" },
  { name: "Sales", path: "/sales", shortcut: "alt+s" },
  { name: "Invoices", path: "/invoices", shortcut: "alt+v" },
  { name: "Reports", path: "/reports", shortcut: "alt+r" },
  { name: "Expenses", path: "/expenses", shortcut: "alt+e" },
];

export function Navbar() {
  const { theme, setTheme } = useTheme();
  const { logout, user } = useAuth();
  const [isScrolled, setIsScrolled] = useState(false);
  const [showKeyboardHelp, setShowKeyboardHelp] = useState(false);
  const navRef = useRef<HTMLElement>(null);

  // Use focus trap for keyboard navigation
  useFocusTrap(navRef);

  // Handle scroll effect for shadow
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Setup keyboard shortcuts
  useKeyboardNavigation({
    [KEYBOARD_SHORTCUTS.TOGGLE_THEME.key]: () => {
      const nextTheme = theme === 'light' ? 'dark' : 'light';
      setTheme(nextTheme);
      toast({
        title: "Theme Changed",
        description: `Switched to ${nextTheme} theme`,
      });
    },
    [KEYBOARD_SHORTCUTS.LOGOUT.key]: () => {
      handleLogout();
    },
    '/': () => {
      setShowKeyboardHelp(true);
    },
    'Escape': () => {
      setShowKeyboardHelp(false);
    },
    ...navigationItems.reduce((acc, item) => ({
      ...acc,
      [`alt+${item.name[0].toLowerCase()}`]: () => {
        window.location.href = item.path;
      },
    }), {}),
  });

  const handleLogout = () => {
    logout();
  };

  return (
    <nav
      ref={navRef}
      className={`sticky top-0 z-50 border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 ${
        isScrolled ? "shadow-sm" : ""
      }`}
      role="navigation"
      aria-label="Main navigation"
    >
      <div className="flex h-16 items-center px-8">
        {/* Logo */}
        <div className="flex items-center gap-3">
          <PrinterIcon className="h-8 w-8 text-primary" />
          <h1 className="text-3xl font-bold">PrintPressPro</h1>
        </div>

        {/* Navigation */}
        <div className="ml-8 flex items-center space-x-4 overflow-x-auto">
          {navigationItems.map((item) => (
            <Button 
              key={item.path} 
              variant="ghost" 
              asChild
              title={`${item.name} (${item.shortcut})`}
            >
              <Link to={item.path}>{item.name}</Link>
            </Button>
          ))}
        </div>

        {/* Right Side Items */}
        <div className="ml-auto flex items-center gap-2">
          {/* Keyboard Help */}
          <Button
            variant="outline"
            size="icon"
            className="h-9 w-9"
            onClick={() => setShowKeyboardHelp(!showKeyboardHelp)}
            title="Keyboard shortcuts (/)"
          >
            <KeyboardIcon className="h-5 w-5" />
          </Button>

          {/* Theme Switcher */}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button 
                variant="outline" 
                size="icon" 
                className="h-9 w-9"
                title={`Toggle theme (${KEYBOARD_SHORTCUTS.TOGGLE_THEME.key})`}
              >
                {theme === "light" ? (
                  <SunIcon className="h-5 w-5" />
                ) : theme === "dark" ? (
                  <MoonIcon className="h-5 w-5" />
                ) : (
                  <MonitorIcon className="h-5 w-5" />
                )}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={() => setTheme("light")}>
                <SunIcon className="mr-2 h-4 w-4" />
                Light
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => setTheme("dark")}>
                <MoonIcon className="mr-2 h-4 w-4" />
                Dark
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => setTheme("system")}>
                <MonitorIcon className="mr-2 h-4 w-4" />
                System
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          {/* User Menu */}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button 
                variant="outline" 
                size="icon" 
                className="h-9 w-9"
                title="User menu (alt+p)"
              >
                <UserIcon className="h-5 w-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <div className="px-2 py-1.5 text-sm font-medium text-muted-foreground">
                {user?.email}
              </div>
              <DropdownMenuSeparator />
              <DropdownMenuItem asChild>
                <Link to="/profile" className="w-full">Profile</Link>
              </DropdownMenuItem>
              {user?.role === 'admin' && (
                <DropdownMenuItem asChild>
                  <Link to="/users" className="w-full">Users</Link>
                </DropdownMenuItem>
              )}
              <DropdownMenuItem asChild>
                <Link to="/settings" className="w-full">Settings</Link>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem 
                onClick={handleLogout}
                className="text-red-600 hover:text-red-700 focus:text-red-700 cursor-pointer"
                title={`Logout (${KEYBOARD_SHORTCUTS.LOGOUT.key})`}
              >
                Logout
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      {/* Keyboard Shortcuts Help Dialog */}
      {showKeyboardHelp && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-background p-6 rounded-lg shadow-lg max-w-md w-full space-y-4">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold">Keyboard Shortcuts</h2>
              <Button 
                variant="ghost" 
                size="sm"
                onClick={() => setShowKeyboardHelp(false)}
              >
                Close
              </Button>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h3 className="font-medium mb-2">Navigation</h3>
                {navigationItems.map((item) => (
                  <div key={item.path} className="flex justify-between text-sm py-1">
                    <span>{item.name}</span>
                    <kbd className="px-2 py-0.5 bg-muted rounded text-xs">
                      {item.shortcut}
                    </kbd>
                  </div>
                ))}
              </div>
              <div>
                <h3 className="font-medium mb-2">System</h3>
                <div className="space-y-2 text-sm">
                  <div className="flex justify-between py-1">
                    <span>Toggle Theme</span>
                    <kbd className="px-2 py-0.5 bg-muted rounded text-xs">
                      {KEYBOARD_SHORTCUTS.TOGGLE_THEME.key}
                    </kbd>
                  </div>
                  <div className="flex justify-between py-1">
                    <span>Logout</span>
                    <kbd className="px-2 py-0.5 bg-muted rounded text-xs">
                      {KEYBOARD_SHORTCUTS.LOGOUT.key}
                    </kbd>
                  </div>
                  <div className="flex justify-between py-1">
                    <span>Show Shortcuts</span>
                    <kbd className="px-2 py-0.5 bg-muted rounded text-xs">/</kbd>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}
