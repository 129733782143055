import { useEffect, useState } from 'react';

// Breakpoint definitions matching our Tailwind config
export const breakpoints = {
  xs: 475,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1400,
} as const;

type Breakpoint = keyof typeof breakpoints;

/**
 * Custom hook to check if the current viewport matches a breakpoint
 * @param breakpoint - The breakpoint to check against
 * @returns boolean indicating if the viewport width is greater than or equal to the breakpoint
 */
export const useBreakpoint = (breakpoint: Breakpoint): boolean => {
  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    // Initial check
    const checkWidth = () => {
      const width = window.innerWidth;
      setMatches(width >= breakpoints[breakpoint]);
    };

    // Check on mount
    checkWidth();

    // Add resize listener
    window.addEventListener('resize', checkWidth);

    // Cleanup
    return () => window.removeEventListener('resize', checkWidth);
  }, [breakpoint]);

  return matches;
};

/**
 * Custom hook to get the current active breakpoint
 * @returns The current active breakpoint
 */
export const useActiveBreakpoint = (): Breakpoint => {
  const [activeBreakpoint, setActiveBreakpoint] = useState<Breakpoint>('xs');

  useEffect(() => {
    const checkBreakpoint = () => {
      const width = window.innerWidth;
      const breakpointEntries = Object.entries(breakpoints);
      
      // Find the largest breakpoint that the current width is greater than or equal to
      for (let i = breakpointEntries.length - 1; i >= 0; i--) {
        const [breakpoint, minWidth] = breakpointEntries[i];
        if (width >= minWidth) {
          setActiveBreakpoint(breakpoint as Breakpoint);
          return;
        }
      }
      
      // Default to smallest breakpoint if no others match
      setActiveBreakpoint('xs');
    };

    // Check on mount
    checkBreakpoint();

    // Add resize listener
    window.addEventListener('resize', checkBreakpoint);

    // Cleanup
    return () => window.removeEventListener('resize', checkBreakpoint);
  }, []);

  return activeBreakpoint;
};

/**
 * Custom hook to handle touch-friendly interactions
 * @returns Object containing touch-related utilities
 */
export const useTouchFriendly = () => {
  const isMobile = useBreakpoint('sm');
  
  return {
    isMobile,
    minTouchTarget: isMobile ? 44 : 32, // Minimum touch target size in pixels
    useHover: !isMobile, // Whether to use hover states
  };
};

/**
 * Custom hook for responsive grid layouts
 * @param itemCount - Number of items in the grid
 * @returns The number of columns to use for the current breakpoint
 */
export const useResponsiveGrid = (itemCount: number): number => {
  const breakpoint = useActiveBreakpoint();
  
  // Define column counts for different breakpoints
  const getColumns = (): number => {
    switch (breakpoint) {
      case '2xl':
      case 'xl':
        return Math.min(4, itemCount);
      case 'lg':
        return Math.min(3, itemCount);
      case 'md':
        return Math.min(2, itemCount);
      default:
        return 1;
    }
  };

  return getColumns();
};

/**
 * Helper function to generate responsive styles
 * @param styles - Object containing styles for different breakpoints
 * @returns Tailwind classes for responsive styles
 */
export const createResponsiveStyles = <T extends Record<string, string>>(
  styles: Partial<Record<Breakpoint, T>>
): string => {
  return Object.entries(styles)
    .map(([breakpoint, style]) => {
      const prefix = breakpoint === 'xs' ? '' : `${breakpoint}:`;
      return Object.values(style)
        .map((value) => `${prefix}${value}`)
        .join(' ');
    })
    .join(' ');
};

// Example usage:
// const responsiveClasses = createResponsiveStyles({
//   xs: { width: 'w-full', padding: 'p-4' },
//   md: { width: 'w-1/2', padding: 'p-6' },
//   lg: { width: 'w-1/3', padding: 'p-8' },
// });
