import * as z from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Button } from "../ui/button"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../ui/form"
import { Input } from "../ui/input"
import { useState, useRef } from "react"
import { Mail } from "lucide-react"
import { toast } from "../../utils/use-toast"
import { LoadingOverlay } from "../ui/loading"
import { useKeyboardNavigation, useFocusTrap } from "../../hooks/use-keyboard-navigation"

const requestResetSchema = z.object({
  email: z.string().email("Please enter a valid email address"),
});

type RequestResetFormValues = z.infer<typeof requestResetSchema>

export function RequestPasswordReset(): JSX.Element {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const formRef = useRef<HTMLDivElement>(null)
  
  const form = useForm<RequestResetFormValues>({
    resolver: zodResolver(requestResetSchema),
    defaultValues: {
      email: "",
    },
  })

  // Use focus trap for keyboard navigation
  useFocusTrap(formRef);

  // Setup keyboard shortcuts
  useKeyboardNavigation({
    'Enter': (e) => {
      if (e.target instanceof HTMLInputElement && !e.shiftKey) {
        form.handleSubmit(onSubmit)();
      }
    },
    'Escape': () => {
      if (!isLoading) {
        window.history.back();
      }
    },
  });

  async function onSubmit(data: RequestResetFormValues) {
    if (isLoading) return;
    
    setIsLoading(true)
    try {
      // Simulate API call with timeout
      await new Promise(resolve => setTimeout(resolve, 1000))
      
      // Here you would typically make an API call to request password reset
      console.log("Password reset requested for:", data.email);
      
      toast({
        title: "Reset link sent",
        description: "Please check your email for the password reset link.",
      })
      setIsSubmitted(true)
    } catch (error: unknown) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred'
      toast({
        title: "Error",
        description: errorMessage,
        variant: "destructive",
      })
    } finally {
      setIsLoading(false)
    }
  }

  if (isSubmitted) {
    return (
      <div className="space-y-6" role="alert" aria-live="polite">
        <div className="flex flex-col items-center justify-center text-center space-y-4">
          <div className="h-12 w-12 rounded-full bg-green-100 flex items-center justify-center animate-in zoom-in duration-300">
            <Mail className="h-6 w-6 text-green-600" />
          </div>
          <div className="space-y-2 animate-in fade-in slide-in-from-bottom-2 duration-500">
            <h3 className="text-lg font-medium">Check your email</h3>
            <p className="text-sm text-muted-foreground">
              We've sent you a password reset link. Please check your email and follow the instructions.
            </p>
          </div>
        </div>
        <div className="animate-in fade-in slide-in-from-bottom-3 duration-700">
          <Button
            variant="outline"
            className="w-full"
            onClick={() => {
              setIsSubmitted(false);
              form.reset();
            }}
            aria-label="Back to reset password form"
          >
            Back to reset password
          </Button>
        </div>
      </div>
    )
  }

  return (
    <LoadingOverlay loading={isLoading} blur>
      <div className="space-y-6" ref={formRef}>
        <div className="animate-in fade-in slide-in-from-bottom-1 duration-300">
          <h3 className="text-lg font-medium">Reset Password</h3>
          <p className="text-sm text-muted-foreground">
            Enter your email address and we'll send you a link to reset your password.
          </p>
        </div>
        <Form {...form}>
          <form 
            onSubmit={form.handleSubmit(onSubmit)} 
            className="space-y-4"
            aria-label="Password reset request form"
          >
            <div className="animate-in fade-in slide-in-from-bottom-2 duration-500">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input 
                        placeholder="Enter your email address" 
                        type="email" 
                        disabled={isLoading}
                        aria-label="Email address input"
                        autoFocus // Automatically focus the email input
                        {...field} 
                      />
                    </FormControl>
                    <FormMessage role="alert" aria-live="polite" />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex flex-col space-y-4 animate-in fade-in slide-in-from-bottom-3 duration-700">
              <Button 
                type="submit" 
                loading={isLoading}
                loadingText="Sending Reset Link..."
                aria-label="Send password reset link"
              >
                Send Reset Link
              </Button>
              <Button 
                type="button" 
                variant="outline" 
                onClick={() => window.history.back()}
                disabled={isLoading}
                aria-label="Back to login page"
              >
                Back to login
              </Button>
              <div className="text-xs text-muted-foreground text-center">
                <p>Press Enter to submit • Esc to go back</p>
              </div>
            </div>
          </form>
        </Form>
      </div>
    </LoadingOverlay>
  )
}
