import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../ui/dialog";
import { Button } from "../../ui/button";
import { Form, FormField, FormItem, FormLabel } from "../../ui/form";
import { Checkbox } from "../../ui/checkbox";
import { Input } from "../../ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/select";
import { useToast } from "../../../utils/use-toast";
import { CustomerSelect } from "./CustomerSelect";
import { ItemsSection } from "./ItemsSection";
import { useInventory } from "../../../hooks/inventory/useInventory";
import { useCustomers } from "../../../hooks/customers/useCustomers";
import { useSaleForm, FormSaleData } from "../../../hooks/sales/useSaleForm";
import { 
  PAYMENT_METHODS,
  ITEM_TYPES,
  InventoryItem, 
  SaleItem, 
  QuantitySaleItem,
  DimensionalSaleItem,
  QuantityInventoryItem,
  DimensionalInventoryItem,
  isQuantityInventoryItem,
  isDimensionalInventoryItem,
  PaymentMethod
} from "../../../types";
import brain from "../../../brain";
import logger from "../../../utils/logger";

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export function CreateSaleDialog({ open, onClose, onSuccess }: Props) {
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<SaleItem[]>([]);
  const { toast } = useToast();
  const { inventory, isLoading: isLoadingInventory } = useInventory();
  const { customers, isLoading: isLoadingCustomers } = useCustomers();
  const { form, resetItemFields, prepareSaleData, validateForm, calculateTotalAmount } = useSaleForm();

  const handleSubmit = async (data: FormSaleData) => {
    const validationError = validateForm();
    if (validationError) {
      toast({
        variant: "destructive",
        title: "Validation Error",
        description: validationError,
      });
      return;
    }

    if (selectedItems.length === 0) {
      toast({
        variant: "destructive",
        title: "Validation Error",
        description: "Please add at least one item",
      });
      return;
    }

    setLoading(true);
    try {
      const saleData = prepareSaleData(selectedItems);
      await brain.create_sale(saleData);
      
      toast({
        title: "Success",
        description: "Sale created successfully",
      });
      
      onSuccess();
      onClose();
      setSelectedItems([]);
      form.reset();
    } catch (error) {
      logger.error("Error creating sale:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to create sale",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddItem = (data: FormSaleData, inventoryItem: InventoryItem): boolean => {
    try {
      if (inventoryItem.type === ITEM_TYPES.QUANTITY) {
        const qtyItem = inventoryItem as QuantityInventoryItem;
        if (!data.quantity || data.quantity <= 0) {
          throw new Error("Please enter a valid quantity");
        }
        if (data.quantity > qtyItem.current_quantity) {
          throw new Error(`Only ${qtyItem.current_quantity} units available`);
        }

        const newItem: QuantitySaleItem = {
          id: crypto.randomUUID(),
          inventory_item_id: inventoryItem.id,
          name: inventoryItem.name,
          type: ITEM_TYPES.QUANTITY,
          quantity: data.quantity,
          unit_price: data.unit_price,
          total_price: data.quantity * data.unit_price,
        };
        setSelectedItems(prev => [...prev, newItem]);
      } else if (inventoryItem.type === ITEM_TYPES.DIMENSIONAL) {
        const dimItem = inventoryItem as DimensionalInventoryItem;
        if (!data.width || !data.length || data.width <= 0 || data.length <= 0) {
          throw new Error("Please enter valid dimensions");
        }
        const area = data.width * data.length;
        if (area > dimItem.area) {
          throw new Error(`Only ${dimItem.area} sq ft available`);
        }

        const newItem: DimensionalSaleItem = {
          id: crypto.randomUUID(),
          inventory_item_id: inventoryItem.id,
          name: inventoryItem.name,
          type: ITEM_TYPES.DIMENSIONAL,
          width: data.width,
          length: data.length,
          area: area,
          unit_price: data.unit_price,
          total_price: area * data.unit_price,
        };
        setSelectedItems(prev => [...prev, newItem]);
      }

      return true;
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to add item",
      });
      return false;
    }
  };

  const handleRemoveItem = (id: string) => {
    setSelectedItems(prev => prev.filter(item => item.id !== id));
  };

  const isLoading = loading || isLoadingInventory || isLoadingCustomers;

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Create New Sale</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
            <CustomerSelect customers={customers} form={form} />

            <ItemsSection
              form={form}
              inventory={inventory}
              selectedItems={selectedItems}
              onAddItem={handleAddItem}
              onRemoveItem={handleRemoveItem}
              onItemAdded={resetItemFields}
            />

            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-4">
                <FormField
                  control={form.control}
                  name="apply_tax"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center space-x-2">
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                      <FormLabel>Apply Tax</FormLabel>
                    </FormItem>
                  )}
                />

                {form.watch("apply_tax") && (
                  <FormField
                    control={form.control}
                    name="tax_rate"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Tax Rate (%)</FormLabel>
                        <Input
                          type="number"
                          {...field}
                          onChange={(e) => field.onChange(parseFloat(e.target.value))}
                          min="0"
                          step="0.01"
                        />
                      </FormItem>
                    )}
                  />
                )}
              </div>

              <div className="space-y-4">
                <FormField
                  control={form.control}
                  name="apply_discount"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center space-x-2">
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                      <FormLabel>Apply Discount</FormLabel>
                    </FormItem>
                  )}
                />

                {form.watch("apply_discount") && (
                  <>
                    <FormField
                      control={form.control}
                      name="discount_type"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Discount Type</FormLabel>
                          <Select
                            value={field.value}
                            onValueChange={(value: "percentage" | "fixed") => field.onChange(value)}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select discount type" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="percentage">Percentage</SelectItem>
                              <SelectItem value="fixed">Fixed Amount</SelectItem>
                            </SelectContent>
                          </Select>
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="discount_value"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            {form.watch("discount_type") === "percentage"
                              ? "Discount (%)" 
                              : "Discount Amount"}
                          </FormLabel>
                          <Input
                            type="number"
                            {...field}
                            onChange={(e) => field.onChange(parseFloat(e.target.value))}
                            min="0"
                            step="0.01"
                          />
                        </FormItem>
                      )}
                    />
                  </>
                )}
              </div>
            </div>

            <FormField
              control={form.control}
              name="payment_method"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Payment Method</FormLabel>
                  <Select
                    value={field.value}
                    onValueChange={(value: PaymentMethod) => field.onChange(value)}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select payment method" />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.entries(PAYMENT_METHODS).map(([key, value]) => (
                        <SelectItem key={key} value={value}>
                          {key}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="notes"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Notes</FormLabel>
                  <Input {...field} placeholder="Optional notes" />
                </FormItem>
              )}
            />

            <div className="flex justify-end space-x-2">
              <Button variant="outline" onClick={onClose} disabled={isLoading}>
                Cancel
              </Button>
              <Button type="submit" disabled={isLoading}>
                {isLoading ? "Creating..." : "Create Sale"}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
